<template>
  <div :class="`${isActive ? 'active-box': 'inactive-box'} p-4 normal-input-text`">
    <Loader :loading="loading"/>
    <div :class="`section-number text-white p-1 ${isActive ? 'active-section-number': 'inactive-section-number'}`">
      2
    </div>
    <div class="section-header">
      Provide context and parameters
    </div>
    <div>
      Please provide the context of the review and the specific insights you are seeking to tailor the sentiment
      analysis to your needs
    </div>
    <textarea v-model="providedContext"
              :disabled="!isActive"
              class="input-text-area h-44"/>
    <span class="context-input-words-count">{{ providedContextWordCount }}/{{ MAX_WORDS_FOR_CONTEXT }} words</span>
    <div class="flex justify-end">
      <button
          v-if="!keyAspectPerSentiment && isActive"
          :class="`input-button mx-1 ${isActive ? 'button-enabled' : 'button-disabled'}`"
          :disabled="!isActive"
          @click="handleBack">
        Back
      </button>
      <button
          :class="`input-button ${providedContext.trim().length > 0 && isActive ? 'button-enabled' : 'button-disabled'}`"
          :disabled="!isActive"
          @click="handleContextProvided">
        Next
      </button>
    </div>
    <div :class="`${keyAspectPerSentiment || !isActive ? '' : 'view-hidden'}`">
      <div class="mt-3 mb-3">
        List the key aspects you want to analyze in the reviews to focus the sentiment analysis on relevant themes.
      </div>
      <div :class="`meta-info-area mt-3`">
        <div class="p-1 brand-text">
          <div v-if="keyAspectPerSentiment"
               class="font-bold">
            Suggested key aspects as per context:
          </div>
          <div>{{ keyAspectPerSentiment }}</div>
          <button @click="copyText">
            <img height="20" width="20" :src="CopyIcon" alt="Copy" class="copy-icon"/>
          </button>
        </div>
      </div>
      <textarea
          v-model="providedParameters"
          :disabled="!(isActive && keyAspectPerSentiment)"
          :placeholder="keyAspectPerSentiment"
          class="input-text-area h-20"/>
      <div class="flex justify-end">
        <button
            :class="`input-button mx-1 ${isActive ? 'button-enabled' : 'button-disabled'}`"
            :disabled="!isActive"
            @click="handleBack">
          Back
        </button>
        <button
            :class="`input-button ${providedParameters.trim().length > 0 && isActive ? 'button-enabled' : 'button-disabled'}`"
            :disabled="!isActive"
            @click="handleParametersProvided">
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Papa from "papaparse";
import axios from "axios";
import CopyIcon from "@/assets/img/copy-icon.svg";

const ASPECT_SUGGESTION_ENDPOINT = `https://powerpulseapicombustdev.kearney.com/suggest_aspects`;

const MAX_WORDS_FOR_CONTEXT = 500;

export default {
  name: "SensaAnalysisContextSection",
  components: {Loader},
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    apiKey: {
      type: String,
      required: true,
    },
    handleNext: {
      type: Function,
      required: true,
    },
    handleBack: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      providedContext: "",
      providedParameters: "",
      loading: false,
      keyAspectPerSentiment: "",
      MAX_WORDS_FOR_CONTEXT,
      CopyIcon
    }
  },
  methods: {
    copyText() {
      this.providedParameters = this.keyAspectPerSentiment;
    },
    async handleContextProvided() {
      if (this.providedContext.trim().length > 0) {
        this.loading = true;

        const resp = await axios
            .post(ASPECT_SUGGESTION_ENDPOINT, {
              "context": this.providedContext.trim(),
              "api_key": this.apiKey
            })
            .catch(error => {
              console.error('Error fetching data:', error);
              this.loading = false;
            });
        this.keyAspectPerSentiment = resp?.data?.aspects ?? "ERROR";
        const regex = /(\d+)\.\s([^\n]+)/g;
        let match;
        const parameters = [];

        while ((match = regex.exec(this.keyAspectPerSentiment)) !== null) {
            parameters.push(match[2].trim());
        }

        this.providedParameters = parameters.join(', ');
        this.loading = false;
      }
    },
    handleParametersProvided() {
      if (this.providedParameters.trim().length > 0) {
        this.handleNext(this.providedContext.trim(), this.providedParameters.trim());
      }
    }
  },
  computed: {
    providedContextWordCount() {
      const trimmedProvidedContext = this.providedContext.trim();
      if (trimmedProvidedContext.length > 0) {
        const wordsInTrimmedProvidedContext = trimmedProvidedContext.split(" ")
        const wordsCount = wordsInTrimmedProvidedContext.length;
        if (wordsCount > MAX_WORDS_FOR_CONTEXT) {
          this.providedContext = wordsInTrimmedProvidedContext.slice(0, MAX_WORDS_FOR_CONTEXT).join(" ");
          return MAX_WORDS_FOR_CONTEXT;
        }
        return wordsCount;
      }
      return 0;
    }
  }
};
</script>

<style scoped>
.context-input-words-count {
  position: relative;
  float: right;
  margin-top: -30px;
  z-index: 9;
  padding: 3px;
  margin-right: 6px;
  color: rgba(0, 0, 0, 0.3);
}
</style>
