<template>
  <div>
    <HeatExchangerSchedulerInput 
      v-if="!showOutput" 
      @heat-exchange-form-values-updated="showOutputPage" 
    />
    <HeatExchanerSchedulerOutput 
      v-if="showOutput" 
      :result="result" 
      :formInput="formInput" 
      @heat-exchange-form-values-updated="showOutputPage" 
      @go-back="catchGoBack"
    />
    <Overlay :visible="showOverlay" />
  </div>
</template>

<script>
import axios from 'axios';
import Overlay from "../components/Overlay.vue";
import HeatExchangerSchedulerInput from "../components/HeatExhangerScheduler/Input/HeatExchangerSchedulerInput.vue";
import HeatExchanerSchedulerOutput from "../components/HeatExhangerScheduler/Output/HeatExchanerSchedulerOutput.vue";

export default {
  name: "HeatExchangerView",
  data() {
    return {
      showOutput: false,
      showOverlay: false,
      result: null,
      formInput: null,
    }
  },
  components: {
    Overlay,
    HeatExchangerSchedulerInput,
    HeatExchanerSchedulerOutput
  },
  methods: {
    showOutputPage(values) {
      this.showOutput = true;
      this.showOverlay = true;
      this.formInput = values;

      const apiUrl = "https://powerpulseapicombustdev.kearney.com/heatexchanger-input";
      axios
        .post(apiUrl, values)
        .then(response => {
          console.log("POST success:", response.data);
          this.result = response.data;
          this.showOverlay = false;
        })
        .catch(error => {
          console.error("POST error:", error);
          this.showOverlay = false;
        });
    },
    catchGoBack() {
      this.showOutput = false;
      this.showOverlay = false;
      this.formInput = null;
      this.result = null;
    }
  }
};
</script>
