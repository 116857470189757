<template>
    <div class="flex items-center justify-between p-2">
      <input
        type="range"
        class="w-3/5 pointer-events-none"
        min="-1"
        max="1"
        step="0.01"
        v-model="sliderValue"
        @input="updateCylinderRotation"
        :class="{ 'priceLow': sliderValue < 0, 'priceHigh': sliderValue >= 0 }"
      />

      <div class="text-center">
        <span class="text-lg font-semibold">{{ sliderValue }}</span>
    </div>
    </div>

  </template>
  
  <script>
  export default {
    props: {
      value: {
        default: 0.6 // Set a default value if not provided by the parent
      }
    },
    data() {
      return {
        sliderValue: this.value // Set the initial value from the prop
      };
    },
    methods: {
      updateCylinderRotation() {
        this.$emit('sentiment-value', this.sliderValue )
      },
      handleSliderChange(value) {
        this.$emit('sentiment-value', value);
      }
    }
  };
  </script>
  
  <style scoped>
  input[type="range"].priceHigh{
    accent-color: #9CC6A0
  }

  input[type="range"].priceLow{
    accent-color: #FF8080
  }


  </style>
  