<template>
  <div class="w-full mb-4">
    <cokeLanding />
  </div>
</template>

<script>
import cokeLanding from "@/components/ProcessParameterOptimizer/cokeLanding.vue";

export default {
  name: "ProcessParameterCokeLandingView",
  components: {
    cokeLanding
  }
};
</script>
