<template>
  <div style="height: 100%; width: 100%" class="p-3">
    <div style="position: relative">
      <div ref="chart1" class=""></div>
    </div>

  </div>
</template>

<script>
import _ from "lodash";
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';

const LINE_COLORS = ["#d2d2d2", "#d2d2d2", "#787878", "#e6d2fa", "#c8a5f0", "#af7deb"]
function getForecastZoneStart(seriesData){
  const zoneStarts = _.filter(_.map(seriesData, v => v.zoneAt), v => {
    return _.isNumber(v);
  });
  return _.min(zoneStarts) ?? 0;
}

function getMaxYValue(seriesData){
  return _.max(_.map(seriesData, v => _.max(v.values)));
}

function getSeriesData(seriesData, times){
  let toReturn = [];
  _.forEach(seriesData, (data, i) => {
    const series = {
      data: [],
      name: data.name,
      color: LINE_COLORS[(_.size(LINE_COLORS) + i) % _.size(LINE_COLORS)]
    };
    const hasZone = _.isNumber(data.zoneAt);
    _.forEach(_.get(data, "values"), (v, i) => {
      const toPush = {
        y: v,
      }
      if (hasZone && i >= data.zoneAt){
        toPush.marker = {
          enabled: false
        }
      }
      series.data.push(toPush)
    });

    if (hasZone){
      series.zoneAxis = 'x';
      series.zones = [{
        value: data.zoneAt,
      }, {
        dashStyle: 'dash',
        color: data.zoneColor
      }];
    }

    toReturn.push(series);
  });
  return toReturn;
}

function getRangeSeries(val, items){
  const series = {
    data: [],
    zoneAxis: 'x',
    dashStyle: 'dash',
    color: "gray",
    lineWidth: 1,
    showInLegend: false,
    name: "",
    enableMouseTracking: false
  };
  _.forEach(items, (v, i) => {
    const toPush = {
      y: val,
      marker: {
        enabled: false
      }
    }

    if (i === _.size(items) - 1){
      toPush.dataLabels = {
        enabled: true,
        formatter: function() {
          return `<div>${this.y}</div>`;
        },
      };
      toPush.marker = {
        symbol: 'url(/predictive-maintenance/predictive_maintenance_left_arrow.svg)',
        width:30,
        height:30
      }
    }
    series.data.push(toPush)
  });
  return series;
}

export default {
  name: "LineAndForecastChart",
  components: {
    HighchartsVue
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: false
    }
  },
  methods: {
    createChart(chartData){
      const seriesData = _.get(chartData, "seriesData");
      const times = _.get(chartData, "categories");
      const upperRangeVal = _.get(chartData, "rangeMax");
      const lowerRangeVal = _.get(chartData, "rangeMin");
      const forecastZoneStart = getForecastZoneStart(seriesData);
      const maxYVal = getMaxYValue(seriesData);

      let ranges = [];
      if (forecastZoneStart > 0){
        ranges = [
          [forecastZoneStart, maxYVal+20],
          [_.size(times) -1, maxYVal+20],
        ]
      }
      const chartOptions = {
        chart: {
          backgroundColor: this.chartOptions?.backgroundColor,
          height: this.chartOptions?.height,
        },
        title: {
          text: _.get(chartData, "chartTitle", ""),
          align: 'left',
          x: 0,
          verticalAlign: "top",
          style: {
            color: '#7f2fdf',
            fontWeight: 700
          }
        },
        subtitle: {
          text: _.get(chartData, "chartSubtitle", ""),
          align: 'left',
          x: 0,
          verticalAlign: "top",
          style: {
            fontWeight: 700
          }
        },
        credits: {
          enabled: false
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          y: -60,
          x: 120,
          width: 400
        },
        plotOptions: {
          area: {
            fillColor: "rgba(228,228,228,255)",
          },
          series: {
            pointPlacement: 'on'
          }
        },
        xAxis: {
          categories: times,
          labels: {
            enabled: true
          },
          startOnTick: true
        },
        yAxis: {
          gridLineColor: 'transparent',
          max: maxYVal+20,
          alignTicks: true,
          endOnTick: false,
          tickInterval: (Math.ceil((maxYVal / 12) / 20)) * 20, // Nearest multiple of 20
          lineWidth: 1,
          title: {
            text: ""
          }
        },
        series: []
      };

      const lineSeriesData = getSeriesData(seriesData, times);
      const uppRangeSeriesData = getRangeSeries(upperRangeVal, times);
      const lowerRangeSeriesData = getRangeSeries(lowerRangeVal, times);

      chartOptions.series = [...lineSeriesData, uppRangeSeriesData, lowerRangeSeriesData, {
        showInLegend: false,
        name: 'Forecasted',
        data: ranges,
        type: 'area',
        lineWidth: 0,
        zIndex: -1,
        marker: {
          enabled: false
        },
        dataLabels: {
          enabled: true,
          x: 60,
          y: maxYVal+60,
          formatter: function () {
            if (this.x === times[_.size(times)-1]) return null;
            return this.series.name;
          },
          backgroundColor: "rgba(228,228,228,255)",
          style: {
            fontSize: "16px",
            fontColor: "#4c4c4c"
          }
        },
        enableMouseTracking: false
      }];
      if (this.$refs.chart1) {
        Highcharts.chart(this.$refs.chart1, chartOptions);
      }
    }
  },
  async created() {
    await new Promise(resolve =>{
      setTimeout(()=>{
        resolve()
      }, 300)
    })
    this.createChart(this.data)
  }
}
</script>

<style scoped>

</style>