<template>
   <div class="h-screen">
      <CombustionAndSADCInput v-if="!showOutput" @form-values-updated="showOutputPage" />
      <CombustionAndSADCOutput v-if="showOutput && result.response" :result="result.response" :formInput="formInput"
         @form-values-updated="showOutputPage" />
      <Overlay :visible="showOverlay" />
   </div>
</template>
  
<script>
import Overlay from "@/components/Overlay.vue";
import CombustionAndSADCOutput from "../components/CombustionAndSADC/Output/CombustionAndSADCOutput.vue";
import CombustionAndSADCInput from "../components/CombustionAndSADC/Input/CombustionAndSADCInput.vue";
import axios from 'axios'
export default {
   name: "CombustionAndSADCView",
   data() {
      return {
         showOutput: false,
         showOverlay: false,
         result: Object,
         formInput: Object
      }

   },
   components: {
      CombustionAndSADCInput,
      CombustionAndSADCOutput,
      Overlay
   },
   methods: {
      showOutputPage(values) {
         this.showOutput = true;
         this.showOverlay = true;
         this.formInput = values;

         if (this.showOutput) {
            const apiUrl = "https://powerpulseapicombustdev.kearney.com/SADC";
            axios
               .post(apiUrl, values)
               .then(response => {
                  console.log("POST success:", response.data);
                  this.result = response.data.processed_gcv_inputs;
                  this.showOverlay = false;
               })
               .catch(error => {
                  console.error("POST error:", error);
               });
         }
      },

      // setTimeout(() => {
      //    if (this.showOutput) {
      //       this.result = {
      //          "response": {
      //             "optimalFurnaceParameters": {
      //                "Primary Air Header Pressure": {
      //                   "Present Value": "9.0",
      //                   "Optimal Value": "8.1",
      //                   "Final Optimal Value": "8.1"
      //                }
      //             },
      //             "optimalMillParameters": {
      //                "Mill A Coal Feed Rate (tph)": {
      //                   "Present Value": 64.46,
      //                   "Optimal Value": 64.42,
      //                   "Recommended Bias": -0.24
      //                },
      //                "Mill B Coal Feed Rate (tph)": {
      //                   "Present Value": 64.93,
      //                   "Optimal Value": 64.89,
      //                   "Recommended Bias": 0.22
      //                },
      //                "Mill C Coal Feed Rate (tph)": {
      //                   "Present Value": 64.66,
      //                   "Optimal Value": 64.62,
      //                   "Recommended Bias": -0.04
      //                },
      //                "Mill D Coal Feed Rate (tph)": {
      //                   "Present Value": 64.98,
      //                   "Optimal Value": 64.94,
      //                   "Recommended Bias": 0.27
      //                },
      //                "Mill E Coal Feed Rate (tph)": {
      //                   "Present Value": 65.09,
      //                   "Optimal Value": 65.01,
      //                   "Recommended Bias": 0.34
      //                },
      //                "Mill F Coal Feed Rate (tph)": {
      //                   "Present Value": 64.20,
      //                   "Optimal Value": 64.12,
      //                   "Recommended Bias": -0.54
      //                },
      //                "Mill G Coal Feed Rate (tph)": {
      //                   "Present Value": 0,
      //                   "Optimal Value": 0,
      //                   "Recommended Bias": 0
      //                },
      //                "Mill H Coal Feed Rate (tph)": {
      //                   "Present Value": 0,
      //                   "Optimal Value": 0,
      //                   "Recommended Bias": 0
      //                }
      //             },
      //             "optimalSADCParameters": {
      //                "AA_1": {
      //                   "Present Value": "90.7",
      //                   "Optimal Value": "90.7",
      //                   "Final Optimal Value": "No Change"
      //                },
      //                "AA_2": {
      //                   "Present Value": "86.4",
      //                   "Optimal Value": "86.1",
      //                   "Final Optimal Value": "No Change"
      //                },
      //                "AA_3": {
      //                   "Present Value": "96.1",
      //                   "Optimal Value": "96.6",
      //                   "Final Optimal Value": "No Change"
      //                },
      //                "AA_4": {
      //                   "Present Value": "69.6",
      //                   "Optimal Value": "70.6",
      //                   "Final Optimal Value": "No Change"
      //                },
      //                "A_1": {
      //                   "Present Value": "74.0",
      //                   "Optimal Value": "74.0",
      //                   "Final Optimal Value": "No Change"
      //                },
      //                "A_2": {
      //                   "Present Value": "83.2",
      //                   "Optimal Value": "77.3",
      //                   "Final Optimal Value": "77.3"
      //                },
      //                "A_3": {
      //                   "Present Value": "74.5",
      //                   "Optimal Value": "73.7",
      //                   "Final Optimal Value": "No Change"
      //                },
      //                "A_4": {
      //                   "Present Value": "95.6",
      //                   "Optimal Value": "102.7",
      //                   "Final Optimal Value": "102.7"
      //                }
      //             },
      //             "optimalTurbineParameters": {
      //                "PSH Outlet Temp (degree celcius)- Left": {
      //                   "Present Value": "578.3",
      //                   "Final Optimal Value": "579.7"
      //                },
      //                "PSH Outlet Temp (degree celcius)- Right": {
      //                   "Present Value": "491.0",
      //                   "Final Optimal Value": "494.5"
      //                },
      //                "FSH Outlet Temp (degree celcius)-Left": {
      //                   "Present Value": "515.9",
      //                   "Final Optimal Value": "517.2"
      //                },
      //                "FSH Outlet Temp (degree celcius)-Right": {
      //                   "Present Value": "562.0",
      //                   "Final Optimal Value": "565.4"
      //                },
      //                "FRH Temperature-Left": {
      //                   "Present Value": "563.2",
      //                   "Final Optimal Value": "565.7"
      //                },
      //                "FRH Temperature-Right": {
      //                   "Present Value": "564.7",
      //                   "Final Optimal Value": "567.7"
      //                }
      //             },
      //             "generalParameters": {
      //                "Specific Coal Consumption": {
      //                   "Present Value": 585.03,

      //                },
      //                "Gross Load": {
      //                   "Present Value": 663.68
      //                },
      //                "Total Coal Flow": {
      //                   "Present Value": 388.28
      //                }
      //             }
      //          }
      //       }
      //       this.showOverlay = false; // Hide overlay after 5 seconds
      //    }
      // }, 5000); // Delay for 5 seconds (5000 milliseconds)
   }
};
</script>
  