<template>
  <div id="app" class="flex h-screen">
    <!-- Conditionally render the Login component if not authenticated -->
    <Login v-if="!authenticated" @authenticated="handleAuthentication" />
    <!-- Conditionally render the main content after authentication -->
    <div v-else>
      <TopNavbar class="fixed top-0 w-full z-10" @toggle-sideNav="showSideNav = !showSideNav" :sideNavOpen="showSideNav"
        @home="homeClicked" :name="userName" @logout="logout" />
      <div class="main-container mt-16 fixed left-0 flex w-full">
        <LeftSidebar v-if="showSideNav" class="w-1/5 z-10 h-screen" :selectedSubOption="selectedSubOption"
          @update-tab="handleSubOptionSelected" />
        <router-view class="content h-screen mx-auto" :class="{ 'w-4/5': showSideNav, 'w-full': !showSideNav }"
          @sub-option-selected="handleSubOptionSelected" />
      </div>
    </div>
  </div>
</template>

<script>
import TopNavbar from "@/components/TopNavbar.vue";
import LeftSidebar from "@/components/LeftSidebar.vue";
import Login from "@/components/Login.vue";
import axios from "axios";
import { pca } from '@/config/authConfig';

export default {
  data() {
    return {
      showSideNav: false,
      selectedSubOption: null,
      authenticated: false,
      userName: String
    };
  },
  components: {
    TopNavbar,
    LeftSidebar,
    Login,
  },
  methods: {
    handleSubOptionSelected(subOptionId) {
      this.selectedSubOption = subOptionId;
      this.showSideNav = !!subOptionId;
    },
    handleAuthentication(name) {
      this.authenticated = true;
      this.userName = name;
      this.$router.push('/')
    },
    homeClicked() {
      this.selectedSubOption = null;
      this.showSideNav = false;
      this.$router.push('/');
    },
    async logout() {
      try {
        const apiUrl = `https://powerpulseapicombustdev.kearney.com/pdf-qa-close-session`;
        axios
          .post(apiUrl, { session_id: sessionStorage.getItem("session_id") })
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.error("POST error:", error);
          }).finally(() => {
            sessionStorage.clear();
            window.location.href = '/';
          });

      } catch (error) {
        console.error('Logout error:', error);
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log(to.name, 'kopopopop')

      
      if (["solarInverterNetworkView", "solarInverterAssetView", "solarInverterMTRiskView", "solarInverterSTRiskView", "windTurbineNetworkView", "windTurbineAssetView", "windTurbineMTRiskView", "windTurbineSTRiskView"].includes(to.name)) {
        this.showSideNav = false;
      }
      // Close the side nav when navigating to a new route
    }
  },
};
</script>

<style>
.kearney-white-button {
  color: #7823dc;
  border-color: #7823dc;
}

.kearney-white-button:hover {
  color: white;
  background-color: #7823dc;
}

.kearney-purple-button {
  color: white;
  background-color: #7823DC;
  transition: transform 0.2s;
  /* Add a smooth transition */
}

.kearney-purple-button:hover {
  cursor: pointer;
  transform: scale(1.05);
  /* Increase the size on hover */
}

.orange-text {
  color: #e46713;
}

.aqua-text {
  color: #55a8b5;
}

.purple-text {
  color: #7824DB;
}

.red-text {
  color: #E71818;
}
</style>