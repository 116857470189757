<template>
    <div class="flex justify-between px-10">
        <button :class="{ 'active-tab': activeTab === 'commodity-pathfinder', 'non-active-tab': activeTab !== 'commodity-pathfinder' }" @click="setActiveTab('commodity-pathfinder')" class="border w-1/4 mx-2 py-2 rounded-md">Home</button>
        <button :class="{ 'active-tab': activeTab === 'price-plot', 'non-active-tab': activeTab !== 'price-plot' }" @click="setActiveTab('price-plot')" class="border w-1/4 mx-2 py-2 rounded-md">Price Pilot</button>
        <button :class="{ 'active-tab': activeTab === 'news', 'non-active-tab': activeTab !== 'news' }" @click="setActiveTab('news')" class="border w-1/4 mx-2 py-2 rounded-md">Pulse</button>
        <button :class="{ 'active-tab': activeTab === 'text-synthesizer', 'non-active-tab': activeTab !== 'text-synthesizer' }" @click="setActiveTab('text-synthesizer')" class="border w-1/4 mx-2 py-2 rounded-md">Text Tune</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeTab: ''
        };
    },
    created() {
        this.activeTab = this.$route.path.split('/')[1];
    },
    watch: {
        '$route' (to) {
            this.activeTab = to.path.split('/')[1];
        }
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab;
            this.$router.push(`/${tab}`);
        },
    },
};
</script>

<style scoped>
.active-tab {
    background-color: #F37022;
    color: #ffffff; /* Set text color to white for better visibility */
}

.non-active-tab {
    background-color: #F6F6F6;
    color: #333; /* Set text color to a dark color for better visibility */
}

.active-tab:hover{
    background-color: #F37022; /* Keep the same color on hover */
}
</style>
