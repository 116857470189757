<template>
  <div class="flex items-center justify-center h-screen px-10">
    <div class="flex flex-col items-center justify-center w-full max-w-screen-lg px-10">
      <div class="split-horizontal flex justify-start carousel">
        <router-link v-if="items[0][0]" :to="routeIDMap[items[0][0].id] ? routeIDMap[items[0][0].id] : '/'"
          @click="handleRouterLinkClick(items[0][0].id)"
          class="yield-card card hover:shadow-2xl w-1/2 py-2 mr-5 px-5 flex-col">
          <div class="text-left flex items-center justify-between" style="height: 20%;">
            <div class="flex items-center">
              <img :src="icons['yieldOptimizer']" alt="" class="inline h-9 m-1">
              <span class="font-bold text-lg">{{ items[0][0].title }}</span>
            </div>
            <img v-if="!items[0][0].id" :src="icons['clockIconPurple']" alt="" class="inline mr-1 h-4">
          </div>
          <div class="flex items-end mt-2" style="height: 80%;">
            <span>{{ items[0][0].description }}</span>
            <img v-if="items[0][0].id" :src="icons['purpleArrowIcon']" alt="" class="inline ml-3">
          </div>
        </router-link>
        <router-link v-if="items[0][1]" :to="routeIDMap[items[0][1].id] ? routeIDMap[items[0][1].id] : '/'"
          @click="handleRouterLinkClick(items[0][1].id)"
          class="combustion-card card hover:shadow-2xl w-1/2 py-2 mr-5 px-5 flex-col">
          <div class="text-left flex items-center justify-between" style="height: 20%;">
            <div class="flex items-center">
              <img :src="icons['yieldOptimizer']" alt="" class="inline h-9 m-1">
              <span class="font-bold text-lg">{{ items[0][1].title }}</span>
            </div>
            <img v-if="!items[0][1].id" :src="icons['clockIconRed']" alt="" class="inline mr-1 h-4">
          </div>
          <div class="flex items-end mt-2" style="height: 80%;">
            <span>{{ items[0][1].description }}</span>
            <img v-if="items[0][1].id" :src="icons['redArrowIcon']" alt="" class="inline ml-3">
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PredictiveMaintenanceMainView',
  data() {
    return {

      items: [
        [
          { title: 'Solar Inverter Predictive Maintenance', id: 1, description: 'AI/ML tool for predictive maintenance of Solar Inverters, monitoring parameters like voltage and temperature to detect anomalies and prevent shutdowns.' },
          { title: 'Wind Turbine Predictive Maintenance', id: 2, description: 'AI/ML tool for predictive maintenance of Wind Turbine Gearboxes, observing parameters such as vibration and oil quality to identify issues and optimize performance.' },
        ]
      ],
      sets: [1, 2],
      activeSetIndex: 0,
      icons: {
        yieldOptimizer: require('@/assets/img/yield-icon.svg'),
        purpleArrowIcon: require('@/assets/img/purple-arrow-icon.svg'),
        redArrowIcon: require('@/assets/img/red-arrow-icon.svg'),
        clockIconPurple: require('@/assets/img/sand-clock.svg'),
        clockIconRed: require('@/assets/img/sand-clock-red.svg')
      },

      routeIDMap: {
        1: '/solar-inverter/network-overview',
        2: '/wind-turbine/network-overview',
      },
      images: {
        homeImage: require('@/assets/img/home-image.png'),
      },
    };
  },
  methods: {

    handleRouterLinkClick(subOptionId) {
      this.$emit("sub-option-selected", subOptionId);
    }
  }
};
</script>

<style scoped>
.card {
  height: auto;
  border: 1px solid #ccc;
  text-decoration: none;
  color: inherit;
  display: flex;
  border-radius: 10px;
  margin: 1rem;
  background-color: white;
  padding: 20px;
  transition: transform 0.2s ease;
  box-shadow: 10px 10px 10px #4b4747;
}

.carousel {
  font-size: 0.7rem;
}

.card:hover {
  transform: scale(1.03);
}

.yield-card {
  border: 2px solid #7823DC;
}

.combustion-card {
  border: 2px solid #FF0101;
}

.blowing-card {
  border: 2px solid #5BB1BF;
}
</style>
