<template>
  <div class="container">
    <div style="height: 300px;">
      <LineAndForecastChart :data="chartData" :chart-options="chartOptions"/>
    </div>
    <div class="p-3">
      <span class="brand-text">Recommendation: </span>{{recommendation}}
    </div>
    <div class="flex justify-between w-full p-3">
      <div class="brand-text">
        {{assetName}} Weather and Equipment Parameters
      </div>
      <div class="favorable-unfavorable-container">
        <div class="favorable-box">Favorable</div>
        <div class="unfavorable-box">Unfavorable</div>
      </div>
    </div>
    <div>
      <div style="flex-wrap: wrap" class="flex p-3">
        <div class="aspect-box" v-for="aspect in inverterAspectData" :key="aspect.aspectName">
          <div class="aspect-name">{{aspect.aspectName}}</div>
          <div :class="`${aspect.favorable ? 'favorable-aspect-value': 'unfavorable-aspect-value'} aspect-value`">{{lodIsNaN(aspect.value) ? "NA": `${aspect.value} ${aspect.unit}`}}</div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import _ from "lodash";
import dayjs from 'dayjs';
import { mapState, mapActions } from 'vuex';
import LineAndForecastChart from "./LineAndForecastChart.vue";

function getTimes(sheetData = []){
  let toReturn = [];
  const LABEL = "IGBT Temperature (F)";
  const relevantRowLabelStartIndex = _.findIndex(sheetData, v =>{
    return v[4] === LABEL || v[5] === LABEL || v[6] === LABEL
  });
  if (relevantRowLabelStartIndex !== -1) {
    const relevantRowIndex = relevantRowLabelStartIndex + 1;
    const lookUpIndex = _.indexOf(sheetData[relevantRowLabelStartIndex], LABEL) + 1;
    toReturn = _.map(_.filter(_.slice(sheetData[relevantRowIndex], lookUpIndex), v => !_.isEmpty(_.trim(v))), v => {
      return dayjs(v).format("hh:mm A")
    });
  }
  return toReturn;
}

function getSeriesData(sheetData = []){
  const toReturn = [];
  const LABEL = "IGBT Temperature (F)";
  const relevantRowLabelStartIndex = _.findIndex(sheetData, v =>{
    return v[4] === LABEL || v[5] === LABEL || v[6] === LABEL
  });
  if (relevantRowLabelStartIndex !== -1) {
    const relevantRowStartIndex = relevantRowLabelStartIndex + 2;
    const lookUpIndex = _.indexOf(sheetData[relevantRowLabelStartIndex], LABEL);
    for (let i = relevantRowStartIndex; ; i++){
      const relevantData = _.filter(_.slice(sheetData[i], lookUpIndex), v => !_.isEmpty(_.trim(v)));
      if (_.isEmpty(relevantData)) break;
      const seriesName = _.get(relevantData, "[0]");
      let values = _.slice(relevantData, 1);
      const lastValue = values[_.size(values)-1];
      let zoneAt = null;
      let zoneColor = null;
      if (!_.isNumber(lastValue)){
        values = _.slice(values, 0, _.size(values)-1);
        const instruction = _.filter(_.split(lastValue, " "), v => !_.isEmpty(_.trim(v)));
        zoneColor = _.toLower(instruction[0]);
        zoneAt = _.size(values) - _.toNumber(instruction[3]);
      }

      toReturn.push({
        name: seriesName,
        values,
        zoneAt,
        zoneColor
      })
    }
  }
  return toReturn;
}

function getInverterAspectsData(sheetData = []){
  const toReturn = [];
  const LABEL = "Short-term inverter health";
  const relevantRowLabelStartIndex = _.findIndex(sheetData, v =>{
    return v[0] === LABEL || v[1] === LABEL
  });

  if (relevantRowLabelStartIndex !== -1){
    const relevantRowStartIndex = relevantRowLabelStartIndex + 2;
    const lookUpIndex = _.indexOf(sheetData[relevantRowLabelStartIndex], LABEL);

    for (let i = relevantRowStartIndex;;i++){
      const relevantData = _.slice(sheetData[i], lookUpIndex, lookUpIndex + 4);
      if (_.isEmpty(relevantData)) break;
      const aspectName = _.get(relevantData, "[0]");
      let unit = _.get(relevantData, "[1]");
      let value = _.get(relevantData, "[2]");
      if (_.isEmpty(value) && !_.isNumber(value)){
        value = "N/A";
        unit = "";
      }
      const isFavourable = eval(_.toLower(_.get(relevantData, "[3]")));
      toReturn.push({
        aspectName,
        unit,
        value,
        favorable: isFavourable
      })
    }
  }

  return toReturn;
}

function getRecommendation(sheetData = []){
  let toReturn = "";
  const LABEL = "Recommendation";
  const relevantRowLabelStartIndex = _.findIndex(sheetData, v =>{
    return v[0] === LABEL || v[1] === LABEL
  });
  if (relevantRowLabelStartIndex !== -1){
    const lookUpIndex = _.indexOf(sheetData[relevantRowLabelStartIndex], LABEL) + 1;
    toReturn = sheetData[relevantRowLabelStartIndex][lookUpIndex];
  }
  return toReturn;
}

export default {
  name: "WindTurbineShortTermRisk",
  components: {
    LineAndForecastChart
  },
  props: {
    assetName: {
      type: String,
      required: true
    }
  },
  data(){
    return {
      lodIsNaN: _.isNaN,
      chartOptions: {
        backgroundColor: "#ededed",
        height: "300px"
      }
    }
  },
  mounted() {
    this.fetchAndStorePredictiveMaintenanceData();
  },
  computed: {
    ...mapState('sheets', [
      'predictiveMaintenanceData'
    ]),

    chartData(){
      const RELEVANT_SHEET_NAME = "C7 Short-term Inverter Health";
      const sheetData = _.get(this.predictiveMaintenanceData, RELEVANT_SHEET_NAME);

      const times = getTimes(sheetData);
      const seriesData = getSeriesData(sheetData);

      if (_.isEmpty(times) || _.isEmpty(seriesData)){
        return null;
      }

      return {
        chartTitle: `${this.assetName} Short-term Inverter Health`,
        chartSubtitle: "Predict Short-term inverter health based on weather and equipment parameters",
        categories: times,
        seriesData,
        rangeMax: 205,
        rangeMin: 80
      }
    },

    inverterAspectData(){
      const RELEVANT_SHEET_NAME = "C7 Short-term Inverter Health";
      const sheetData = _.get(this.predictiveMaintenanceData, RELEVANT_SHEET_NAME);
      return getInverterAspectsData(sheetData)
    },

    recommendation(){
      const RELEVANT_SHEET_NAME = "C7 Short-term Inverter Health";
      const sheetData = _.get(this.predictiveMaintenanceData, RELEVANT_SHEET_NAME);
      return getRecommendation(sheetData)
    }
  },
  methods: {
    ...mapActions('sheets', ['fetchAndStorePredictiveMaintenanceData'])
  }
}
</script>
<style scoped>
.brand-text {
  color: #7f2fdf;
  font-weight: 700;
}

.container {
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
}

.favorable-unfavorable-container {
  display: flex;
  margin-right: 10px;
}

.favorable-box {
  background-color: #ccfecc;
  width: 90px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}
.unfavorable-box {
  background-color: #ffcccc;
  width: 90px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}

.aspect-box {
  min-width: 146px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.aspect-name {
  background-color: #af7deb;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-weight: bold;
  width: 100%;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}

.aspect-value {
  border: 1px solid #FFFFFF;
  width: 100%;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}

.favorable-aspect-value {
  background-color: #ccfecc;
}

.unfavorable-aspect-value {
  background-color: #ffcccc;
}
</style>