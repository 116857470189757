<template>
  <div class="mt-10 text-center">
    <Loader :loading="loading"/>
    <div class="flex mx-10 mt-2 justify-between text-sm text-left gap-6">
      <div class="section-enclosure">
        <ApiKeySection
            :handle-next="handleApiKeySelection"
            :is-active="activeSection === sections.GET_ACCESS_KEY"/>
      </div>
      <div class="section-enclosure">
        <ContextSection
            :api-key="providedApiKey"
            :handle-back="handleBackFromContextSection"
            :handle-next="handleNextFromContextSection"
            :is-active="activeSection === sections.GET_CONTEXT"/>
      </div>
      <div class="section-enclosure">
        <UploadExcelSection
            :handle-back="handleBackFromExcelUploadSection"
            :handle-next="handleExcelUploaded"
            :is-active="activeSection === sections.GET_EXCEL"
            :key-aspects="keyAspects"/>
      </div>
    </div>

    <button
        :class="`analyze-button border font-bold shadow-lg px-20 py-2 mt-3 mb-20 ${isInputProvided ? 'button-enabled' : 'button-disabled'}`"
        :disabled="!isInputProvided"
        @click="analyzeSentiments">
      Analyze Sentiments
    </button>
  </div>
</template>

<script>
import ApiKeySection from "@/components/SensaAnalysis/Input/ApiKeySection.vue";
import ContextSection from "@/components/SensaAnalysis/Input/ContextSection.vue";
import UploadExcelSection from "@/components/SensaAnalysis/Input/UploadExcelSection.vue";
import Loader from "@/components/Loader.vue";
import axios from "axios";

const ANALYSE_SENTIMENT_ENDPOINT = `https://powerpulseapicombustdev.kearney.com/process`;

const SECTIONS = {
  GET_ACCESS_KEY: "GET_ACCESS_KEY",
  GET_CONTEXT: "GET_CONTEXT",
  GET_EXCEL: "GET_EXCEL",
}

const ACCEPTABLE_INPUT_DOC_FORMATS = ['.xlsx']; // Provide acceptable extensions in lower case ONLY.

export default {
  name: "SensaAnalyticsInput",
  components: {
    Loader,
    ApiKeySection,
    ContextSection,
    UploadExcelSection,
  },
  props: {
    analyzeSentimentsHandler: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      activeSection: SECTIONS.GET_ACCESS_KEY,
      sections: SECTIONS,
      providedApiKey: "",
      context: "",
      keyAspects: "",
      excelFileDescriptor: null,
      loading: false,
    }
  },
  methods: {
    handleApiKeySelection(apiKey) {
      if (apiKey.length > 0) {
        this.providedApiKey = apiKey;
        this.activeSection = SECTIONS.GET_CONTEXT;
      }
    },
    handleNextFromContextSection(contextProvided, parametersProvided) {
      this.context = contextProvided;
      if (parametersProvided.length > 0) {
        this.keyAspects = parametersProvided;
        this.activeSection = SECTIONS.GET_EXCEL;
      }
    },
    handleBackFromContextSection() {
      this.activeSection = SECTIONS.GET_ACCESS_KEY;
    },
    handleExcelUploaded(excelFileDescriptor) {
      try {
        const extensionMatchPattern = /.*(\.[\w]*)/;
        const extension = extensionMatchPattern.exec(excelFileDescriptor.name)[1];
        if (ACCEPTABLE_INPUT_DOC_FORMATS.includes(extension.toLowerCase())) {
          this.excelFileDescriptor = excelFileDescriptor;
        } else {
          this.excelFileDescriptor = null;
        }
      } catch (e) {
        this.excelFileDescriptor = null;
      }
    },
    handleBackFromExcelUploadSection() {
      this.excelFileDescriptor = null;
      this.activeSection = SECTIONS.GET_CONTEXT;
    },
    async analyzeSentiments() {
      try {
        localStorage.setItem('api_key', this.providedApiKey);
        console.log(this.providedApiKey, 'this.providedApiKey')
      } catch (err) {
        console.log(err)
      }

      console.log(this.providedApiKey);
      console.log(this.keyAspects);
      console.log(this.excelFileDescriptor);
      this.loading = true;
      await axios
          .post(ANALYSE_SENTIMENT_ENDPOINT, {
            api_key: this.providedApiKey,
            parameters: this.keyAspects,
            context: this.context,
            file: this.excelFileDescriptor
          }, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .catch((e)=>{
            console.error(e);
          })
      this.loading = false;
      this.analyzeSentimentsHandler()
    }
  },
  computed: {
    isInputProvided() {
      return this.providedApiKey.length > 0
          && this.keyAspects.length > 0
          && this.excelFileDescriptor?.name?.endsWith(".xlsx")
    }
  }
};
</script>

<style>
.button-enabled {
  color: #eeeeee;
  background-color: #7823DC;
  transition: transform 0.2s;
}

.button-disabled {
  color: #eeeeee;
  background-color: #A5A5A5;
  transition: transform 0.2s;
}

.section-number {
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 0 auto;
  margin-top: -30px;
  border-radius: 50%;
  font-size: 20px;
  line-height: 30px;
}

.active-section-number {
  background-color: #390b67
}

.inactive-section-number {
  background-color: #d9d9d9;
}

.section-enclosure {
  border-radius: 24px;
  width: 33.333333%;
}

.section-header {
  text-align: center;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 3px;
}

.input-text-area {
  width: 100%;
  margin-top: 8px;
  padding: 4px 8px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 8px;
  border: 1px solid #bebebe;
  resize: none;
  font-weight: normal;
}

.brand-text {
  color: #7f2fdf;
  font-weight: 400;
}

.ulliitem {
  font-size: 12px;
  margin-left: 1px;
  list-style-type: disc;
  font-stretch: condensed;
  font-weight: bold;
}

.active-box {
  background-color: #e7e7e7;
  border-radius: 24px;
  height: 100%;
}

.inactive-box {
  background-color: #f6f6f6;
  border-radius: 24px;
  height: 100%;
}

.active-info-area {
  background-color: #E6D2FA;
  border-radius: 8px;
  color: #7f2fdf;
  font-weight: 400;
}

.inactive-info-area {
  background-color: #E7E7E7;
  border-radius: 8px;
  font-weight: normal;
}

.meta-info-area {
  background-color: #D6D6D6;
  border-radius: 8px;
}

.meta-info-area-white {
  background-color: #FFFFFF;
  border-radius: 8px;
}

.active-inputs-text {
  color: #7823DC
}

.normal-input-text {
  color: #636363;
  font-size: 14px;
  font-weight: 600;
}

.input-button {
  border-radius: 8px;
  margin-top: 8px;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  width: 60px;
  padding-left: 8px;
  padding-right: 8px;
}

.list-group {
  padding-left: 18px
}

.view-hidden {
  visibility: hidden;
}

.analyze-button {
  color: #FFFFFF;
  border-radius: 25px;
}

</style>
