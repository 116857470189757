<template>
    <div class="shadow-lg p-2 h-full form-height flex flex-col justify-between">
        <div class="text-sm text-left mt-5">
            <p class="font-semibold mb-5">Input Values</p>
            <div class="bg-white">
                <label for="waxy70n" class="block text-xs">Waxy 70N (₹/mt)</label>
                <input id="waxy70n" type="number" v-model="waxy70n"           min="1"
          step="1000"
                    class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none" />
            </div>
            <div class="bg-white">
                <label for="waxy150n" class="block text-xs">Waxy 150N (₹/mt)</label>
                <input id="waxy150n" type="number" v-model="waxy150n"           min="1"
          step="1000"
                    class="bg-gray-100 w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none" />
            </div>
            <div class="bg-white">
                <label for="waxy500n" class="block text-xs">Waxy 500N (₹/mt)</label>
                <input id="waxy500n" type="number" v-model="waxy500n"           min="1"
          step="1000"
                    class="bg-gray-100  w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none" />
            </div>

            <!-- <div class="bg-white">
                <label for="waxy70n" class="block text-xs">Waxy 70N (₹/mt)</label>
                <select v-model="waxy70n" id="waxy70n"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="39203">39203</option>
                    <option value="39321">39321</option>
                </select>
            </div>

            <div class="bg-white">
                <label for="waxy150n" class="block text-xs">Waxy 150N (₹/mt)</label>
                <select v-model="waxy150n" id="waxy150n"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="42721">42721</option>
                    <option value="43141">43141</option>
                </select>
            </div>

            <div class="bg-white">
                <label for="waxy500n" class="block text-xs">Waxy 500N (₹/mt)</label>
                <select v-model="waxy500n" id="waxy500n"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="51588">51588</option>
                    <option value="50674">50674</option>
                </select>
            </div> -->
        </div>
        <button @click="sendFormValues"
            class="w-full border font-semibold rounded text-xs kearney-purple-button text-white shadow-lg py-2">
            Estimate
        </button>
    </div>
</template>

<script>
export default {
    name: 'YieldOptimizerForm',
    data() {
        return {
            waxy70n: this.formInput.pr_70 || "",
            waxy150n: this.formInput.pr_150 || "",
            waxy500n: this.formInput.pr_500 || ""
        };
    },
    props: {
        formInput: Object
    },
    methods: {
        sendFormValues() {
            const formValues = {
                pr_70: this.waxy70n,
                pr_150: this.waxy150n,
                pr_500: this.waxy500n
            };
            this.$emit("form-values-updated", formValues);
        }
    }
}
</script>

<style scoped>
.form-height {
    height: calc(100vh - 4rem);
}
</style>