<template>
  <div class="border bg-white shadow-lg h-96">
    <div class="flex items-center justify-between">
      <h2 class="font-bold purple-text text-lg mt-2 ml-4">
        Asset {{ assetId }} Wind Turbine Health Map
      </h2>
      <div class="flex mt-2 mr-4">
        <div class="bg-green-custom text-white font-bold text-xs text-center py-1 px-2">
          Normal Operations
        </div>
        <div class="bg-yellow-custom text-white font-bold text-xs text-center py-1 px-2">
          Medium-term risk
        </div>
      </div>
    </div>
    <div class="grid-container h-64 overflow-y-auto">
      <div class="grid grid-cols-10 gap-2 m-6">
        <div v-for="(item, index) in gridData" :key="index" class="p-4 grid-item h-16">
          <img :src="TurbineGreenIcon" v-if="item[1] === 'Normal Operations'" />
          <img :src="TurbineYellowIcon" v-if="item[1] === 'Medium-term risk'" />
          <img :src="TurbineRedIcon" v-if="item[1] === 'Short-term risk'" />
          A{{ ++index }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TurbineRedIcon from "@/assets/img/turbine-asset-red.svg";
import TurbineYellowIcon from "@/assets/img/turbine-asset-yellow.svg";
import TurbineGreenIcon from "@/assets/img/turbine-asset-green.svg";

export default {
  name: "HealthView",
  data() {
    return {
      TurbineRedIcon,
      TurbineGreenIcon,
      TurbineYellowIcon
    }
  },
  props: {
    gridData: Array,
    assetId: Number
  }
};
</script>

<style scoped>
.grid-container {
  height: 50vh;
}
.bg-green-custom {
  background-color: #329832;
}
.bg-red-custom {
  background-color: #ff0000;
}
.bg-yellow-custom {
  background-color: #febf00;
}
</style>