<template>
    <div v-if="visible" class="overlay">
        <div class="spinner"></div>
        <p class="loading-text ml-10">Optimization in progress, it may take some time.</p>
    </div>
</template>
  
  
<script>
export default {
    name: "Overlay",
    props: {
        visible: Boolean,
    }
};
</script>
  
<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.934);
    /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    /* Ensure it's above other content */
}

.spinner {
    border: 6px solid #ccc;
    border-top: 6px solid #7824DB;
    /* Purple color for the spinner */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
}

.loading-text {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}</style>
  