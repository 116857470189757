<template>
  <div class="table-class border text-center shadow-lg mt-2 h-12">
    <div class="grid-container bg-white">
      <!-- Header Row -->
      <div class="bg-purple-600 text-white font-bold py-2 px-4 border-b border-gray-300"># of Turbines</div>
      <div class="bg-purple-600 text-white font-bold py-2 px-4 border-b border-gray-300">Medium-term Risk (5-15 days)</div>
      <!-- Data Rows -->
      <div class="py-2 px-4 border-b border-gray-300">
        <div>{{ tableData.totalInverterCount }}</div>
      </div>
      <div class="py-2 px-4 border-b border-gray-300">
        <div>{{ tableData.totalMediumRisk }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HealthTable",
  props: {
    tableData: Object
  }
};
</script>

<style scoped>
.table-class  {
  margin-top: 14rem;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1px;
}
</style>