<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <!-- Main Content Area -->
    <div class="container flex-1 p-4 overflow-y-auto mb-16">
      <WindTurbineShortTermRisk :asset-name="assetName"/>
    </div>
  </div>
</template>

<script>
import TreeMenu from '@/components/PredictiveMaintenance/WindTurbine/TreeMenu.vue';
import WindTurbineShortTermRisk from "@/components/PredictiveMaintenance/WindTurbine/PredictiveMaintenanceShortTermRisk/index.vue";

export default {
  name: "WindTurbineSTRiskView",
  components: {
    TreeMenu,
    WindTurbineShortTermRisk
  },
  data(){
    return {
      assetName: "C7" // todo
    }
  }
};
</script>
<style scoped>

</style>
