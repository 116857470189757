<template>
    <div class="news-headline p-2 rounded-md mt-2">
      <div class="news-container">
        <marquee behavior="" direction="">
          <div class="news-item px-5 py-1 rounded-md bg-white mx-2">
            {{ breakingNews && breakingNews[0][8] }}
          </div>
          <div class="news-item px-5 py-1 rounded-md bg-white mx-2">
            {{ breakingNews && breakingNews[0][9] }}
          </div>
          <div class="news-item px-5 py-1 rounded-md bg-white mx-2">
            {{ breakingNews && breakingNews[0][10] }}
          </div>
        </marquee>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      breakingNews: Array,
      default: []
    },
  };
  </script>
  
  <style scoped>
  .news-headline {
    overflow: hidden;
    background-color: #243058;
    white-space: nowrap;
  }
  
  .news-container {
    display: inline-flex;
  }

  .news-container marquee div {
    padding: 0 10px;
    border: 3px solid #FFBF99;
    display: inline-block;
    /* color: white */
  }
  
  .news-item {
    padding: 0 10px;
    border: 3px solid #FFBF99;
    display: inline-block;
  }
/*   
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  } */
  </style>
  