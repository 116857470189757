<template>
  <div class="flex items-center justify-center h-full">
    <!-- Left half with an image -->
    <div class="w-1/2 h-full relative">
      <img :src="require('@/assets/img/kearney-logo-black.svg')" class="p-5" alt="logo">
      <div v-if="!authenticated" class="absolute flex flex-col items-center" style="top: 25%">
        <h2 class="font-bold text-3xl mt-20">Sign in</h2>
        <p class="text-md text-gray-500 my-5">
          Welcome back! Please Sign in to your account.
        </p>
        <button @click="signIn" class="bg-black text-white py-3 rounded mt-5"
          style="padding-left: 80px; padding-right: 80px;">Sign In with Kearney SSO</button>
        <div class="w-2/3 text-justify text-xs mt-10">
          By clicking on 'Sign In', you confirm to have read the 'Kearney Privacy Statement' and provide consent to use
          your personal information like Name and Email ID. <a class="privacy-statement"
            href="https://www.kearney.com/client-privacy-policy">view privacy statement</a>
        </div>
      </div>
    </div>
    <div class="w-1/2 relative">
      <img :src="require('@/assets/img/sso.webp')" alt="Image" class="w-full h-full object-cover" style="width: 100vw;" />
      <div class="absolute inset-0 flex flex-col justify-center bg-gray-100 bg-opacity-5 text-white px-10">
        <p class="font-bold text-3xl">Kearney - Catalyst Platform</p>
        <p class="mt-5">A one-stop place for all AI and advanced analytics use-cases across EPI for your client proposals,
          demos, and projects.</p>
      </div>
    </div>

  </div>
</template>



<script>
import { ref, defineEmits } from 'vue';
import { authConfig, pca } from '@/config/authConfig.js';

export default {
  setup(_, context) {

    const authenticated = ref(false);

    const signIn = async () => {
      const loginRequest = {
        scopes: ['openid', 'profile'],
      };

      try {
        await pca.initialize();
        const response = await pca.loginPopup(loginRequest);
        if (response.account) {
          authenticated.value = true;
          context.emit('authenticated', response.account.name);
        }
      } catch (error) {
        console.error('Authentication error:', error);
      }
    };
    return { signIn, authenticated };
  },
};
</script>


<style scoed>
.bgg-black {
  background-color: #323232;
}

.privacy-statement {
  color: #7823DC;
  text-decoration: underline;
}
</style>
