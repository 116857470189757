<template>
  <div>
    <div class="m-10 border bg-white text-center shadow-lg">
      <p class="font-bold mt-4 text-2xl text-left mx-10 purple-text">YIELDify</p>
      <p class="text-left mx-10 text-sm"><i>Yield Improvement Engine Leveraging Data Intelligence for you</i></p>
      <p class="text-left mx-10 mt-10 mb-5 font-bold">Product Pricing</p>
      <div class="flex mx-10 mt-2 justify-between text-sm text-left">
        <div class="bg-white rounded-md p-4 border-2 w-1/3 mr-2">
          <label for="waxy70n" class="block mb-2 font-bold">Waxy 70N (₹/mt)</label>
          <input v-model="waxy70n" id="waxy70n" type="number" min="1" step="1000"
            class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none" />
        </div>
        <div class="bg-white rounded-md p-4 border-2 w-1/3 mr-2">
          <label for="waxy150n" class="block mb-2 font-bold">Waxy 150N (₹/mt)</label>

          <input v-model="waxy150n" id="waxy150n" type="number" min="1" step="1000"
            class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none " />
        </div>
        <div class="bg-white rounded-md p-4 border-2 w-1/3">
          <label for="waxy500n" class="block mb-2 font-bold">Waxy 500N (₹/mt)</label>

          <input v-model="waxy500n" id="waxy500n" type="number" min="1" step="1000"
            class="bg-gray-100  w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none" />
        </div>

        <!-- <div class="bg-white rounded-md p-4 border-2 w-1/3 mr-2">
        <label for="waxy70n" class="block mb-2 font-bold">Waxy 70N (₹/mt)</label>
        <select v-model="waxy70n" id="waxy70n"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="39203">39203</option>
          <option value="39321">39321</option>
        </select>
      </div>
      <div class="bg-white rounded-md p-4 border-2 w-1/3 mr-2">
        <label for="waxy150n" class="block mb-2 font-bold">Waxy 150N (₹/mt)</label>
        <select v-model="waxy150n" id="waxy150n"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="42721">42721</option>
          <option value="43141">43141</option>
        </select>
      </div>
      <div class="bg-white rounded-md p-4 border-2 w-1/3">
        <label for="waxy500n" class="block mb-2 font-bold">Waxy 500N (₹/mt)</label>
        <select v-model="waxy500n" id="waxy500n"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="51588">51588</option>
          <option value="50674">50674</option>
        </select>
      </div> -->
      </div>
      <button @click="sendFormValues"
        class="border font-semibold rounded font-sm kearney-purple-button shadow-lg px-20 py-2 mt-20 mb-20">
        Estimate
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "YieldOptimizerInput",
  data() {
    return {
      waxy70n: "",
      waxy150n: "",
      waxy500n: ""
    };
  },
  methods: {
    showOutputPage() {
      this.$emit('show-output');
    },

    sendFormValues() {
      if (this.waxy70n && this.waxy150n && this.waxy500n) {
        const formValues = {
          pr_70: this.waxy70n,
          pr_150: this.waxy150n,
          pr_500: this.waxy500n
        };
        this.$emit("form-values-updated", formValues);
      }
      else {
        alert("Please input all the prices.");
      }

    },
  }
};
</script>
