<template>
  <div class="flex flex-col overflow-y-auto px-5 pb-3 pt-10 shadow">
    <Navbar />
    <div>
      <Loader :loading="loading"/>
    <div class="px-10">
      <!-- <header class="text-[1.3rem] font-semibold">Text Synthesizer</header> -->
      <div v-if="isBrowsePage">
        <div>
          <div class="flex justify-center items-center">
            <div
              class="flex justify-center items-center flex-col mt-[20px] w-[100%] rounded-[20px] bg-[#EEF6FF] border-dashed border-2 border[#2774CF]">
              <!-- <uploadCloud class="mt-[10px]" /> -->
              <div class="mt-[10px] mb-[35px]">
                <svg width="120" height="134" viewBox="0 0 168 134" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Group 237244">
                    <path id="Vector"
                      d="M87.635 33.052L87.7281 33.0775L87.73 33.0754C88.7202 33.2974 89.745 32.771 90.0432 31.8465C92.9133 22.9981 101.96 16.8053 112.055 16.8053C113.113 16.8053 113.995 16.0149 113.995 15.0119C113.995 14.0088 113.113 13.2185 112.055 13.2185C99.8717 13.2185 89.6208 20.6619 86.3273 30.8169C86.0145 31.7795 86.6165 32.7733 87.635 33.052Z"
                      fill="#4C95EB" stroke="#F9FFF9" stroke-width="0.322847" />
                    <path id="Vector_2"
                      d="M136.954 94.9801H126.283C125.301 94.9801 124.504 94.2492 124.504 93.3481C124.504 92.447 125.301 91.7161 126.283 91.7161H136.954C151.665 91.7161 163.634 80.7339 163.634 67.2363C163.634 53.7387 151.665 42.7565 136.954 42.7565H136.698C136.182 42.7565 135.692 42.5513 135.354 42.1934C135.016 41.8356 134.863 41.3611 134.937 40.8925C135.096 39.876 135.176 38.8548 135.176 37.8605C135.176 26.1627 124.802 16.6445 112.054 16.6445C107.094 16.6445 102.364 18.0667 98.3747 20.7583C97.4981 21.3493 96.2531 21.087 95.7462 20.2022C84.4483 0.46225 54.9392 -2.18863 39.6403 14.9834C33.1955 22.2178 30.6632 31.6285 32.6923 40.8005C32.9159 41.8135 32.0711 42.7577 30.9493 42.7577H30.2366C15.5263 42.7577 3.5574 53.7399 3.5574 67.2375C3.5574 80.7351 15.5263 91.7173 30.2366 91.7173H40.9081C41.8901 91.7173 42.6867 92.4482 42.6867 93.3493C42.6867 94.2504 41.8902 94.9813 40.9081 94.9813H30.2366C13.5645 94.9813 0 82.5351 0 67.2374C0 52.3688 12.8135 40.1941 28.8403 39.5227C27.3349 30.0069 30.2137 20.4085 36.886 12.9178C53.2656 -5.46882 84.6561 -3.40792 97.9256 17.0945C102.159 14.6594 107.003 13.3819 112.053 13.3819C127.499 13.3819 139.739 25.4447 138.668 39.5379C154.547 40.3551 167.19 52.4655 167.19 67.2362C167.19 82.5351 153.626 94.9801 136.954 94.9801L136.954 94.9801Z"
                      fill="#4C95EB" />
                    <path id="Vector_3"
                      d="M38.7292 92.4271C38.7292 115.026 58.7623 133.389 83.3565 133.389C107.951 133.389 127.984 115.026 127.984 92.4271C127.984 69.8278 107.951 51.4655 83.3565 51.4655C58.762 51.4655 38.7292 69.8281 38.7292 92.4271ZM42.6101 92.4271C42.6101 71.8324 60.8757 55.0531 83.3565 55.0531C105.837 55.0531 124.103 71.8321 124.103 92.4271C124.103 113.022 105.837 129.801 83.3565 129.801C60.8761 129.801 42.6101 113.022 42.6101 92.4271Z"
                      fill="#4C95EB" stroke="#F9FFF9" stroke-width="0.322847" />
                    <path id="Vector_4"
                      d="M82.7058 108.851C82.7058 109.612 83.3746 110.207 84.1693 110.207C84.964 110.207 85.6329 109.613 85.6329 108.851V77.7856C85.6329 77.024 84.9641 76.4294 84.1693 76.4294C83.3746 76.4294 82.7058 77.024 82.7058 77.7856V108.851Z"
                      fill="#4C95EB" stroke="#B2CFF1" stroke-width="0.322847" />
                    <path id="Vector_5"
                      d="M93.5559 88.3109C93.8421 88.5735 94.2159 88.7033 94.5858 88.7033L74.7816 88.3108L84.1687 79.6976L93.5559 88.3109ZM72.7217 88.3109C73.292 88.8343 74.2119 88.8345 74.7815 88.3109L83.1386 76.825L72.7217 86.3832C72.1432 86.914 72.1432 87.7801 72.7217 88.3109ZM85.1986 76.8249C84.6282 76.3015 83.7084 76.3014 83.1387 76.8249L94.5858 88.7033C94.9554 88.7033 95.3295 88.5745 95.6159 88.3107C96.1942 87.7799 96.1941 86.914 95.6157 86.3832L85.1986 76.8249Z"
                      fill="#4C95EB" stroke="#B2CFF1" stroke-width="0.322847" />
                  </g>
                </svg>
              </div>
              <span class="mt-[5px] text-[#414141] text-[20px] font-bold">Drag & drop files or

                <span class="my-[5px] text-[#4C95EB] text-[20px] font-bold"><input type="file" @change="handleFileUpload"
                    accept=".pdf,.txt" class="mb-4" style="display: none" id="fileInput" />
                  <label for="fileInput" class="font-bold py-2 rounded cursor-pointer underline decoration-auto"
                    @dragover.prevent @drop="handleDrop" @dragleave="handleDragLeave" @dragenter="handleDragEnter"
                    @dragexit="handleDragExit">Browse</label></span></span>

              <span class="my-[5px] text-[#676767] text-[16px] font-sans">Supported format - .pdf and .txt</span>


              <div class="mb-[20px]">
                <UploadFilesData @enable-submit="handleEnableBtn" ref="childComponent" />
              </div>
              <div v-if="selectedFile.length >0" class="flex items-center space-x-5">
                <span class="font-bold">Selected Files: </span>
                <span v-for="(file, index) in selectedFile" :key="index" class="my-[5px] text-[#676767] text-[16px] font-sans">{{ file.name }}</span>
              </div>

              

              <!-- <AlertMsg /> -->
            </div>

          </div>
        </div>
        <div class="border-"></div>

        <!-- <h5 class="mb-2">Select a file:</h5>
          <input
            type="file"
            @change="handleFileUpload"
            accept=".pdf,.txt"
            class="mb-4"
          /> -->
        <div class="flex justify-center align-middle mt-[15px]">
          <button class="bg-[#243058] hover:bg-blue-700 text-white font-bold py-1 px-6 rounded mr-[15px]"
            @click="loadFromUrl">
            From URL
          </button>
          <button class="bg-[#243058] hover:bg-blue-700 text-white font-bold py-1 px-8 rounded ml-[15px]"
            @click="showPreview">
            Next
          </button>
        </div>
      </div>
      <div v-if="!isBrowsePage" class="flex space-x-5 my-5">
        <div class="w-2/3 pdf-container">
          <div v-if="showFilePreview" style="overflow-y: scroll;">
            <iframe v-for="(pdf, index) in selectedFilePreview" :key="index" :src="pdf" class="border w-full mb-10"
              style="height: 85vh;"></iframe>
          </div>
        </div>
        <div class="w-1/3">
          <div v-if="!isBrowsePage">
            <ChatbotView class="w-full" />
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
</template>

<script>
import axios from "axios";
import ChatbotView from "@/components/ChatbotView.vue"
import Loader from '@/components/Loader.vue'
import Navbar from '@/components/Navbar.vue'

export default {
  name: "TextSynthesizer",
  components: {
    ChatbotView,
    Loader,
    Navbar
  },
  data() {
    return {
      isBrowsePage: true,
      loading: false,
      selectedFile: [],
      selectedFilePreview: [],
      showFilePreview: false,
      urlInput: "", // Added for inputting URL
      pdfUrl: "",
      pdfUploaded: false,
      isDragging: false,
    };
  },
  mounted() {
    sessionStorage.clear()
    sessionStorage.setItem("session_id", this.generateSessionId())
  },
  methods: {
    handleFileUpload(event) {
      const files = event.target.files;
      console.log(event)
      if (files) {
        for (let file of files) {
          if (file.type === "application/pdf" || file.type === "text/plain") {
            this.selectedFile.push(file);
            this.selectedFilePreview.push(URL.createObjectURL(file));
          } else {
            alert("Please select a PDF or text file.");
          }
        }
      }
    },

    async handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const files = event.dataTransfer;
      if (files) {
        for (let file of files) {
          if (file.type === "application/pdf" || file.type === "text/plain") {
            this.selectedFile.push(file);
            this.selectedFilePreview.push(URL.createObjectURL(file));
          } else {
            alert("Please select a PDF or text file.");
          }
        }
      }
    },

    handleDragEnter(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    async loadFromUrl() {
      this.showFilePreview = false; // Reset preview flag
      const url = prompt("Enter the URL of the PDF or text file:");
      if (url) {
        try {
          const response = await axios.get(url, { responseType: "blob" });
          const fileBlob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          this.selectedFile.push(fileBlob); // Assign to selectedFile to trigger preview update
          this.selectedFilePreview.push(URL.createObjectURL(fileBlob)); // Set preview URL
          this.pdfUrl = ""; // Clear URL-uploaded PDF URL
          this.pdfUploaded = false; // Clear URL upload flag
        } catch (error) {
          console.error("Error uploading PDF from URL:", error);
          alert(
            "Error uploading PDF from URL. Please check the URL and try again."
          );
        }
      }
    },

    showPreview() {
      if ((this.selectedFile.length > 0 || this.pdfUrl) && this.selectedFilePreview.length > 0) {
        this.uploadFile();
      } else {
        alert("Please select a file or provide a URL first.");
      }
    },

    uploadFile(){
      this.loading=true;
      const apiUrl = `https://powerpulseapicombustdev.kearney.com/pdf-qa-upload-file`;
      let formData = new FormData();
      formData.append('session_id', sessionStorage.getItem("session_id"));
      formData.append('pdf_qa_file', this.selectedFile[0]);
      axios
        .post(apiUrl, formData)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.error("POST error:", error);
        }).finally(()=>{
          this.isBrowsePage = !this.isBrowsePage;
          this.showFilePreview = true;
          this.loading=false;
        })
    },

    generateSessionId() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let sessionId = '';
      for (let i = 0; i < 10; i++) {
        sessionId += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return sessionId;
    }
  },
};
</script>

<style scoped>
.pdf-container {
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 1px solid #ccc;
  border-radius: 10px;
}
</style>
