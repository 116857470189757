<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 h-screen-auto bg-white shadow-md rounded-md p-4 mx-4">
        <div class="h-16 mb-4">
          <h1 class="font-bold text-[2rem] mt-2">Command Center</h1>
        </div>
        <div class="flex items-center justify-between mb-4 pl-1 text-sm">
          <div class="ml-8 bg-white rounded-md overflow-hidden w-[90%] pb-2 relative image-container" 
            :style="{
              backgroundImage: 'url(' + icons.dcmCover + ')',
              backgroundSize: 'contain',      // Ensures image fits without being cut off
              backgroundPosition: 'center',   // Centers the image within the container
              backgroundRepeat: 'no-repeat',  // Prevents the image from repeating
              height: '609px', //+ 'px'       // Dynamically set the height based on the image aspect ratio
              position: 'relative'
            }">
            <div class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[3%] left-[40%] sm:left-[35%] md:left-[30%] transform -translate-x-1/2 text-black font-semibold">
              <!-- 6.75% -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TreeMenu from './TreeMenu.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "cokeLanding",
  components: {
    TreeMenu,
    Datepicker
  },
  data() {
    return {
      icons: {
        dcmCover: require('@/assets/img/dcm-view.png'),  // Path to your image
      },
      imageHeight: 0,   // This will store the calculated height of the image
    };
  },
  mounted() {
    this.calculateImageHeight();
    
    // const button = document.getElementById('hamburger');

    // if (button) {
    //   button.click();
    // }
  },
  methods: {
    calculateImageHeight() {
      const image = new Image();
      image.src = this.icons.dcmCover;

      image.onload = () => {
        // Calculate the aspect ratio of the image
        const aspectRatio = image.height / image.width;
        // Set the height of the container based on the image width (adjust this as needed)
        this.imageHeight = this.$el.clientWidth * aspectRatio;
      };
    },
  },
};
</script>

<style>
.dummy {
  position: absolute;
  left: 24%;
  top: 15%;
  font-size: 0.675rem;
  font-weight: 600;
}
.blue-custom {
  background: #232B60;
}

.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}

.tab-button {
  border: #232B60 1px solid;
  font-size: 1rem;
}

.dp__input_icon_pad {
  height: 33px;
}
</style>
