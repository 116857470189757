<template>
    <highcharts :options="donutOptions" />
</template>

<script>
export default {
    props: {
        selectedCategory: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            baseColors: ['#8A2404', '#8A2404', '#8A2404', '#8A2404', '#8A2404', '#8A2404'],
            highlightColor: '#FF5722', // This is the color used to highlight selected slices
            donutOptions: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    height: 150
                },
                title: {
                    text: '', //this.selectedCategory,
                    // align: 'center',
                    // verticalAlign: 'middle',
                    // y: 60,
                    // style: {
                    //     fontSize: '0.8rem',
                    //     width: '100%'
                    // }
                },
                tooltip: {
                    pointFormat: ''
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: false,
                            distance: -50,
                            style: {
                                fontWeight: 'bold',
                                color: 'white'
                            }
                        },
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '75%'],
                        size: '110%',
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '50%',
                    data: [
                        ['Short', 30.00],
                        ['Short-Medium', 30.00],
                        ['Medium', 30.00],
                        ['Medium-Long', 30.00],
                        ['Long', 30.00],
                        ['Short-Medium-Long', 30.00]
                    ]
                }]
            }
        };
    },
    computed: {
        highlightedColors() {
            let highlightIndex = 0;
            switch (this.selectedCategory) {
                case 'Short term':
                    highlightIndex = 1;
                    break;
                case 'Short and medium term':
                    highlightIndex = 2;
                    break;
                case 'Medium term':
                    highlightIndex = 3;
                    break;
                case 'Medium and long term':
                    highlightIndex = 4;
                    break;
                case 'The news article will have a long-term':
                    highlightIndex = 5;
                    break;
                default:
                    highlightIndex = 6;
                    break;
            }

            return this.baseColors.map((color, index) =>
                index < highlightIndex ? this.highlightColor : color
            );
        }
    },
    watch: {
        highlightedColors(newColors) {
            this.donutOptions.plotOptions.pie.colors = newColors;
        }
    },
    mounted() {
        this.donutOptions.plotOptions.pie.colors = this.highlightedColors;
    }
};
</script>
