<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <!-- Main Content Area -->
    <div class="flex-1 p-4 overflow-y-auto mb-20">
      <div class="grid grid-rows-3 h-screen">
        <HealthView class="mt-8" :gridData="assetOverviewHealthMapData" :assetId="assetId" />
        <HealthTable class="mt-8 mb-20" :tableData="tableData" />
        <div class="grid grid-cols-2 gap-4 pt-4 mt-20 barChart">
          <div class="border bg-white text-center shadow-lg pt-4 h-80" v-for="v in mapData">
            <BarCharts :data="v" :chart-options="barChartOptions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HealthView from './HealthView.vue';
import HealthTable from './HealthTable.vue';
import HealthMap from './HealthMap.vue';
import TreeMenu from '../TreeMenu.vue';
import { mapState, mapActions } from 'vuex';
import BarCharts from "@/components/PredictiveMaintenance/WindTurbine/PredictiveMaintenanceAssetOverview/BarCharts.vue";

export default {
  name: "WindTurbineAssetOverview",
  components: {
    HealthView,
    HealthTable,
    HealthMap,
    TreeMenu,
    BarCharts
  },
  data() {
    return {
      barChartOptions: {
        backgroundColor: "",
        height: "280",
      }
    }
  },
  computed: {
    ...mapState('sheets', [
      'predictiveMaintenanceData'
    ]),
    assetId() {
      return this.$route.params.asset;
    },
    assetOverviewHealthMapData() {
      return this.predictiveMaintenanceData['Asset 6 Turbine Health Map'].slice(1).filter(asset => asset[2] == this.assetId)
    },
    tableData() {
      const assetData = this.predictiveMaintenanceData['Asset 6 Turbine Health Map'].slice(1)
      const totalInverterCount = assetData.filter(asset => asset[2] == this.assetId).length
      const totalMediumRisk = assetData.filter(asset => asset[2] == this.assetId && asset[1] === 'Medium-term risk').length

      return { totalInverterCount, totalMediumRisk }
    },
    mapData() {
      const mapData = this.predictiveMaintenanceData['Asset 6 overview data'].slice(1)
      const filteredData = mapData.filter(data => data[9] == this.assetId)

      const convertedData = filteredData.map(entry => {
        const [title, , , , , planned, actual, predictedNC, predictedREC] = entry;

        return {
          title,
          planned: planned || 0,  // Default to 0 if undefined
          actual: actual || 0,
          predictedNC: predictedNC || 0,
          predictedREC: predictedREC || 0
        };
      });

      // Format the data for charting
      const convertedDataFormat = convertedData.map(v => {
        const { title, planned, actual, predictedNC, predictedREC } = v;
        return {
          title,
          data: [
            { name: 'Planned Downtime', y: Math.round(planned) },
            { name: 'Actual MTD', y: Math.round(actual) },
            { name: 'Predicted -<br> No Change', y: Math.round(predictedNC) },
            { name: 'Predicted -<br> Recommended', y: Math.round(predictedREC) }
          ]
        };
      });
      return convertedDataFormat;
    }
  },
  mounted() {
    this.fetchAndStorePredictiveMaintenanceData();
  },
  methods: {
    ...mapActions('sheets', ['fetchAndStorePredictiveMaintenanceData']),
  }
};
</script>
<style scoped>
.barChart{ 
  margin-top: 6.5rem;
}</style>
