<template>
 <div class="flex justify-start">
    <span class="mx-5">{{ text }}</span>
    <div class="flex justify-center items-center">
      <span v-if="showPurpleLegend" class="purple-legend mr-1"></span><span v-if="showPurpleLegend" class="mr-4">Current</span>
      <span v-if="showAquaLegend" class="aqua-legend mr-1"></span><span v-if="showAquaLegend" class="mr-4">Suggested</span>
      <span v-if="showRedLegend" class="red-legend mr-1"></span><span v-if="showRedLegend" class="mr-4">Above/Below Threshold</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Legend',
  props: {
    text: String,
    showPurpleLegend: {
      type: Boolean,
      default: true
    },
    showAquaLegend: {
      type: Boolean,
      default: true
    },
    showRedLegend: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.purple-legend {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #7824DB;
}

.red-legend {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #E71818;
}

.aqua-legend {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background-color: #55A8B5;
}
</style>