<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 overflow-y-auto mb-20">
      <div class="flex items-center justify-between">

        <h1 class="font-bold text-lg mt-2 ml-2">
          <span class="purple-text">Asset {{ assetId }} Combiner Box Throughput</span>
        </h1>
      </div>
      <div class="grid grid-cols-2 gap-1 pt-2 mt-4 barChart">
        <div class="border bg-white text-center shadow-lg h-full">
          <div class="chart-container p-3">
            <div ref="chart" class="chart"></div>
          </div>
        </div>
        <div class="border bg-white text-center shadow-lg h-full">
          <div class="chart-container p-3">
            <div ref="chart1" class="chart"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TreeMenu from '@/components/PredictiveMaintenance/SolarInverter/TreeMenu.vue';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';

export default {
  name: "CombinerBoxThroughput",
  components: {
    TreeMenu,
    HighchartsVue
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
        },
        title: {
          text: 'Cumulative MWh - Last week average',
          align: 'left',
          margin: 50, // Adjust this value to increase the gap between the title and the chart
          style: {
            color: '#7824DB' // Set the color of the chart title
          }
        },
        xAxis: {
          categories: [], // Will be populated dynamically
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Count Throughput',
          },
          stackLabels: {
            enabled: true
          },
          plotLines: [{
            color: 'black', // Color of the vertical line
            width: 2,     // Width of the line
            value: 410,    // x-axis value where the line will be drawn (0-based index, so x=20 is index 19)
            dashStyle: 'ShortDash', // Style of the line (e.g., solid, dash, dot, etc.)
            label: {
              text: '', // Label for the line (optional)
              align: 'left',
              verticalAlign: 'middle',
              y: 10,
              style: {
                color: 'red',
                fontWeight: 'bold'
              }
            }
          }]
        },
        legend: {
          align: 'right',
          x: 0,
          verticalAlign: 'top',
          y: 25,
          floating: true,
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
          borderColor: '#CCC',
          borderWidth: 1,
          shadow: false
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
          bar: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            },
            pointPadding: 0.05,  // Adjust this value to reduce the gap between bars within a group
            groupPadding: 0.05,  // Adjust this value to reduce the gap between groups of bars
            pointWidth: 10,     // Set the width of the bars directly (adjust as needed)
          }
        },
        series: [{
          name: 'Excess Load',
          color: '#febf00', // Light gray color for all bars in this series
          data: [] // Will be populated dynamically
        },{
          name: 'Normal Load',
          color: 'green', // Gray color for all bars in this series
          data: [] // Will be populated dynamically
        }]
      },
      chartOptions1: {
        chart: {
          type: 'bar',
          // margin: [120, 50, 100, 80] // Adjust margins if necessary
        },
        title: {
          text: 'Cumulative MWh - Last Month average',
          align: 'left',
          margin: 50, // Adjust this value to increase the gap between the title and the chart
          style: {
            color: '#7824DB' // Set the color of the chart title
          }
        },
        xAxis: {
          categories: [], // Will be populated dynamically
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Count Throughput',
          },
          stackLabels: {
            enabled: true
          },
          plotLines: [{
            color: 'black', // Color of the vertical line
            width: 2,     // Width of the line
            value: 1640,    // x-axis value where the line will be drawn (0-based index, so x=20 is index 19)
            dashStyle: 'ShortDash', // Style of the line (e.g., solid, dash, dot, etc.)
            label: {
              text: '', // Label for the line (optional)
              align: 'left',
              verticalAlign: 'middle',
              y: 10,
              style: {
                color: 'red',
                fontWeight: 'bold'
              }
            }
          }]
        },
        legend: {
          align: 'right',
          x: 0,
          verticalAlign: 'top',
          y: 25,
          floating: true,
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
          borderColor: '#CCC',
          borderWidth: 1,
          shadow: false
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
          bar: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            },
            pointPadding: 0.05,  // Adjust this value to reduce the gap between bars within a group
            groupPadding: 0.05,  // Adjust this value to reduce the gap between groups of bars
            pointWidth: 10      // Set the width of the bars directly (adjust as needed)
          },
        },
        series: [{
          name: 'Excess Load',
          color: '#febf00', // Light gray color for all bars in this series
          data: [] // Will be populated dynamically
        },{
          name: 'Normal Load',
          color: 'green', // Gray color for all bars in this series
          data: [] // Will be populated dynamically
        }]
      }
    };
  },
  mounted() {
    this.fetchAndStorePredictiveMaintenanceData();
  },
  computed: {
    ...mapState('sheets', [
      'predictiveMaintenanceData'
    ]),
    assetId() {
      return this.$route.params.asset;
    },
    monthData(){
      const RELEVANT_SHEET_NAME = "Asset 7 Combiner Box - Monthly";
      const sheetData = this.predictiveMaintenanceData[RELEVANT_SHEET_NAME];
      
      if (!sheetData) {
        return;
      }
      
      const categories = [];
      const normalData = [];
      const excessLoadData = [];

      sheetData.forEach(row => {
        categories.push(row[0]); // Combiner Name
        normalData.push(row[4]); // Normal Operation
        excessLoadData.push(row[3]); // Excess Load
      });
      this.chartOptions1.xAxis.categories = categories;
      this.chartOptions1.series[0].data = normalData;
      this.chartOptions1.series[1].data = excessLoadData;
    },
    weekData() {
      const RELEVANT_SHEET_NAME = "Asset 7 Combiner Box - Weekly";
      const sheetData = this.predictiveMaintenanceData[RELEVANT_SHEET_NAME];
      
      if (!sheetData) {
        return;
      }
      
      const categories = [];
      const normalData = [];
      const excessLoadData = [];

      sheetData.forEach(row => {
        categories.push(row[0]); // Combiner Name
        normalData.push(row[4]); // Normal Operation
        excessLoadData.push(row[3]); // Excess Load
      });
      this.chartOptions.xAxis.categories = categories;
      this.chartOptions.series[0].data = normalData;
      this.chartOptions.series[1].data = excessLoadData;
    }
  },
  methods: {
    ...mapActions('sheets', ['fetchAndStorePredictiveMaintenanceData']),
    createChart() {
      if (this.$refs.chart) {
        Highcharts.chart(this.$refs.chart, this.chartOptions);
        Highcharts.chart(this.$refs.chart1, this.chartOptions1);
      }
    }
  },
  watch: {
    monthData() {
      this.createChart();
    },
    weekData() {
      this.createChart();
    }
  },
  async created() {
    await new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 500)
    })
    this.createChart()
  }
};
</script>
<style scoped>
/* Style for the chart container to control its size */
.chart-container {
  height: 600px;
  /* Adjust the height as needed */
}

.chart {
  height: 100%;
  /* Make the chart take up the full height of its container */
}
</style>
