<template>
    <div class="map-class grid grid-cols-2 gap-8">
      <div class="border bg-white text-center shadow-lg overflow-y-auto pt-4 pb-4 h-96">
        <Bar :data="monthlyDowntime.monthlyDowntimeChartData" :options="monthlyDowntime.monthlyDowntimeChartOptions" />
      </div>
      <div class="border bg-white text-center shadow-lg overflow-y-auto pt-4 pb-4 h-96">
        <Bar :data="monthlyGeneration.monthlyGenerationChartData" :options="monthlyGeneration.monthlyGenerationChartOptions" />
      </div>
    </div>
  </template>
  <script>
  import { Bar } from 'vue-chartjs';
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
  
  export default {
    name: "HealthMap",
    components: {
      Bar
    },
    props: {
      mapData: Object
    },
    data() {
      return {
        chartData: {
          labels: [
            'Very Long Label\nThat Needs Wrapping',
            'Another Long Label\nThat Needs Wrapping',
            'A Short Label'
          ],
          datasets: [
            {
              label: 'Hours',
              data: [10, 20, 30, 40], // Values for each category
              backgroundColor: 'grey', // Color of the bars
              borderRadius: 5, // Rounded corners for bars
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false, // Allows the chart to resize based on its container
          plugins: {
            legend: {
              display: false // Hides the legend
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  return `${context.label}: ${context.raw}`; // Custom tooltip label
                }
              }
            },
            title: {
              display: true,
              text: 'Monthly Spares Spend ($M)', // Title text
              font: {
                size: 16 // Adjust the font size as needed
              },
              padding: {
                top: 10,
                bottom: 10
              },
              color: '#7824DB'
            }
          },
          scales: {
            y: {
              display: false // Hides the y-axis
            },
            x: {
              display: true, // Shows the x-axis
              maxRotation: 0, // Rotate labels by 45 degrees
              minRotation: 0,  // Ensure labels are rotated by at least 45 degrees
            }
          }
        }
      };
    },
    computed: {
      monthlyDowntime() {
        const monthlyDowntimeChartData = JSON.parse(JSON.stringify(this.chartData));
        monthlyDowntimeChartData.datasets[0].data = [this.mapData[0][5], this.mapData[0][6], this.mapData[0][7], this.mapData[0][8]];
  
        const monthlyDowntimeChartOptions = JSON.parse(JSON.stringify(this.chartOptions));
        monthlyDowntimeChartData.labels = [
          this.mapData[0][1],
          this.mapData[0][2],
          this.mapData[0][3],
          this.mapData[0][4]
        ];
        console.log(monthlyDowntimeChartData.labels, 'monthlyDowntimeChartData.labels')
        monthlyDowntimeChartOptions.plugins.title.text = this.mapData[0][0];
        
        return { monthlyDowntimeChartData, monthlyDowntimeChartOptions };
      },
      monthlyGeneration() {
        const monthlyGenerationChartData = JSON.parse(JSON.stringify(this.chartData));
        monthlyGenerationChartData.datasets[0].data = [this.mapData[1][5], this.mapData[1][6], this.mapData[1][7], this.mapData[1][8]];
        monthlyGenerationChartData.datasets[0].label = 'MUs'

        const monthlyGenerationChartOptions = JSON.parse(JSON.stringify(this.chartOptions));
  
        monthlyGenerationChartData.labels = [
          this.mapData[1][1],
          this.mapData[1][2],
          this.mapData[1][3],
          this.mapData[1][4]
        ];

        monthlyGenerationChartData.labels.forEach((unformattedText, i) => {
          monthlyGenerationChartData.labels[i] = this.formatText(unformattedText, 10)
          console.log(monthlyGenerationChartData.labels[i]);
          console.log('---'); // Separator for readability
        });



        monthlyGenerationChartOptions.plugins.title.text = this.mapData[1][0];
  
        return { monthlyGenerationChartData, monthlyGenerationChartOptions };
      }
    },
    methods: {
      formatText(text, maxLineLength) {
  let formattedText = '';
  let currentIndex = 0;

  while (currentIndex < text.length) {
    // Find the end of the current line
    let lineEndIndex = currentIndex + maxLineLength;
    
    if (lineEndIndex < text.length) {
      // Find the last space within the line length
      let splitIndex = text.lastIndexOf(' ', lineEndIndex);
      if (splitIndex === -1 || splitIndex <= currentIndex) {
        splitIndex = lineEndIndex;
      }
      formattedText += text.slice(currentIndex, splitIndex).trim() + '\n';
      currentIndex = splitIndex + 1; // Move to the next line
    } else {
      // Add the remaining text
      formattedText += text.slice(currentIndex).trim();
      break;
    }
  }

  return formattedText;
}
    }
  };
  </script>
<style>

.map-class  {
  margin-top: 7rem;
}
</style>