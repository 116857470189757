<template>
    <div class="h-screen">
      <SootBlowingOptimizer/>
    </div>
  </template>
  
  <script>
  import SootBlowingOptimizer from "../components/SootBlowingOptimizer.vue";
  export default {
    name: "SootBlowingView",
    components: {
        SootBlowingOptimizer,
    }
  };
  </script>
  