<template>
  <div class="border bg-white shadow-lg overflow-y-auto pt-4 mb-1 h-full">
    <div class="flex items-center justify-between">

      <h1 class="font-bold purple-text text-lg mt-2 ml-4">
        Network Inverter Health Map
      </h1>

      <div class="flex mt-2 mr-2">
        <div class="bg-green-custom text-white font-bold text-xs text-center py-1 px-2">
          Normal Operations
        </div>
        <div class="bg-yellow-custom text-white font-bold text-xs text-center py-1 px-2">
          Medium-term IF risk
        </div>
        <div class="bg-red-custom text-white font-bold text-xs text-center py-1 px-2">
          Imminent IF risk
        </div>
      </div>
    </div>
    <div>
      <PoliticalMap v-if="mapData.length > 0" :data="mapData" :chart-options="chartOptions" />
    </div>
    <div style="margin-bottom: 16px">
      <table class="asset-risks-table">
        <tr class="bg-gray-100 text-center">
          <th class="border border-gray-300 px-4 py-2" rowspan="2">Asset</th>
          <th class="border border-gray-300 px-4 py-2" rowspan="2">MW</th>
          <th class="border border-gray-300 px-4 py-2" rowspan="2"># of inverters</th>
          <th class="border border-gray-300 px-4 py-2 text-center" colspan="2">Inverter failure </th>
          <!-- Main header spanning 3 columns -->
          <th class="border border-gray-300 px-4 py-2" rowspan="2">DC Fuse Failure<br>Medium-term risk</th>
          <!-- Sub-headers -->
        </tr>
        <tr class="bg-gray-200 text-left">
          <th class="border border-gray-300 px-4 py-2">Imminent risk</th>
          <th class="border border-gray-300 px-4 py-2">Medium-term risk</th>
        </tr>

        <tr v-for="v in mapData">
          <td>{{ v.name }}</td>
          <td>{{ v.mw }}</td>
          <td>{{ v.no_of_inverters }}</td>
          <td>{{ v.short_term_risk }}</td>
          <td>{{ v.medium_term_risk }}</td>
          <td>{{ v.medium_term }}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-4 pt-4">
    <div class="border bg-white text-center shadow-lg pt-4 h-80" v-for="v in downTimeData">
      <BarCharts :data="v" :chart-options="barChartOptions" />
    </div>
  </div>

</template>

<script>
import _ from "lodash";
import PoliticalMap from "@/components/PredictiveMaintenance/SolarInverter/PredictiveMaintenanceAssetOverview/PoliticalMap.vue";
import BarCharts from "@/components/PredictiveMaintenance/SolarInverter/PredictiveMaintenanceAssetOverview/BarCharts.vue";

export default {
  name: "NetworkOverview",
  components: {
    PoliticalMap,
    BarCharts
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mapData: [],
      downTimeData: [],
      chartOptions: {
        backgroundColor: "",
      },
      barChartOptions: {
        backgroundColor: "",
        height: "280",
      }
    }
  },
  async created() {
    console.log(this.data);
    const { mapData, downTimeData } = this.data || {};

    await new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 1000)
    });

    this.mapData = mapData;
    console.log(mapData, 'mapData network')
    this.downTimeData = await _.reduce(downTimeData, (acc, v) => {
      const { title, actual, planned, predictedNC, predictedREC } = v ?? {};
      acc.push({
        title,
        data: [{
          name: 'Planned', y: _.round(planned)
        }, {
          name: 'Actual MTD', y: _.round(actual)
        }, {
          name: 'Predicted -<br> No Change', y: _.round(predictedNC)
        }, {
          name: 'Predicted -<br> Recommended', y: _.round(predictedREC)
        }]
      });
      return acc;
    }, []);
    this.downTimeData = await [_.find(this.downTimeData, v => v.title === "Monthly Downtime (Hours)"),
    _.find(this.downTimeData, v => v.title === "Monthly Generation (MUs)")]
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  background-color: #dbdbdb;
  width: 100%;
  overflow-y: auto;
}

.asset-risks-table {
  margin: 0 auto;
  background-color: #FFFFFF;
  table-layout: fixed;
  width: 95%;
  font-size: 12px;
  border: 1px solid #dbdbdb;
}

.asset-risks-table,
th {
  background-color: #af7deb;
  color: #FFFFFF;
}

.asset-risks-table,
tr:nth-child(even) {
  background-color: #eeeeee;
  color: #000000;
}

.asset-risks-table,
tr:nth-child(odd) {
  background-color: #f7f7f7;
  color: #000000;
}

.asset-risks-table,
th,
td {
  border: 1px solid #FFFFFF;
}

.table-header {
  background-color: #f4f4f4;
  text-align: center;
  border: 1px solid black
}

.asset-risks-table td {
  text-align: center
}

.container-first-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ededed;
  border-radius: 30px;
  margin: 10px;
}

.container-second-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  margin: 10px;
}

.container-second-section-item {
  background-color: #ededed;
  border-radius: 10px;
}

.bg-green-custom {
  background-color: #329832;
}

.bg-red-custom {
  background-color: #ff0000;
}

.bg-yellow-custom {
  background-color: #febf00;
}
</style>