<template>
    <div class="text-left text-xs">
      <table class="w-full table-auto">
        <thead class="bg-gray-100">
          <tr class="purple-text">
            <th class="px-5 py-3">Optimal Coal Feed Rate</th>
            <th class="px-5 py-3">Present Value</th>
            <th class="px-5 py-3">Optimal Value</th>
            <th class="px-5 py-3">Recommended Bias</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in result.optimalMillParameters" :key="key">
          <td class="border-b px-5 py-2">{{ key }}</td>
          <td class="border-b px-5 py-2">{{ value['Present Value'] }}</td>
          <td class="border-b px-5 py-2">{{ value['Optimal Value'] }}</td>
          <td class="border-b px-5 py-2">{{ value['Recommended Bias'] }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    props: {
      result: Object
    }
  };
  </script>
  
  <style scoped></style>
  