<template>
    <div class="w-64 bg-gray-800 text-white p-4 h-full">
      <div class="back-button font-bold mt-2 mb-4 text-sm text-left text-white text-lg">
        <button @click="goToPredictiveMaintenance"><u>&lt;&lt; Previous Page</u></button>
      </div>
      <h2 class="text-xl font-bold mb-4">Wind Turbine</h2>
      <ul class="space-y-2">
        <li v-for="item in menuItems" :key="item.name" class="relative">
          <button
            v-if="item.subItems"
            @click="toggle(item)"
            :class="['flex items-center w-full text-left py-2 px-4 hover:bg-gray-700 focus:outline-none', { 'bg-gray-700': isActive(item) }]"
          >
            <img height="20" width="20" :src="item.icon" alt="Copy" class="w-6 h-6 mr-3"/>
            {{ item.name }}
          </button>
          <router-link
            v-else
            :to="item.url"
            :class="['flex items-center w-full text-left py-2 px-4 hover:bg-gray-700 rounded', { 'bg-gray-700': isActive(item) }]"
          >
            <img height="20" width="20" :src="item.icon" alt="Copy" class="w-6 h-6 mr-3"/>
            {{ item.name }}
          </router-link>
          <ul v-if="item.subItems && item.isOpen" class="ml-4 space-y-1">
            <li v-for="subItem in item.subItems" :key="subItem.name">
              <router-link
                :to="subItem.url"
                class="flex items-center py-2 px-4 hover:bg-gray-600 rounded"
                :class="{ 'bg-gray-600': isActive(subItem) }"
              >
                <img :src="subItem.icon" alt="" class="w-5 h-5 mr-3" />
                {{ subItem.name }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import AssetIcon from "@/assets/img/asset1-white.svg";
  import AssetOverviewIcon from "@/assets/img/asset-overview-white.svg";
  import MediumTermIcon from "@/assets/img/medium-term-white.png";
  import ShortTermIcon from "@/assets/img/short-term-white.svg";
  import NetworkOverviewIcon from "@/assets/img/network-overview-white.svg";
  
  export default {
    data() {
      return {
        AssetIcon,
        AssetOverviewIcon,
        MediumTermIcon,
        ShortTermIcon,
        NetworkOverviewIcon,
        menuItems: [
          {
            name: 'Network Overview', url: '/wind-turbine/network-overview',
            isOpen: false,
            icon: NetworkOverviewIcon
          },
          {
            name: 'Asset 1',
            isOpen: false,
            icon: AssetIcon,
            subItems: [
              { name: 'Overview', url: '/wind-turbine/1/overview', icon: AssetOverviewIcon },
              // { name: 'Short Term Risk', url: '/wind-turbine/1/short-term-risk', icon: ShortTermIcon },
              { name: 'Medium Term Risk', url: '/wind-turbine/1/medium-term-risk', icon: MediumTermIcon },
            ]
          },
          {
            name: 'Asset 2',
            isOpen: false,
            icon: AssetIcon,
            subItems: [
              { name: 'Overview', url: '/wind-turbine/2/overview', icon: AssetOverviewIcon },
              // { name: 'Short Term Risk', url: '/wind-turbine/2/short-term-risk', icon: ShortTermIcon },
              { name: 'Medium Term Risk', url: '/wind-turbine/2/medium-term-risk', icon: MediumTermIcon },
            ]
          },
          {
            name: 'Asset 3',
            isOpen: false,
            icon: AssetIcon,
            subItems: [
              { name: 'Overview', url: '/wind-turbine/3/overview', icon: AssetOverviewIcon },
              // { name: 'Short Term Risk', url: '/wind-turbine/3/short-term-risk', icon: ShortTermIcon },
              { name: 'Medium Term Risk', url: '/wind-turbine/3/medium-term-risk', icon: MediumTermIcon },
            ]
          },
          {
            name: 'Asset 4',
            isOpen: false,
            icon: AssetIcon,
            subItems: [
              { name: 'Overview', url: '/wind-turbine/4/overview', icon: AssetOverviewIcon },
              // { name: 'Short Term Risk', url: '/wind-turbine/4/short-term-risk', icon: ShortTermIcon },
              { name: 'Medium Term Risk', url: '/wind-turbine/4/medium-term-risk', icon: MediumTermIcon },
            ]
          },
          {
            name: 'Asset 5',
            isOpen: false,
            icon: AssetIcon,
            subItems: [
              { name: 'Overview', url: '/wind-turbine/5/overview', icon: AssetOverviewIcon },
              // { name: 'Short Term Risk', url: '/wind-turbine/5/short-term-risk', icon: ShortTermIcon },
              { name: 'Medium Term Risk', url: '/wind-turbine/5/medium-term-risk', icon: MediumTermIcon },
            ]
          },
          {
            name: 'Asset 6',
            isOpen: false,
            icon: AssetIcon,
            subItems: [
              { name: 'Overview', url: '/wind-turbine/6/overview', icon: AssetOverviewIcon },
              // { name: 'Short Term Risk', url: '/wind-turbine/6/short-term-risk', icon: ShortTermIcon },
              { name: 'Medium Term Risk', url: '/wind-turbine/6/medium-term-risk', icon: MediumTermIcon },
            ]
          }
        ]
      };
    },
    methods: {
      toggle(item) {
        item.isOpen = !item.isOpen;
      },
      isActive(item) {
        const currentRoute = this.$route.path;
        if (item.subItems) {
          return item.subItems.some(subItem => subItem.url === currentRoute);
        }
        return item.url === currentRoute;
      },
      goToPredictiveMaintenance() {
        this.$router.push({ name: 'predictiveMaintenanceMainView' });
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any additional styling if needed */
  </style>
  