<template>
  <div class="chatbot text-sm h-screen flex flex-col justify-between items-center">

    <div class="chatbot-container bg-white relative">
      <p class="absolute top-0 left-0 p-3 bg-gray-100 w-full rounded-t-lg flex items-center z-[888]">
        <img :src="require('@/assets/img/chat.svg')" alt="chat-icon" class="h-8 inline" />
        <span class="font-bold ml-3">Chat with your document</span>
      </p>
      <div class="chat-messages" ref="chatMessages">
        <span class="w-full flex" v-for="(message, index) in messages" :key="index" :class="{
          'justify-end': message.isUser,
          'justify-start': !message.isUser,
        }">
          <div class="flex items-start" :class="{
          'justify-end': message.isUser,
          'justify-start': !message.isUser,
        }">
            <img v-if="!message.isUser" :src="require('@/assets/img/bot.svg')" alt="bot-icon" class="h-10" />
            <div class="message" :class="{
          'user-message': message.isUser,
          'bot-message': !message.isUser,
        }" v-html="message.text">

            </div>
            <img v-if="message.isUser" :src="require('@/assets/img/user.svg')" alt="user-icon" class="h-10 mr-5" />
          </div>
        </span>
        <div class="w-full flex justify-around">
          <img v-if="isLoading" src="@/assets/gif/loading-gif.gif" alt="Loading..." class="loading-gif" />
        </div>
      </div>
      <div class="user-input flex">
        <div class="relative flex-1">
          <div class="input-container">
            <input class="input-field" v-model="userMessage" @keyup.enter="sendMessage"
              placeholder="Ask any question related to the document" />
            <div class="checkmark">
              <svg class="cursor-pointer fill-current h-4 w-6" width="20" height="20" fill="none"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" @click="sendMessage">
                <path
                  d="M21.2428 12.4371C21.4016 12.3489 21.5 12.1816 21.5 12C21.5 11.8184 21.4016 11.6511 21.2428 11.5629L18.9605 10.295C14.464 7.79689 9.72391 5.76488 4.81421 4.2306L4.14914 4.02276C3.99732 3.97532 3.83198 4.00294 3.70383 4.09716C3.57568 4.19138 3.5 4.34094 3.5 4.5V10.25C3.5 10.5159 3.70816 10.7353 3.97372 10.7493L4.98336 10.8025C7.44497 10.932 9.89156 11.2659 12.2979 11.8006L12.5362 11.8536C12.5892 11.8654 12.6122 11.887 12.625 11.9042C12.6411 11.926 12.6536 11.9594 12.6536 12C12.6536 12.0406 12.6411 12.0741 12.625 12.0958C12.6122 12.113 12.5892 12.1347 12.5362 12.1464L12.2979 12.1994C9.89157 12.7341 7.44496 13.068 4.98334 13.1976L3.97372 13.2507C3.70816 13.2647 3.5 13.4841 3.5 13.75V19.5C3.5 19.6591 3.57568 19.8086 3.70383 19.9029C3.83198 19.9971 3.99732 20.0247 4.14914 19.9772L4.81422 19.7694C9.72391 18.2351 14.464 16.2031 18.9605 13.705L21.2428 12.4371Z"
                  fill="#8737E1" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChatbotView",
  components: {
  },
  data() {
    return {
      userMessage: "",
      messages: [],
      data: [],
      loading: false,
      isLoading: false,
      initialComponent: null,
      eventSource: null,
      showPopup: false,
      popupQuestion: "Are you sure you want to start a new chat?"
    };
  },
  beforeDestroy() {
    const apiUrl = `https://powerpulseapicombustdev.kearney.com/pdf-qa-close-session`;
    axios
      .post(apiUrl, { session_id: sessionStorage.getItem("session_id") })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error("POST error:", error);
      }).finally(() => {
        sessionStorage.clear();
      });

  },
  methods: {
    processQuery(question) {
      this.userMessage = question;
      this.sendMessage();
    },

    async sendMessage() {
      const userText = this.userMessage.trim();
      if (userText === "") return;

      this.messages.push({
        text: userText,
        isUser: true,
        timeStamp: new Date(),
      });
      this.scrollToBottom();
      this.userMessage = "";
      this.isLoading = true;

      const apiUrl = `https://powerpulseapicombustdev.kearney.com/pdf-qa-ask-question`;
      const formData = new FormData();
      formData.append('session_id', sessionStorage.getItem("session_id"));
      formData.append('questions', [userText]);
      axios
        .post(apiUrl, formData)
        .then((response) => {
          let responseText = "No response from the backend.";
          if (typeof response.data === "object") {
            responseText = JSON.stringify(response.data[0].answer);
          } else {
            responseText = response.data[0].answer;
          }
          const botResponse = {
            text: responseText,
            isUser: false,
            timeStamp: new Date(),
          };
          this.messages.push(botResponse);
          this.scrollToBottom();
        })
        .catch((error) => {
          console.error("POST error:", error);
        }).finally(() => {
          this.isLoading = false;
        });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.chatMessages.scrollTop =
          this.$refs.chatMessages.scrollHeight;
      });
    }
  },
};
</script>

<style scoped>
.chatbot-container {
  height: 85vh;
  width: 30vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.message {
  padding: 8px 20px;
  margin: 10px;
  word-wrap: break-word;
  width: fit-content;
  max-width: 80%;
  border-radius: 8px;
  display: block;
}

.chat-messages {
  overflow-y: scroll;
}

.table-container {
  overflow-x: auto;
}

.user-message {
  position: relative;
  text-align: right;
  background-color: #8737E1;
  color: #fff;
}

.loading-gif {
  height: 80px;
}

.k-br-cyan {
  border: 1px solid #55a8b5;
}

.chat-messages::-webkit-scrollbar {
  width: 4px;
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: rgb(123, 126, 129);
  border-radius: 10px;
}

.bot-message {
  position: relative;
  text-align: left;
  background-color: #f3f3f3;
  color: #000;
}

.user-input {
  margin-top: 10px;
  display: flex;
}

.input-container {
  display: flex;
  align-items: center; /* Vertically center the input and the checkmark */
}

.input-field {
  width: 100%;
  height: 2.5rem; /* Adjust height as needed */
  padding-right: 3rem; /* Space for the checkmark */
}

.checkmark {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%; /* Ensure it spans the height of the input */
  padding: 0 0.5rem; /* Adjust padding as needed */
}

input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

button {
  margin-left: 10px;
  padding: 5px 20px;
  background-color: rgb(50, 100, 165);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
