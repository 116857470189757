<template>
  <div class="p-8">
    <Legend :text="'Outputs'" :showRedLegend="false" />
    <div class="cards-list mt-5 overflow-y-auto" style="display: grid; grid-template-columns: repeat(4, 1fr);">
      <InfoCard
        v-for="(cardData, index) in flattenedCardRows"
        :key="index"
        :info="cardData"
        class="m-2"
      />
    </div>
  </div>
</template>
  
  <script>
  import InfoCard from '../../../InfoCard.vue';
  import Legend from '../Legend.vue'; // Make sure to update the path
  
  export default {
    name: 'Outputs.vue',
    components: {
      InfoCard,
      Legend
    },
    props: {
    result: Object
  },
    computed: {
      flattenedCardRows() {
      return this.result.outputs;
    },
    },
  };
  </script>
  
  <style scope>
  .cards-list {
    max-height: calc(100vh - 300px);
  }
  </style>
  