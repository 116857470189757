<template>
  <div class="flex items-center m-2">
      <div class="w-15 flex">
          <span class="font-bold w-32">Week Ahead:</span> <!-- Adjust width as needed -->
      </div>
      <div class="w-15 flex items-center space-x-2">
          <input type="radio" id="option2" value="2 weeks" v-model="selectedViewType">
          <label for="option2" class="ml-2">2 Weeks</label>
      </div>
      <div class="w-15 flex items-center pl-2 space-x-2">
          <input type="radio" id="option1" value="4 weeks" v-model="selectedViewType">
          <label for="option1" class="ml-2">4 Weeks</label>
      </div>
      <div class="w-15 flex items-center pl-2 space-x-2">
          <input type="radio" id="option3" value="6 weeks" v-model="selectedViewType">
          <label for="option3" class="ml-2">6 Weeks</label>
      </div>
      <div class="w-15 flex items-center pl-2 space-x-2">
          <input type="radio" id="option3" value="8 weeks" v-model="selectedViewType">
          <label for="option3" class="ml-2">8 Weeks</label>
      </div>
  </div>
  <div class="p-4">  
    <div class="mb-4">
      <label for="block-size" class="block text-sm font-medium text-gray-700 mb-2">Select buckets:</label>
        <div class="flex items-center">
            <select id="block-size" v-model="selectedBlockSize" @change="handleChange" class="block w-1/4 py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option value="2">2 Buckets</option>
                <option value="4">4 Buckets</option>
                <option value="6">6 Buckets</option>
                <option value="8">8 Buckets</option>
            </select>
            <div class="ml-auto">
                <span class="text-md font-bold text-gray-500">Accuracy {{ accuracy }}</span> <!-- Example accuracy label -->
            </div>
        </div>
    </div>
    <div ref="chart" class=""></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import heatmap from 'highcharts/modules/heatmap';
import { mapState, mapActions } from 'vuex';

// Initialize the heatmap module
heatmap(Highcharts);

export default {
  components: {
    HighchartsVue
  },
  data() {
    return {
      accuracy: '89.71%',
      selectedBlockSize: '2',
      selectedViewType: '2 weeks',
      chartOptions: {
        chart: {
          type: 'heatmap',
          marginTop: 40,
          marginBottom: 80,
          plotBorderWidth: 1
        },
        title: {
          text: 'Movement Comparison'
        },
        xAxis: {
          categories: ['<0', '>0'],
          title: {
            text: 'Actual Change Direction'
          }
        },
        yAxis: {
          categories: ['<0', '>0'],
          title: {
            text: 'Predicted Change Direction'
          }
        },
        colorAxis: {
          min: 0,
          minColor: '#FFFFFF',
          max: 400,
          maxColor: '#6b21a8'
        },
        plotOptions: {
          heatmap: {
            pointWidth: 30, // Set a fixed width for each cell
            pointHeight: 30, // Set a fixed height for each cell
            dataLabels: {
              enabled: true,
              color: '#000000',
              style: {
                fontSize: '14px', // Adjust the font size here
              },
            },
          },
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280
      },
        series: [{
          borderWidth: 1,
          data: [
            [0, 0, 32], // First row, first column
            [1, 0, 344],  // First row, second column
            [0, 1, 271],  // Second row, first column
            [1, 1, 37]  // Second row, second column
          ],
          dataLabels: {
            enabled: true,
            color: '#000000',
            style: {
              fontSize: '28px', // Adjust the font size here
            },
          }
        }]
      },
      bucketData: []
    };
  },
  mounted() {
    
    this.fetchAndStoreSheetData();
    this.createChart();
  },
  async created() {
    await this.fetchAndStoreSheetData();
  },
  watch: {
    selectedViewType: {
      handler: 'handleChange',
      immediate: true  // Call handleChange immediately on component mount
    },
    selectedCommodityTile: {
      handler: 'handleChange',
      immediate: true
    }
  },
  computed: {
    ...mapState('sheets', [
      'organizedBucketPredictions',
      'selectedCommodityTile'
    ]),
  },
  methods: {
    async fetchAndStoreSheetData() {
      this.bucketData = this.organizedBucketPredictions?.slice(1) || [];
      console.log(this.bucketData, 'bucketData90909900890900');
      this.handleChange();
    },
    createChart() {
      if (this.$refs.chart) {
        Highcharts.chart(this.$refs.chart, this.chartOptions);
      }
    },
    arrangeArray(buckets, data, week) {
      let arrangedArray = []
      if (data.length == 0) return [];
      console.log(buckets, data, week, this.selectedCommodityTile, 'buckets161')
      week = week.split(' ')[0]
      console.log(week, 'week42542 12313')
      const specificBucketData = data.filter((item, index) => {
        console.log(item[1], item[2], buckets, week, 'itssem');
        return item[1] == week && item[2] == buckets && item[68] === this.selectedCommodityTile;
      });

      console.log(specificBucketData, 'specificBucketData')

      if (specificBucketData.length == 0) return [];
      
      for (let i=0; i<buckets; i++) {
        for (let j=0; j<buckets; j++) {
          switch(buckets){      
            case '2':
              const slicedData_2 = specificBucketData[0].slice(3, 7); 
              console.log(slicedData_2, 'slicedData 168')

              console.log(specificBucketData[0], 'specificBucketData 172')
              const data1 = slicedData_2.slice(0, 2); // [1, 3]
              const data2 = slicedData_2.slice(2);    // [4, 5]

              if (i == 0) {
                data = data2
              } else {
                data = data1
              }
              break;
            case '4':
              const slicedData_4 = specificBucketData[0].slice(3, 19);

              console.log(slicedData_4, 'slicedData')
              const data_4_1 = slicedData_4.slice(0, 4);   // [1, 3, 4, 5]
              const data_4_2 = slicedData_4.slice(4, 8);   // [4, 5, 3, 1]
              const data_4_3 = slicedData_4.slice(8, 12);  // [1, 3, 4, 5]
              const data_4_4 = slicedData_4.slice(12);     // [4, 5, 3, 1]

              if (i == 0) {
                data = data_4_4
              } else if (i == 1) {
                data = data_4_3
              } else if (i == 2) {
                data = data_4_2   
              } else if (i == 3) {
                data = data_4_1
              }
              break;
            case '6':
              const slicedData_6 = specificBucketData[0].slice(3, 39); // Slices from index 3 to 39

              console.log(slicedData_6, 'slicedData')
              const data_6_1 = slicedData_6.slice(0, 6);    // Slice 0 to 3
              const data_6_2 = slicedData_6.slice(6, 12);    // Slice 4 to 7
              const data_6_3 = slicedData_6.slice(12, 18);   // Slice 8 to 11
              const data_6_4 = slicedData_6.slice(18, 24);  // Slice 12 to 15
              const data_6_5 = slicedData_6.slice(24, 30);  // Slice 16 to 19
              const data_6_6 = slicedData_6.slice(30);      // Slice 20 to 23

              if (i === 0) {
                data = data_6_6;
              } else if (i === 1) {
                data = data_6_5;
              } else if (i === 2) {
                data = data_6_4;
              } else if (i === 3) {
                data = data_6_3;
              } else if (i === 4) {
                data = data_6_2;
              } else if (i === 5) {
                data = data_6_1;
              }
              break;
            case '8':
              const slicedData_8 = specificBucketData[0].slice(3, 67); // Slices from index 3 to 67

              console.log(slicedData_8, 'slicedData')
              const data_8_1 = slicedData_8.slice(0, 8);    // Slice 0 to 7
              const data_8_2 = slicedData_8.slice(8, 16);    // Slice 8 to 15
              const data_8_3 = slicedData_8.slice(16, 24);   // Slice 16 to 23
              const data_8_4 = slicedData_8.slice(24, 32);  // Slice 24 to 31
              const data_8_5 = slicedData_8.slice(32, 40);  // Slice 32 to 39
              const data_8_6 = slicedData_8.slice(40, 48);  // Slice 40 to 47
              const data_8_7 = slicedData_8.slice(48, 56);  // Slice 48 to 55
              const data_8_8 = slicedData_8.slice(56);      // Slice 56 to 63

              // let data;

              if (i === 0) {
                data = data_8_8;
              } else if (i === 1) {
                data = data_8_7;
              } else if (i === 2) {
                data = data_8_6;
              } else if (i === 3) {
                data = data_8_5;
              } else if (i === 4) {
                data = data_8_4;
              } else if (i === 5) {
                data = data_8_3;
              } else if (i === 6) {
                data = data_8_2;
              } else if (i === 7) {
                data = data_8_1;
              }
              break;

          }
          console.log(data, 'data')
          arrangedArray.push([j, i, Number(data[j])])
        }
      }
      console.log(arrangedArray, 'arrangedArray')
      return {data: arrangedArray, accuracy: (specificBucketData[0][67]*100).toFixed(2) + '%'};
    },
    handleChange() {
      const arrangeArray = this.arrangeArray(this.selectedBlockSize, this.bucketData, this.selectedViewType);
      console.log(arrangeArray, 'arrangeArray')
      switch(this.selectedBlockSize) {
        case '2':
          this.chartOptions.xAxis.categories = ['<0', '>0'];
          this.chartOptions.yAxis.categories = ['>0', '<0'];
          this.chartOptions.colorAxis.max = 210;
          this.chartOptions.colorAxis.maxColor = '#6b21a8';

          console.log(this.selectedBlockSize, 'this.selectedBlockSize')
          console.log(this.bucketData, 'this.bucketData')
          
          this.chartOptions.series[0].data = arrangeArray.data;
          this.accuracy = arrangeArray.accuracy;
          break;
        case '4':
          this.chartOptions.xAxis.categories = ['(-inf, -5]', '[-5,0)', '[0,5)', '[5,inf)'];
          this.chartOptions.yAxis.categories = ['[5,inf)', '[0,5)', '[-5,0)', '(-inf, -5]'];
          this.chartOptions.colorAxis.max = 210;
          this.chartOptions.colorAxis.maxColor = '#6b21a8';

          this.chartOptions.series = [{
            borderWidth: 1,
            data: arrangeArray.data,
            dataLabels: {
              enabled: true,
              color: '#000000',
              style: {
                fontSize: '24px', // Adjust the font size here
              }
            }
          }];
          this.accuracy = arrangeArray.accuracy;
          break;
        case '6':
          this.chartOptions.xAxis.categories = ['(-inf, -5]', '[-5,-2.5)', '[-2.5,0)', '[0,2.5)', '[2.5,5)', '[5,inf)'];
          this.chartOptions.yAxis.categories = ['[5,inf)', '[2.5,5)', '[0,2.5)', '[-2.5,0)', '[-5,-2.5)', '(-inf, -5]'];
          this.chartOptions.colorAxis.max = 210;
          this.chartOptions.colorAxis.maxColor = '#6b21a8';
          
          this.chartOptions.series = [{
            borderWidth: 1,
            data: arrangeArray.data,
            dataLabels: {
              enabled: true,
              color: '#000000',
              style: {
                fontSize: '20px', // Adjust the font size here
              }
            }
          }];
          this.accuracy = arrangeArray.accuracy;
          break;
        case '8':
          this.chartOptions.xAxis.categories = ['[-inf, -5)', '[-5, -3)', '[-3, -1.5)', '[-1.5, 0)', '[0, 1.5)', '1.5, 3)','[3, 5)','[5, inf)'];
          this.chartOptions.yAxis.categories = ['[5, inf)', '[3, 5)', '[1.5, 3)', '[0, 1.5)', '[-1.5, 0)', '[-3, -1.5)', '[-5, -3)', '[-inf, -5)'];
          this.chartOptions.colorAxis.max = 210;
          this.chartOptions.colorAxis.maxColor = '#6b21a8';

          this.chartOptions.series = [{
            borderWidth: 1,
            data: arrangeArray.data,
            dataLabels: {
              enabled: true,
              color: '#000000',
              style: {
                fontSize: '16px', // Adjust the font size here
              }
            }
          }];
          this.accuracy = arrangeArray.accuracy;
          break;

          break;
      }
      this.createChart(); // Redraw the chart after updating options
    }
  }
};
</script>

<style scoped>
/* Optional: Add custom CSS styles for your component */
.highcharts-figure,
.highcharts-data-table table {
    min-width: 360px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-description {
    margin: 10px;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 3.8em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
</style>
