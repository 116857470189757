<template>
  <div class="mt-4">
    <h3 class="font-semibold text-[1rem]">
      Select Parameters
    </h3>
    <div class="flex items-center space-x-4 mb-4">
      <label v-for="(parameter, key) in dataConfigurableParameters" :key="index" class="flex items-center text-[0.825rem]">
        <input type="checkbox" v-model="selectedParameters" :value="key" @change="renderChart" class="mr-2 accent-blue-custom" />
        {{ parameter.name }}
      </label>
    </div>
    <div ref="tornadoChart" style="height: 400px; width: 100%;"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';

export default {
  name: 'TornadoChart',
  props: {
    tornadoData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedParameters: [], // Track selected parameters
      dataConfigurableParameters: {
        'blast_temp': {
          name: 'Blast Temperature',
          unit: '°C'
        },
        'oxygen_enriched_rate': {
          name: 'Oxygen Enriched Rate',
          unit: '°C'
        },
        'pci_rate': {
          name: 'PCI Rate',
          unit: '°C'
        },
        'steam_addition': {
          name: 'Steam Addition',
          unit: '°C'
        },
        'top_gas_pressure_average': {
          name: 'Top Gas Pressure Average',
          unit: 'Nm3/min'
        },

        'Pellet%': {
          name: 'Pellet (%)',
          unit: '%'
        },
        'Sinter%': {
          name: 'Sinter (%)',
          unit: '%'
        }
      },
    }
  },
  mounted() {
    console.log('tornadoData received:', this.tornadoData); // Check if data is received
    this.selectedParameters = Object.keys(this.dataConfigurableParameters); // Select all initially
    this.renderChart();
  },
  methods: {
    renderChart() {
      if (!this.tornadoData || !this.$refs.tornadoChart) return;

      const categories = this.selectedParameters;
      const positiveImpact = categories.map(key => this.tornadoData[key]?.[1]?.toFixed(1) || 0);
      const negativeImpact = categories.map(key => this.tornadoData[key]?.[0]?.toFixed(1) || 0);
      const chartCategories = categories.map(category => this.dataConfigurableParameters[category]?.name);

      Highcharts.chart(this.$refs.tornadoChart, {
        chart: {
          type: 'bar',
        },
        title: {
          text: 'Impact of Parameters on Coke Rate',
        },
        xAxis: {
          categories: chartCategories,
          title: { text: null },
        },
        yAxis: {
          title: { text: 'Impact' },
          min: -100,
          max: 100,
          tickInterval: 20,
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },
        series: [
          {
            name: 'Negative Impact',
            data: negativeImpact,
            color: '#C62828',
          },
          {
            name: 'Positive Impact',
            data: positiveImpact,
            color: '#2E7D32',
          },
        ],
      });
    }
  },
  watch: {
    tornadoData: {
      immediate: true,
      handler(newData) {
        if (newData) this.renderChart();
      },
    },
  },
};
</script>
