<template>
  <div>
    <WindTurbineAssetOverview />
  </div>
</template>

<script>
import WindTurbineAssetOverview from "@/components/PredictiveMaintenance/WindTurbine/PredictiveMaintenanceAssetOverview/index.vue";

export default {
  name: "WindTurbineAssetView",
  components: {
    WindTurbineAssetOverview
  }
};
</script>
