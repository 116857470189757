<template>
  <div>
    <div class="m-10 border bg-white text-center shadow-lg  max-h-screen  overflow-y-auto">
      <div class="w-full mr-15">
        <img :src="require('@/assets/img/PHT_Net.jpg')" alt="Full-width image" class="w-full h-60">
      </div>
      <p class="font-bold mt-4 text-2xl text-left mx-10 purple-text">Heat Exchanger Cleaning Scheduler</p>
      <p class="text-left mx-10 text-sm"><i>Optimized scheduler to detect heat exchanger fouling through AI models</i></p>
      <p class="text-left mx-10 mt-5 mb-5 font-bold">Exchanger Parameters</p>
      <div class="flex mx-10 justify-between text-sm text-left">
        <div class="bg-white rounded-md p-4 border-2 w-1/3 mr-2">
          <label for="fixedCost" class="block mb-2 font-bold">Fixed Cost</label>
          <input v-model="fixedCost" id="fixedCost" type="number" min="1" class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none" />
        </div>
        <div class="bg-white rounded-md p-4 border-2 w-1/3 mr-2">
          <label for="cleaningDuration" class="block mb-2 font-bold">Cleaning Duration (In Days)</label>

          <input v-model="cleaningDuration" id="cleaningDuration" type="number" min="1" step="1000"
            class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none " />
        </div>
        <div class="bg-white rounded-md p-4 border-2 w-1/3">
          <label for="fuelPrice" class="block mb-2 font-bold">Fuel Price</label>

          <input v-model="fuelPrice" id="fuelPrice" type="number" min="1" step="1000"
            class="bg-gray-100  w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none" />
        </div>
      </div>
      <button @click="sendFormValues"
        class="border font-semibold rounded font-sm kearney-purple-button shadow-lg px-20 mt-5 py-2 mb-20">
        Generate Schedule
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeatExchangeSchedulerInput",
  data() {
    return {
      fixedCost: "",
      cleaningDuration: "",
      fuelPrice: ""
    };
  },
  methods: {
    showOutputPage() {
      this.$emit('show-output');
    },

    sendFormValues() {
      if (this.fixedCost && this.cleaningDuration && this.fuelPrice) {
        const formValues = {
          fixedCost: this.fixedCost,
          cleaningDuration: this.cleaningDuration,
          fuelPrice: this.fuelPrice
        };
        this.$emit("heat-exchange-form-values-updated", formValues);
      }
      else {
        alert("Please input all the prices.");
      }

    },
  }
};
</script>
