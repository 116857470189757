<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <!-- Main Content Area -->
    <div class="flex-1 p-4 overflow-y-auto h-full">
      <!-- Display Loading or Data -->
      <div class="grid grid-cols-1" v-if="loading">Loading...</div>
      <div class="grid grid-rows-2" v-else>
        <!-- Pass networkOverviewData to NetworkOverview component -->
        <NetworkOverview :data="networkOverviewData" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import TreeMenu from '@/components/PredictiveMaintenance/WindTurbine/TreeMenu.vue';
import NetworkOverview from "@/components/PredictiveMaintenance/WindTurbine/PredictiveMaintenanceAssetOverview/NetworkOverview.vue";
import { mapState, mapActions } from 'vuex';

export default {
  name: "WindTurbineNetworkView",
  components: {
    TreeMenu,
    NetworkOverview
  },
  data() {
    return {
      networkOverviewData: {
        downTimeData: [],
        mapData: []
      },
      loading: true
    };
  },
  async mounted() {
    this.$emit('toggle-sideNav', { message: 'Component has been mounted!' });
    await this.fetchAndStorePredictiveMaintenanceData();
    await this.fetchNetworkOverviewData(); // Ensure this is called after data is fetched
  },
  computed: {
    ...mapState('sheets', ['predictiveMaintenanceData']),
  },
  methods: {
    ...mapActions('sheets', ['fetchAndStorePredictiveMaintenanceData']),
    async fetchNetworkOverviewData() {
      const PREDICTIVE_DOWNTIME_SHEET_NAME = "Wind Network Overview Data";
      const PREDICTIVE_ASSET_LOCATION_SHEET_NAME = "Network Gearbox Health Map";
      const PREDICTIVE_ASSET_RISK_SHEET_NAME = "Turbine network overview table";

      const downTimeData = _.reduce(
        _.map(_.slice(_.get(this.predictiveMaintenanceData, PREDICTIVE_DOWNTIME_SHEET_NAME), 1), v => [v[0], ...v.slice(5)]),
        (acc, v) => {
          acc.push({
            title: _.get(v, "[0]"),
            planned: _.toNumber(_.get(v, "[1]")),
            actual: _.toNumber(_.get(v, "[2]")),
            predictedNC: _.toNumber(_.get(v, "[3]")),
            predictedREC: _.toNumber(_.get(v, "[4]")),
          });
          return acc;
        },
        []
      );

      const locationData = _.reduce(
        _.slice(_.get(this.predictiveMaintenanceData, PREDICTIVE_ASSET_LOCATION_SHEET_NAME), 1),
        (acc, v) => {
          acc[v[0]] = {
            lat: _.toNumber(v[1]),
            lon: _.toNumber(v[2])
          };
          return acc;
        },
        {}
      );

      const riskData = _.reduce(
        _.slice(_.get(this.predictiveMaintenanceData, PREDICTIVE_ASSET_RISK_SHEET_NAME), 1),
        (acc, v) => {
          acc[v[0]] = _.assign({}, acc[v[0]], {
            mw: _.toNumber(v[1]),
            no_of_inverters: _.toNumber(v[2]),
            medium_term_risk: _.toNumber(v[3]),
            medium_term: v[4]
          });
          return acc;
        },
        locationData
      );

      const mapData = _.map(_.keys(riskData), v => {
        return {
          name: v,
          ...riskData[v]
        }
      });

      this.networkOverviewData = {
        downTimeData,
        mapData
      };
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #dbdbdb;
}
</style>
