<template>
  <div :class="`${isActive ? 'active-box': 'inactive-box'} p-4 normal-input-text`">
    <div :class="`section-number text-white p-1 ${isActive ? 'active-section-number': 'inactive-section-number'}`">
      1
    </div>
    <div class="section-header">Provide API Key</div>
    <div>Enter your OpenAI API access key to access the processing functions</div>
    <textarea v-model="apiAccessKey"
              :disabled="!isActive"
              class="input-text-area h-20"/>
    <div class="flex justify-end">
      <button
          :class="`input-button ${apiAccessKey.trim().length > 0 && isActive ? 'button-enabled' : 'button-disabled'}`"
          :disabled="!isActive"
          @click="handleProvidedApiKey">
        Next
      </button>
    </div>
    <div :class="`${isActive ? 'active-info-area': 'inactive-info-area'} mt-3 p-1`">
      <div class="font-bold">Steps to create OpenAI API access key:</div>
      <div>
        <div class="flex flex-row m-1">
          <div class="mr-1 ml-1">
            1.
          </div>
          <div>
            Visit <span class="linebreak-anywhere">[OpenAI Platform](https://platform.openai.com/docs/overview)</span>
            and <span class="font-bold">sign up</span>, providing the required information.
          </div>
        </div>

        <div class="flex flex-row m-1">
          <div class="mr-1 ml-1">
            2.
          </div>
          <div>
            <span class="font-bold">Verify</span> your email via the link sent to your inbox.
          </div>
        </div>

        <div class="flex flex-row m-1">
          <div class="mr-1 ml-1">
            3.
          </div>
          <div>
            <span class="font-bold">Log in</span>, navigate to the 'API Keys' page, and click <span class="font-bold">'Create new API key'</span>.
          </div>
        </div>

        <div class="flex flex-row m-1">
          <div class="mr-1 ml-1">
            4.
          </div>
          <div>
            Name your API key, set permissions, and click 'Create'.
          </div>
        </div>

        <div class="flex flex-row m-1">
          <div class="mr-1 ml-1">
            5.
          </div>
          <div>
            Store the API key securely, <span class="font-bold">set up billing</span> under 'Settings' > 'Billing', and
            monitor/manage usage.
          </div>
        </div>
      </div>
    </div>
    <div :class="`meta-info-area mt-3 p-1`">
      <div class="font-bold">For further queries please refer</div>
      <div class="brand-text underline">
        <ul class="list-group">
          <li class="ulliitem">
            <a :href="Sensa_2_tool_SOP"
               download="Sensa 2.0 tool SOP.docx">
              Sensa 2.0 tool SOP.docx
            </a>
          </li>
          <li class="ulliitem">
            <a :href="OpenAI_API_Key_Generation_SOP"
               download="OpenAI API Key Generation SOP.docx">
              OpenAI API Key Generation SOP.docx
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import OpenAI_API_Key_Generation_SOP from "./docs/OpenAI_API_Key_Generation_SOP.jpg";
import Sensa_2_tool_SOP from "./docs/Sensa_2_tool_SOP.jpg";

export default {
  name: "SensaAnalysisApiKeySection",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    handleNext: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      apiAccessKey: "",
      OpenAI_API_Key_Generation_SOP,
      Sensa_2_tool_SOP
    }
  },
  methods: {
    handleProvidedApiKey() {
      if (this.apiAccessKey.trim().length > 0) {
        this.handleNext(this.apiAccessKey);
      }
    }
  },
};
</script>

<style scoped>
.linebreak-anywhere {
  line-break: anywhere;
}
</style>
