<template>
    <div class="m-10 border bg-white text-center shadow-lg">
      <p class="font-bold my-4 text-xl">Soot Blowing Optimizer</p>
      <div class="flex mx-10 mt-10 justify-between text-sm text-left">
        <div class="bg-white rounded-md p-4 border-2 w-1/3 mr-2">
          <label for="waxy70n" class="block mb-2 font-semibold">Enter how many hours earlier stable load was running</label>
          <input
            id="waxy70n"
            type="text"
            class="bg-gray-100 w-full font-semibold border border-gray-300 rounded mt-2 px-2 py-1 focus:border-transparent focus:ring-0 text-amber-500"
          />
        </div>
        <div class="bg-white rounded-md p-4 border-2 w-1/3 mr-2">
          <label for="waxy150n" class="block mb-2 font-semibold"
            >Did furnace got pressureized in last 8 hours?</label
          >
          <input
            id="waxy150n"
            type="text"
            class="bg-gray-100 w-full font-semibold border border-gray-300 rounded mt-2 px-2 py-1 focus:border-transparent focus:ring-0 text-amber-500"
          />
        </div>
        <div class="bg-white rounded-md p-4 border-2 w-1/3">
          <label for="waxy500n" class="block mb-2 font-semibold"
            >Enter coal totalizer reading</label
          >
          <br>
          <input
            id="waxy500n"
            type="text"
            class="bg-gray-100 font-semibold w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:border-transparent focus:ring-0 text-amber-500"
          />
        </div>
      </div>
      <button
        class="border font-semibold rounded font-sm kearney-purple-button shadow-lg px-20 py-2 mt-20 mb-20"
      >
        Submit
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: "SootBlowingOptimizer",
  };
  </script>
  