<template>
    <div class="flex flex-col overflow-y-auto px-5 pb-3 pt-10 shadow">
        <Navbar />
        <div class="flex justify-between px-12">
            <div class="text-left">
                <p class="pt-5"><strong>Price Pilot</strong> - Price prediction</p>
            </div>
            <div class="text-right mt-3">
                <select v-model="selectedCommodity" @change="handleSelectChange" class="block appearance-none w-full bg-gray-200 border border-gray-400 text-gray-800 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    <option v-for="commodity in commodities" :key="commodity" :value="commodity">{{ commodity }}</option>
                </select>
            </div>
        </div>

        <div class="">
            <div class="mx-10 mb-10 mt-5">
                <div class="flex justify-between space-x-5">
                    <div class="w-full text-sm">
                        <div class="flex items-center price-strip rounded">
                            <div class="space-x-2 ml-2 font-bold my-2 text-left">
                                {{ this.selectedCommodity }}
                            </div>
                            <div class="space-x-2 ml-2 font-bold my-2 text-left">
                                {{ commodityClosingValue }}
                            </div>
                        </div>
                        <div class="flex items-center m-2">
                            <div class="w-15 flex">
                                <span class="font-bold w-32">Historical Period:</span> <!-- Adjust width as needed -->
                            </div>
                            <div class="w-30 flex items-center space-x-2">
                                <span>From:</span>
                                <Datepicker type="date" format="dd/MM/yyyy" v-model="startDate" class="border-none" />
                            </div>
                            <div class="w-30 flex items-center pl-2 space-x-2">
                                <span>To:</span>
                                <Datepicker type="date" format="dd/MM/yyyy" v-model="endDate" :datepickerType="'date'" />
                            </div>
                        </div>
                        
                        <div class="flex items-center m-2">
                            <div class="w-15 flex">
                                <span class="font-bold w-32">Chart Type:</span> <!-- Adjust width as needed -->
                            </div>
                            <div class="w-15 flex items-center space-x-2">
                                <input type="radio" id="option2" value="line" v-model="selectedChart">
                                <label for="option2" class="ml-2">Line</label>
                            </div>
                            <div class="w-15 flex items-center pl-2 space-x-2">
                                <input type="radio" id="option1" value="bucket" v-model="selectedChart">
                                <label for="option1" class="ml-2">Movement Comparison</label>
                            </div>
                        </div>
                        
                        
                        <div class="flex items-center m-2" v-if="selectedChart !== 'bucket'" :viewType="selectedViewType">
                            <div class="w-15 flex">
                                <span class="font-bold w-32">View Type:</span> <!-- Adjust width as needed -->
                            </div>
                            <div class="w-15 flex items-center space-x-2">
                                <input type="radio" id="option2" value="daily" v-model="selectedViewType">
                                <label for="option2" class="ml-2">Daily</label>
                            </div>
                            <div class="w-15 flex items-center pl-2 space-x-2">
                                <input type="radio" id="option1" value="weekly" v-model="selectedViewType">
                                <label for="option1" class="ml-2">Weekly</label>
                            </div>
                            <div class="w-15 flex items-center pl-2 space-x-2">
                                <input type="radio" id="option3" value="monthly" v-model="selectedViewType">
                                <label for="option3" class="ml-2">Monthly</label>
                            </div>
                        </div>
                        
                        <BucketChart :key="`bucket-${startDate}-${endDate}`" :selectedCommodity="this.selectedCommodity" v-if="selectedChart === 'bucket'" :viewType="selectedViewType" />
                        <ForecastChart :key="`line-${startDate}-${endDate}`" :currentData="chartData.currentData" :forecastData="chartData.forecastData" :viewType="selectedViewType" :lineCategory="chartData.lineCategory" v-if="selectedChart === 'line'" />
                    </div>
                </div>
            </div>

            <div class="m-10">
                <div class="grid grid-cols-3 gap-2 w-full max-w-4xl">
                    <div class="bg-sky-200 border border-gray-300 rounded p-2">
                        <div class="space-y-2">
                            <h4 class="text-md font-bold mb-4">Daily KPI </h4>
                            <div class="kpi-box flex justify-between">
                                <span class="italic text-left">% change</span>
                                <span class="italic text-right">{{ dailyKPIs?.percentChange }} %</span>
                            </div>
                            <div class="kpi-box flex justify-between">
                                <span class="italic text-left">Highest</span>
                                <span class="italic text-right">£{{ dailyKPIs?.highest }}/mt</span>
                            </div>
                            <div class="kpi-box flex justify-between">
                                <span class="italic text-left">Lowest</span>
                                <span class="italic text-right">£{{ dailyKPIs?.lowest }}/mt</span>
                            </div>
                        </div>
                    </div>
                    <div class="bg-sky-200 border border-gray-300 rounded p-2">
                        <div class="space-y-2">
                            <h2 class="text-lg font-bold mb-4">Weekly KPI</h2>
                            <div class="kpi-box flex justify-between">
                                <span class="italic text-left">% change</span>
                                <span class="italic text-right">{{ weeklyKPIs.percentChange }} %</span>
                            </div>
                            <div class="kpi-box flex justify-between">
                                <span class="italic text-left">Moving average</span>
                                <span class="italic text-right">£{{ weeklyKPIs.movingAverage }}/mt</span>
                            </div>
                            <div class="kpi-box flex justify-between">
                                <span class="italic text-left">Standard deviation</span>
                                <span class="italic text-right">£{{ weeklyKPIs.standardDeviation }}/mt</span>
                            </div>
                        </div>
                    </div>
                    <div class="bg-sky-200 border border-gray-300 rounded p-2">
                        <div class="space-y-2">
                            <h2 class="text-lg font-bold mb-4">Monthly KPI</h2>
                            <div class="kpi-box flex justify-between">
                                <span class="italic text-left">% change</span>
                                <span class="italic text-right">{{ monthlyKPIs.percentChange }} %</span>
                            </div>
                            <div class="kpi-box flex justify-between">
                                <span class="italic text-left">Moving average</span>
                                <span class="italic text-right">£{{ monthlyKPIs.movingAverage }}/mt</span>
                            </div>
                            <div class="kpi-box flex justify-between">
                                <span class="italic text-left">Standard deviation</span>
                                <span class="italic text-right">£{{ monthlyKPIs.standardDeviation }}/mt</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-10">
                <div class="flex items-center m-2">
                    <div class="w-15 flex">
                        <span class="font-bold w-32">News Date Range:</span> <!-- Adjust width as needed -->
                    </div>
                    <div class="w-30 flex items-center space-x-2">
                        <span>From:</span>
                        <Datepicker type="date" format="dd/MM/yyyy" v-model="startDateNews" class="border-none" />
                    </div>
                    <div class="w-30 flex items-center pl-2 space-x-2">
                        <span>To:</span>
                        <Datepicker type="date" format="dd/MM/yyyy" v-model="endDateNews" :datepickerType="'date'" />
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-0 w-full max-w-4xl">
                    <div 
                        class="p-4 flex items-center justify-between" 
                        v-for="(box, index) in paginatedData" 
                        :key="index" 
                        :class="{'bg-white left-container': index % 2 === 0, 'bg-gray-200 right-container': index % 2 !== 0}"
                    >   
                        <div class="left-side text-justify mr-4">
                            <!-- Daily News Summary Heading -->
                            <p v-if="index === 0" class="title-text pb-4">Daily News Summary</p>
                            
                            <!-- Weekly News Summary Heading -->
                            <p v-else-if="index === 1" class="title-text pb-4">Weekly News Summary</p>
                            <p>
                                <span class="s-and-p"><b>{{ box.date }} &nbsp;-&nbsp;</b> S&P Platts</span>
                            </p>
                            <p class="normal-text description-para" v-if="showFullText[index]">{{ box.content }}</p>
                            <p class="normal-text" v-else>{{ truncateString(box.content, 200) }}</p>
                            <button class="font-bold view-btn" @click="toggleText(index)">{{ showFullText[index] ? 'View Less' : 'View More' }}</button>
                        </div>
                        <div class="right-side flex flex-col items-center justify-center">
                            <p class="bold price-moment justify-center">Price Moment</p>
                            <img v-if="box.priceMoment < 0" :src="icons['deltaDown']" alt="trend icon" class="icon">
                            <img v-if="box.priceMoment >= 0" :src="icons['deltaUp']" alt="trend icon" class="icon">
                        </div>
                    </div>
                </div>

                <!-- Pagination Controls -->
                <div class="pagination-controls mt-4 mb-4 pb-12">
                    <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
                    <span>Page {{ currentPage }} of {{ totalPages }}</span>
                    <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import BucketChart from '@/components/BucketChart.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Navbar from '@/components/Navbar.vue';
import ForecastChart from '@/components/ForecastChart.vue';
import { mapState, mapActions } from 'vuex';
import { reactive } from 'vue';

export default {
    name: "AnalyticsView",
    components: {
        BucketChart,
        Datepicker,
        Navbar,
        ForecastChart
    },
    setup() {
        const showFullText = reactive({});
        const toggleText = (index) => {
            showFullText[index] = !showFullText[index];
        };

        return {
            showFullText,
            toggleText,
        };
    },
    data() {
        return {
            selectedCommodity: 'Steel', // Initial placeholder text
            commodities: ['Steel', 'Brent', 'Naphtha', 'Coking Coal'],
            selectedChart: 'line',
            selectedViewType: 'daily',
            icons: {
                deltaUp: require('@/assets/img/delta-up.svg'),
                deltaDown: require('@/assets/img/delta-down.svg'),
            },
            startDate: null,
            endDate: null,
            startDateNews: null,
            endDateNews: null,
            series: [{
                data: []
            }],
            articleData: [], // Initialize as empty array
            currentPage: 1,
            pageSize: 10
        };
    },
    beforeRouteUpdate() {
        this.$store.dispatch('sheets/fetchAndStorePriceProjectionsData');
    },
    mounted() {
        this.fetchAndStoreDailySummaryAndSentimentOutputData();
        this.fetchAndStoreWeeklySummaryAndSentimentOutputData();
        this.fetchAndStorePriceProjectionsData();
    },
    computed: {
        ...mapState('sheets', [
            'selectedCommodityTile',
            'dailySummaryAndSentiment',
            'weeklySummaryAndSentiment',
            'priceProjections',
        ]),
        paginatedData() {
            console.log(this.formattedNewsSummary, 'this.formattedNewsSummary')
            console.log(this.dailySummaryAndSentiment, 'this.dailySummaryAndSentiment1111')
            console.log(this.weeklySummaryAndSentiment, 'this.weeklySummaryAndSentiment1111')
            console.log(this.priceProjections, 'this.priceProjections1111')
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.filteredNewsSummary.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredNewsSummary.length / this.pageSize);
        },
        filteredNewsSummary() {
            const startDate = new Date(this.startDateNews);
            const endDate = new Date(this.endDateNews);
            return this.formattedNewsSummary.filter(entry => {
                const entryDate = new Date(entry.date);
                return entryDate >= startDate && entryDate <= endDate;
            });
        },
        formattedNewsSummary() {
            let dailySummary = this.dailySummaryAndSentiment || [];
            dailySummary = dailySummary.slice(1); // Remove the header row

            let dailyData = dailySummary.map(entry => ({ type: 'daily', data: entry }));
            console.log(dailyData, 'dailyData');

            dailyData = dailyData.filter(entry => {
                return entry['data'][7] === this.selectedCommodity
            });
            
            let weeklySummary = this.weeklySummaryAndSentiment || [];
            weeklySummary = weeklySummary.slice(1); // Remove the header row

            let weeklyData = weeklySummary.map(entry => ({ type: 'weekly', data: entry }));
            weeklyData = weeklyData.filter(entry =>  entry.data && entry.data[8] === this.selectedCommodity);

            console.log(weeklyData, 'weeklyData');
            
            // Interleave daily and weekly data
            const mergedData = [];
            const maxLength = Math.max(dailyData.length, weeklyData.length);
            for (let i = 0; i < maxLength; i++) {
                if (i < dailyData.length) mergedData.push(dailyData[i]);
                if (i < weeklyData.length) mergedData.push(weeklyData[i]);
            }
            
            console.log(mergedData, 'mergedData');
            
            // Prepare formatted summary for rendering
            const formattedSummary = [];
            
            mergedData.forEach((entry, index) => {
                formattedSummary.push({
                    type: entry.type,
                    date: entry.data[0],
                    source: 'S&P Platts',
                    content: entry.type === 'daily' ? entry.data[6] : entry.data[7],
                    priceMoment: entry.type === 'daily' ? entry.data[1] : entry.data[2]
                });
            });

            console.log(formattedSummary, 'formattedSummary');
            return formattedSummary;
        },
        filteredArticleData() {
            if (!this.articleData) {
                return [];
            }

            if (!this.startDateNews || !this.endDateNews) {
                return this.articleData;
            }

            const start = new Date(this.startDateNews);
            const end = new Date(this.endDateNews);

            return this.articleData.filter(article => {
                const articleDate = new Date(article[2]);
                return articleDate >= start && articleDate <= end;
            });
        },
        chartData() {
            let articleData = this.priceProjections || [];
            articleData = articleData.slice(1); // Remove the header row
            
            if (this.startDate && this.endDate) {
                const start = new Date(this.startDate);
                const end = new Date(this.endDate);

                articleData = articleData.filter(data => {
                    const date = new Date(data[0]);
                    return date >= start && date <= end && data[1] === this.selectedCommodity;
                });
            }
            const seriesData = [];
            const currentData = [];
            const forecastData = [];
            const lineCategory = [];

            for (let index = 0; index < articleData.length; index++) {
                seriesData.push({
                    x: articleData[index][0],
                    y: [
                        articleData[index][2], // Forecasted Prices (Open)
                        articleData[index][8], // Day high (High)
                        articleData[index][9], // Day low (Low)
                        articleData[index][3], // Actual Prices (Close)
                    ],
                });

                currentData.push(articleData[index][2]);
                forecastData.push(articleData[index][3]);
                lineCategory.push(articleData[index][0]);
            }

            console.log(seriesData, 'seriesData1414')
            
            return { 
                series: [{ data: seriesData }],
                                currentData, 
                forecastData, 
                lineCategory 
            };
        },
        dailyKPIs() {
            return this.calculateKPIs('daily');
        },
        weeklyKPIs() {
            return this.calculateKPIs('weekly');
        },
        monthlyKPIs() {
            return this.calculateKPIs('monthly');
        },
        commodityClosingValue() {
            let dailySummary = this.dailySummaryAndSentiment || [];
            dailySummary = dailySummary.slice(1); // Remove the header row

            dailySummary = dailySummary.filter(entry => entry[7] === this.selectedCommodity);
            
            if (dailySummary.length === 0) {
                return 0; // Return default value or handle empty case
            }

            let unit = '';
            let currency = '';

            if (this.selectedCommodity == 'Steel') {
                unit = 'mt'
                currency = '£'
            } else if (this.selectedCommodity == 'Brent') {
                unit = 'bbl'
                currency = '$'
            } else if (this.selectedCommodity == 'Coking Coal') {
                unit = 'mt'
                currency = '$'
            } else {
                unit = 'bbl'
                currency = '$'
            }
            
            const sortedData = dailySummary.sort((a, b) => new Date(b[0]) - new Date(a[0]));
            return currency+parseFloat(sortedData[0][3]).toFixed(2)+'/'+unit;
        }
    },
    methods: {
        handleSelectChange(event) {
            // Update selectedCommodity with the value from the event
            this.selectedCommodity = event.target.value;
            console.log('Selected commodity:', this.selectedCommodity);
            this.$store.dispatch('sheets/setSelectedCommodityTile', this.selectedCommodity);
            // You can emit an event or perform other actions based on the selected commodity
        },
        ...mapActions('sheets', ['fetchAndStoreDailySummaryAndSentimentOutputData', 'fetchAndStoreWeeklySummaryAndSentimentOutputData', 'fetchAndStorePriceProjectionsData', 'setSelectedCommodityTile']),
        async fetchAndStoreSheetData() {
            await this.$store.dispatch('sheets/fetchAndStorePriceProjectionsData');
            this.articleData = this.priceProjections?.slice(1) || [];
            console.log(this.articleData, 'this.articleData')
        },
        updateFilteredData() {
            this.articleData = this.filteredArticleData; // Ensure reactivity
        },
        calculateKPIs(interval) {
            console.log(interval, 'interval')
            // Ensure sheets and Price Projection data are available
            if (!this.priceProjections) {
                return null; // or handle the case where data is not available
            }
            console.log(this.selectedCommodity, 'this.priceProjections')
            let prices = this.priceProjections.filter(entry => new Date(entry[0]) <= new Date(this.endDate) && entry[11] === this.selectedCommodity);
            console.log(prices, 'prices prices pricesprices')

            let pricesBefore = prices.sort((a, b) => new Date(b[0]) - new Date(a[0]));
            console.log(pricesBefore, 'pricesBeforee')

            prices = prices.map(entry => entry[2]);
            prices = prices.slice(1); // Remove the header row

            console.log(prices, 'prices')
            switch (interval) {
                case 'daily':
                    if (!pricesBefore.length) {
                        return { percentChange: 0, highest: 0, lowest: 0 };
                    }
                    const highest = pricesBefore[0][8]
                    const lowest = pricesBefore[0][9]
                    const percentChange = (((highest - lowest) / lowest) * 100).toFixed(2);

                    return { percentChange, highest, lowest };
                case 'weekly':
                    if (!pricesBefore.length) {
                        return { percentChange: 0, movingAverage: 0, standardDeviation: 0 };
                    }
                    const percentChangeWeekly = this.calculatePercentChangeWeekly(prices).toFixed(2);
                    const movingAverageWeekly = this.calculateMovingAverage(prices, 7).toFixed(2);
                    const standardDeviationWeekly = this.calculateStandardDeviation(prices, 7).toFixed(2);
                    return { percentChange: percentChangeWeekly, movingAverage: movingAverageWeekly, standardDeviation: standardDeviationWeekly };
                case 'monthly':
                    if (!pricesBefore.length) {
                        return { percentChange: 0, movingAverage: 0, standardDeviation: 0 };
                    }
                    const percentChangeMonthly = this.calculatePercentChangeMonthly(prices).toFixed(2);
                    const movingAverageMonthly = this.calculateMovingAverage(prices, 30).toFixed(2); // Assuming 30 days in a month
                    const standardDeviationMonthly = this.calculateStandardDeviation(prices, 30).toFixed(2); // Assuming 30 days in a month
                    return { percentChange: percentChangeMonthly, movingAverage: movingAverageMonthly, standardDeviation: standardDeviationMonthly };
                default:
                    return null;
            }
        },
        calculatePercentChange(prices) {
            const mostRecentPrice = prices[prices.length - 1];
            const previousPrice = prices[prices.length - 2];
            return ((mostRecentPrice - previousPrice) / previousPrice) * 100;
        },
        calculatePercentChangeWeekly(prices) {
            const mostRecentPrice = prices[prices.length - 1];
            const oneWeekAgoPrice = prices[prices.length - 8]; // Assuming 7 days in a week
            return ((mostRecentPrice - oneWeekAgoPrice) / oneWeekAgoPrice) * 100;
        },
        calculatePercentChangeMonthly(prices) {
            const mostRecentPrice = prices[prices.length - 1];
            const oneMonthAgoPrice = prices[prices.length - 31]; // Assuming 30 days in a month
            return ((mostRecentPrice - oneMonthAgoPrice) / oneMonthAgoPrice) * 100;
        },
        calculateMovingAverage(prices, windowSize) {
            const validPrices = prices.slice(-windowSize).filter(price => !isNaN(price));
            const validCount = validPrices.length;
            if (validCount === 0) return 0; // Return 0 if there are no valid prices
            const sum = validPrices.reduce((acc, price) => acc + parseFloat(price), 0);
            return sum / validCount;
        },
        calculateStandardDeviation(prices, windowSize) {
            const mean = this.calculateMovingAverage(prices, windowSize);
            const variance = prices
                .slice(-windowSize)
                .reduce((acc, price) => acc + Math.pow(price - mean, 2), 0) / windowSize;
            return Math.sqrt(variance);
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        truncateString(str, maxLength = 200) {
            if (str.length <= maxLength) {
                return str;
            } else {
                return str.substring(0, maxLength) + '...';
            }
        },
        async getLatestDate(data) {
            const dates = await data.map(row => new Date(row[0]));
        
            if (dates.length === 0 || isNaN(dates[0].getTime())) {
                console.error('No valid dates found');
                return null;
            }
        
            const latestDate = new Date(Math.max.apply(null, dates));
            return latestDate;
        },
        subtractMonths(date, months) {
            const d = new Date(date);
            d.setMonth(d.getMonth() - months);
            return d;
        },
        formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
    },
    watch: {
        startDate: function() {
            this.updateFilteredData();
        },
        endDate: function() {
            this.updateFilteredData();
        },
        selectedCommodity: async function() {
            let priceProjection = this.priceProjections || [];
            priceProjection = priceProjection.slice(1);
            console.log(priceProjection, 'thispriceProjection priceProjection priceProjection')

            priceProjection = await priceProjection.filter(data => data[1] === this.selectedCommodity)
            console.log(priceProjection, 'priceProjection12345')

            priceProjection = await priceProjection.sort((a, b) => {
                return new Date(b[0]) - new Date(a[0])
            })
            
            console.log(priceProjection, 'priceProjection12345')
            
            const latestDate = await this.getLatestDate(priceProjection);

            console.log(latestDate, 'latestDate')

            if (latestDate) {
                this.endDate = latestDate;
                this.startDate = this.subtractMonths(latestDate, 6);
                console.log(this.formatDate(this.endDate), 'formatted date');
            } else {
                console.error('Failed to get the latest date');
            }
        }
    },
    created() {
        setTimeout(async() => {
            await this.fetchAndStoreSheetData();
            await this.$store.dispatch('sheets/fetchAndStoreOrganizedBucketPredictionsData');
            await this.$store.dispatch('sheets/fetchAndStorePriceProjectionsData');
            console.log(this.organizedBucketPredictions, 'sheets112312');
            
            let priceProjection = this.priceProjections || [];
            priceProjection = priceProjection.slice(1);

            priceProjection = priceProjection.filter(data => data[1] === 'Steel')

            priceProjection = priceProjection.sort((a, b) => {
                return new Date(b[0]) - new Date(a[0])
            })
            
            console.log(priceProjection, 'priceProjection123')
            
            const latestDate = await this.getLatestDate(priceProjection);
            
            let dailyNews = this.dailySummaryAndSentiment || [];
            dailyNews = dailyNews.slice(1);
            
            const latestDateNews = await this.getLatestDate(dailyNews);
            
            if (latestDateNews) {
                this.startDateNews = this.subtractMonths(latestDateNews, 6);
                this.endDateNews = latestDateNews;
            }
            
            if (latestDate) {
                this.endDate = latestDate;
                this.startDate = this.subtractMonths(latestDate, 6);
                console.log(this.formatDate(this.endDate), 'formatted date');
            } else {
                console.error('Failed to get the latest date');
            }
            this.selectedCommodity = this.selectedCommodityTile || 'Steel';
        }, 2000); // 2000 milliseconds = 2 seconds
    }
    
};
</script>

<style scoped>
.custom-nav-button {
    background-color: #F6F6F6;
    color: #000000;
    font-size: 18px;
}
.custom-nav-button:active {
    background-color: #F37022;
    color: #FFFFFF;
    font-size: 18px;
}
#chart {
    max-width: 600px;
    margin: auto;
}
.price-strip {
    background-color: #FFDECA;
    font-size: 24px;
}
.dp__input {
    border: none !important;
}

.title-text {
    color: #F37022;
    font-size: 1rem;
    font-weight: bold;
}
.right-side {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.s-and-p {
    color: gray;
    font-size: 0.6rem;
    margin: 0px;
}

.icon {
    width: 24px;
    height: 24px;
    margin: 0.5rem 0;
    align-items: center;
}

.normal-text {
    font-size: 0.75em;
}
.price-moment {
    font-size: 0.75em;
    color: #F37022;
    font-weight: bold;
}
.right-container {
  background-color: #dbdbdb;
}
.left-container {
  background-color: #f3f3f3;
}
.bg-sky-200 {
    background-color: #F1F8FF;
}
.kpi-box  {
    font-size: 0.875em;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.pagination-controls button {
  padding: 2px 10px;
  background-color: #F37022;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.pagination-controls button:disabled {
  background-color: #FFDBC6;
  cursor: not-allowed;
}

.view-btn {
    font-size: 12px;
}
.description-para{
  max-height: 130px;
  overflow-y: scroll;
  padding-right: 1rem;
}
</style>
