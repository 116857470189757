<template>
  <div class="w-full mb-4">
    <ProcessParameterSimulator />
  </div>
</template>

<script>
import ProcessParameterSimulator from "@/components/ProcessParameterOptimizer/simulator.vue";

export default {
  name: "ProcessParameterSimulatorView",
  components: {
    ProcessParameterSimulator
  }
};
</script>
