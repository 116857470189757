<template>
  <div class="text-left text-xs">
    <table class="w-full table-auto">
      <thead class="bg-gray-100">
        <tr class="purple-text">
          <th class="px-5 py-3">Parameter</th>
          <th class="px-5 py-3 text-center">Present Value</th>
          <th class="px-5 py-3">Optimal Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, key) in result.generalParameters" :key="key">
          <td class="border-b px-5 py-2">{{ key }}</td>
          <td class="border-b px-5 py-2 text-center">{{ formatNumber(value['Present Value']) }}</td>
          <td class="border-b px-5 py-2">{{ formatNumber(value['Optimal Value']) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
    };
  },
  props: {
    result: Object
  },
  methods: {
    formatNumber(numberStr) {
      if (isNaN(numberStr)) {
        return "-";
      }
      const number = parseFloat(numberStr);
      return isNaN(number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) ? '-':
       number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
  }
};
</script>

<style scoped></style>
  