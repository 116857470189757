<template>
  <div style="height: 100%; width: 100%" class="p-3">
    <div style="position: relative">
      <div ref="chart" class=""></div>
    </div>

  </div>
</template>

<script>
import _ from "lodash";
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';

export default {
  name: "BarChart",
  components: {
    HighchartsVue
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: false
    }
  },
  methods: {
    createChart() {
      const chartOptions = {
        chart: {
          type: 'column',
          backgroundColor: this.chartOptions?.backgroundColor,
          height: this.chartOptions?.height
        },
        title: {
          align: 'left',
          text: _.get(this.data, "title", ""),
          style: {
            color: '#7f2fdf', // Title color
            fontWeight: 700
          }
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'category',
          labels: {
            enabled: true,
            style: {
              color: 'black', // X-axis label color
              fontSize: 12
            }
          }
        },
        yAxis: {
          gridLineColor: 'transparent',
          labels: {
            enabled: false
          },
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              color: 'black' // Data label color
            },
            color: 'grey' // Bar color
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: ' +
            '<b>{point.y:.2f}</b> of total<br/>'
        },
        series: [
          {
            name: `series-${this?.data?.title}`,
            data: this?.data?.data,
          }
        ],
      };

      if (this.$refs.chart) {
        Highcharts.chart(this.$refs.chart, chartOptions);
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler(newData) {
        // Optionally add a delay or use debounce if needed
        this.createChart();
      }
    }
  },
  async created() {
    await new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 500)
    })
    this.createChart()
  }
}
</script>

<style scoped></style>