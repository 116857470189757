<template>
    <div class="w-64 bg-white text-black pl-1 pb-16 h-full overflow-y-auto sidebar">
      <ul class="space-y-2 mt-8">
        <li v-for="item in menuItems" :key="item.name" class="relative">
          <router-link
            :to="item.url"
            :class="['flex items-center mx-8 pl-4 text-center py-2 active-menu  rounded', { 'active-menu1': isActive(item) }]"
          >
            {{ item.name }}

          </router-link>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        menuItems: [
          {
            name: 'Machine Flow', url: '/machine-flow/landing',
            isOpen: false,
          },
        ]
      };
    },
    methods: {
      isActive(item) {
        const currentRoute = this.$route.path;
        return currentRoute.startsWith(item.url);
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add any additional styling if needed */
  .sidebar {
    height: 100%;
    background-color: #232B60;
    color: #fff;
  }
  .active-menu:hover {
    color: #232B60;
    background-color: #fff;
  }
  .active-menu1 {
    color: #232B60;
    background-color: #fff;
  }
  </style>
  