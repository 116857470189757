<template>
  <div>
    <div ref="spline3DChart" style="height: 600px; width: 800px;"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';

Highcharts3D(Highcharts);

export default {
  name: 'ReverseParabola3D',
  props: {
    chartData: {
      type: Object,
      required: true
    },
  },
  watch: {
    chartData: {
      handler(newData) {
        if (newData) {
          this.renderChart(newData); // Call renderChart with the new data
        }
      },
      immediate: true
    }
  },
  methods: {
    // This method will map your responseData to points for the chart
    // This method will map your responseData to points for the chart
    generateSpline3DPoints() {
      const { pdp_var_1, pdp_var_2, pdp_output } = this.chartData;
      const points = [];
      
      if (
        Array.isArray(pdp_var_1) &&
        Array.isArray(pdp_var_2) &&
        Array.isArray(pdp_output) &&
        pdp_var_1.length === pdp_var_2.length &&
        pdp_var_2.length === pdp_output.length &&
        pdp_var_1.length > 0 // Ensure data is not empty
      ) {
        // Map pdp_var_1 to X, pdp_var_2 to Y, and pdp_output to Z
        for (let i = 0; i < pdp_var_1.length; i++) {
          points.push([pdp_var_1[i], pdp_var_2[i], pdp_output[i]]);
        }

        console.log(points, 'points');
      } else {
        console.error('Response data arrays have mismatched lengths or are not arrays!');
      }

      return points;
    },
    renderChart(data) {
      this.$nextTick(() => {
        const { pdp_var_1, pdp_var_2, pdp_output } = data;

        // Make sure pdp_var_1, pdp_var_2, and pdp_output exist before rendering
        if (!pdp_var_1 || !pdp_var_2 || !pdp_output) {
          console.error('Response data is missing required properties.');
          return;
        }

        // Ensure that the chart container is available
        const chartContainer = this.$refs.spline3DChart;
        if (!chartContainer) {
          console.error('Chart container element not found!');
          return;
        }

        // Render the chart
        Highcharts.chart(chartContainer, {
          chart: {
            type: 'scatter3d',
            options3d: {
              enabled: true,
              alpha: 10,
              beta: 30,
              depth: 500,
              viewDistance: 25,
              frame: {
                bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
                back: { size: 1, color: 'rgba(0,0,0,0.04)' },
                side: { size: 1, color: 'rgba(0,0,0,0.06)' }
              }
            }
          },
          title: {
            text: 'Partial Dependence 3d Plot'
          },
          xAxis: {
            title: {
              text: 'X Axis (pdp_var_1)'
            },
            min: Math.min(...(pdp_var_1 || [])),
            max: Math.max(...(pdp_var_1 || []))
          },
          yAxis: {
            title: {
              text: 'Y Axis (pdp_var_2)'
            },
            min: Math.min(...(pdp_var_2 || [])),
            max: Math.max(...(pdp_var_2 || []))
          },
          zAxis: {
            title: {
              text: 'Z Axis (pdp_output)'
            },
            min: Math.min(...(pdp_output || [])),
            max: Math.max(...(pdp_output || []))
          },
          plotOptions: {
            scatter: {
              lineWidth: 2, // Connect points with lines to form a "spline"
              marker: {
                enabled: false
              }
            }
          },
          series: [
            {
              name: 'Partial depnendence 3D plot',
              data: this.generateSpline3DPoints(),
              color: 'blue'
            }
          ]
        });
      });
    }
  }
};
</script>

<style scoped>
/* Optional: Center chart container or adjust size */
#spline3DChart {
  margin: auto;
}
</style>
