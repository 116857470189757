<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 overflow-y-auto">
      <div class="grid grid-rows-[auto,1fr,1fr] p-4 h-screen overflow-y-auto">
        <div class="h-16 mb-4">
          <h2 class="font-bold purple-text text-lg mt-2">
            Asset {{ assetId }} Gearbox Health Map (A7)
          </h2>
          <!-- <span class="text-xs italic">
            Predict medium-term (1 – 3 weeks in advance) inverter health based on weather and equipment parameters​
          </span> -->
        </div>
        <HealthIndexLineChart class="mb-8" />
        
        <div class="shadow-lg mb-16">
          <div class="flex justify-between w-full p-3">
            <div class="brand-text">
              {{assetName}} Weather and Equipment Parameters
            </div>
            <div class="favorable-unfavorable-container">
              <div class="favorable-box">Favorable</div>
              <div class="unfavorable-box">Unfavorable</div>
            </div>
          </div>
          <div>
            <div class="p-3 grid grid-cols-6">
              <div class="aspect-box" v-for="aspect in inverterAspectData" :key="aspect.aspectName">
                <div class="aspect-name">{{ aspect.aspectName }}</div>
                <div :class="`${aspect.favorable ? 'favorable-aspect-value' : 'unfavorable-aspect-value'} aspect-value`">
                  {{ lodIsNaN(aspect.value) ? "NA" : `${aspect.value} ${aspect.unit}` }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="mt-4 mb-16 flex w-full">
          <div class="grid grid-cols-3 gap-2">
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-md font-semibold text-gray-800">Peak short-term risk score</h2>
                <h6 class="text-xs italic">(UOM - Score)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                80
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-md font-semibold text-gray-800">Current short-term risk score</h2>
                <h6 class="text-xs italic">(UOM - Score)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                60
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-md font-semibold text-gray-800">Preventive Maintenace compliance</h2>
                <h6 class="text-xs italic">(UOM - %)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                95%
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-md font-semibold text-gray-800">Cumulative MWh</h2>
                <h6 class="text-xs italic">(UOM - MWh)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                TBD
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-md font-semibold text-gray-800">Average temperature (last week)</h2>
                <h6 class="text-xs italic">(UOM - F)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                85
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-md font-semibold text-gray-800">IGBT Cycle Count (daily)</h2>
                <h6 class="text-xs italic">(UOM - Count)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                10
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-md font-semibold text-gray-800">Power Factor</h2>
                <h6 class="text-xs italic">(UOM - -)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                0.98
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-md font-semibold text-gray-800">Average Irradiance (last week)</h2>
                <h6 class="text-xs italic">(UOM - kWh/m<sup>2</sup>/day)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                9
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VueApexCharts from "vue3-apexcharts";
import HealthIndexLineChart from './HealthIndexLineChart.vue';
import TreeMenu from '../TreeMenu.vue';

function getInverterAspectsData(sheetData = []){
  const toReturn = [];
  const LABEL = "Short-term inverter health";
  const relevantRowLabelStartIndex = _.findIndex(sheetData, v =>{
    return v[0] === LABEL || v[1] === LABEL
  });

  if (relevantRowLabelStartIndex !== -1){
    const relevantRowStartIndex = relevantRowLabelStartIndex + 2;
    const lookUpIndex = _.indexOf(sheetData[relevantRowLabelStartIndex], LABEL);

    for (let i = relevantRowStartIndex;;i++){
      const relevantData = _.slice(sheetData[i], lookUpIndex, lookUpIndex + 4);
      if (_.isEmpty(relevantData)) break;
      const aspectName = _.get(relevantData, "[0]");
      let unit = _.get(relevantData, "[1]");
      let value = _.get(relevantData, "[2]");
      if (_.isEmpty(value) && !_.isNumber(value)){
        value = "N/A";
        unit = "";
      }
      const isFavourable = _.get(relevantData, "[3]");
      toReturn.push({
        aspectName,
        unit,
        value,
        favorable: isFavourable
      })
    }
  }

  return toReturn;
}
export default {
  name: "WindTurbineMediumTermRisk",
  components: {
    apexchart: VueApexCharts,
    HealthIndexLineChart,
    TreeMenu
  },
  data() {
    return {
      lodIsNaN: _.isNaN,
      healthIndexChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [1, 2, 3, 4]
        },
        title: {
          text: 'Total MW/Irradiance',
          align: 'left'
        },
        colors: ['#FF4560']
      },
      pastTemperatureChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [1, 2, 3, 4]
        },
        title: {
          text: 'Past Temperature (F)',
          align: 'left'
        },
        colors: ['#FF4560']
      },
      IGBTChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [1, 2, 3, 4]
        },
        title: {
          text: 'IGBT Thermal Cycle Count​',
          align: 'left'
        },
        colors: ['#FF4560']
      },
      PowerFactorChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [1, 2, 3, 4]
        },
        title: {
          text: 'Power Factor​',
          align: 'left'
        },
        colors: ['#FF4560']
      },
      chartSeries: [
        {
          name: 'Health Index',
          data: [30, 200, 250, 300]
        }
      ]
    };
  },
  computed: {
    ...mapState('sheets', [
      'predictiveMaintenanceData'
    ]),
    assetId() {
      return this.$route.params.asset;
    },
    inverterAspectData(){
      const RELEVANT_SHEET_NAME = "Gearbox Medium-term Health ";
      const sheetData = _.get(this.predictiveMaintenanceData, RELEVANT_SHEET_NAME);
      return getInverterAspectsData(sheetData)
    },
  },
  methods: {
    ...mapActions('sheets', ['fetchAndStorePredictiveMaintenanceData'])
  },
  mounted() {
    this.fetchAndStorePredictiveMaintenanceData();
  },
};
</script>
<style scoped>
.brand-text {
  color: #7f2fdf;
  font-weight: 700;
}

.container {
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
}

.favorable-unfavorable-container {
  display: flex;
  margin-right: 10px;
}

.favorable-box {
  background-color: #ccfecc;
  width: 90px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}
.unfavorable-box {
  background-color: #ffcccc;
  width: 90px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}

.aspect-box {
  min-width: 146px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.aspect-name {
  background-color: #af7deb;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-weight: bold;
  width: 100%;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}

.aspect-value {
  border: 1px solid #FFFFFF;
  width: 100%;
  height: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}

.favorable-aspect-value {
  background-color: #ccfecc;
}

.unfavorable-aspect-value {
  background-color: #ffcccc;
}
</style>

