<template>
  <div ref="chart1" class=""></div>
</template>

<script>
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';

const LINE_COLORS = ["#d2d2d2", "#d2d2d2", "#787878", "#e6d2fa", "#c8a5f0", "#af7deb"];

function getForecastZoneStart(seriesData) {
  const zoneStarts = _.filter(_.map(seriesData, v => v.zoneAt), _.isNumber);
  return _.min(zoneStarts) - 5 ?? 0;
}

function getMaxYValue(seriesData) {
  return _.max(_.map(seriesData, v => _.max(v.values)));
}

function getSeriesData(seriesData, times, zone) {
  let toReturn = [];
  _.forEach(seriesData, (data, i) => {
    const series = {
      data: [],
      name: data.name,
      color: LINE_COLORS[(_.size(LINE_COLORS) + i) % _.size(LINE_COLORS)]
    };
    const hasZone = _.isNumber(data.zoneAt);
    _.forEach(_.get(data, "values"), (v, i) => {
      const toPush = { y: v };
      if (hasZone && i >= data.zoneAt) {
        toPush.marker = { enabled: false };
      }
      series.data.push(toPush);
    });

    if (hasZone) {
      series.zoneAxis = 'x';
      series.zones = [{ value: zone }, { dashStyle: 'dash', color: data.zoneColor }];
    }

    toReturn.push(series);
  });
  return toReturn;
}

function getRangeSeries(val, items) {
  const series = {
    data: [],
    zoneAxis: 'x',
    dashStyle: 'dash',
    color: "gray",
    lineWidth: 1,
    showInLegend: false,
    name: "",
    enableMouseTracking: false
  };
  _.forEach(items, (v, i) => {
    const toPush = { y: val, marker: { enabled: false } };
    if (i === _.size(items) - 1) {
      toPush.dataLabels = {
        enabled: true,
        formatter: function () { return `<div>${this.y}</div>`; },
      };
      toPush.marker = { symbol: 'url(/predictive-maintenance/predictive_maintenance_left_arrow.svg)', width: 30, height: 30 };
    }
    series.data.push(toPush);
  });
  return series;
}

export default {
  name: "LineAndForecastChart",
  components: {
    HighchartsVue
  },
  data() {
    return {

      dataSets: [
        [
          {
            "name": "PEBB1 T1",
            "values": [95.82, 105.82, 120.82, 135.82],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB1 T2",
            "values": [113.82, 128.82, 148.82, 173.82],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T1",
            "values": [88.82, 96.82, 110.82, 130.82],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T2",
            "values": [101.82, 109.82, 123.82, 143.82],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T1",
            "values": [96.82, 104.82, 118.82, 138.82],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - Actual",
            "values": [103.82, 111.82, 125.82, 153.82],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - No Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 185.22, 209.20, 213.37, 214.98],
            "zoneAt": 8,
            "zoneColor": "red"
          },
          {
            "name": "PEBB3 T2 - Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 155.20, 157.30, 160.10,	161.20,	163.21],
            "zoneAt": 8,
            "zoneColor": "green"
          },
          {
            "name": "Lower Lim",
            "values": [
              80
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          },
          {
            "name": "Upper Lim",
            "values": [
              205
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          }
        ],
        [
          {
            "name": "PEBB1 T1",
            "values": [95.82, 105.82, 120.82, 135.82, 136.322534],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB1 T2",
            "values": [113.82, 128.82, 148.82, 173.82, 174.463134],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T1",
            "values": [88.82, 96.82, 110.82, 130.82, 131.304034],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T2",
            "values": [101.82, 109.82, 123.82, 143.82, 144.352134],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T1",
            "values": [96.82, 104.82, 118.82, 138.82, 139.333634],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - Actual",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - No Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.09, 210.18, 214.37, 215.99],
            "zoneAt": 8,
            "zoneColor": "red"
          },
          {
            "name": "PEBB3 T2 - Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 166.04, 168.99, 170.15, 172.27],
            "zoneAt": 8,
            "zoneColor": "green"
          },
          {
            "name": "Lower Lim",
            "values": [
              80
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          },
          {
            "name": "Upper Lim",
            "values": [
              205
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          }
        ],
        [
          {
            "name": "PEBB1 T1",
            "values": [95.82, 105.82, 120.82, 135.82, 136.322534, 136.9087209],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB1 T2",
            "values": [113.82, 128.82, 148.82, 173.82, 174.463134, 175.2133255],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T1",
            "values": [88.82, 96.82, 110.82, 130.82, 131.304034, 131.8686413],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T2",
            "values": [101.82, 109.82, 123.82, 143.82, 144.352134, 144.9728482],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T1",
            "values": [96.82, 104.82, 118.82, 138.82, 139.333634, 139.9327686],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - Actual",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.090534],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - No Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.090534, 211.65, 215.87, 217.50],
            "zoneAt": 8,
            "zoneColor": "red"
          },
          {
            "name": "PEBB3 T2 - Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.090534, 189.40, 190.70, 193.08],
            "zoneAt": 8,
            "zoneColor": "green"
          },
          {
            "name": "Lower Lim",
            "values": [
              80
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          },
          {
            "name": "Upper Lim",
            "values": [
              205
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          }
        ],
        [
          {
            "name": "PEBB1 T1",
            "values": [95.82, 105.82, 120.82, 135.82, 136.322534, 136.9087209, 137.5932645],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB1 T2",
            "values": [113.82, 128.82, 148.82, 173.82, 174.463134, 175.2133255, 176.0893921],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T1",
            "values": [88.82, 96.82, 110.82, 130.82, 131.304034, 131.8686413, 132.5279846],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T2",
            "values": [101.82, 109.82, 123.82, 143.82, 144.352134, 144.9728482, 145.6977124],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T1",
            "values": [96.82, 104.82, 118.82, 138.82, 139.333634, 139.9327686, 140.6324325],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - Actual",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.090534, 191.97],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - No Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.090534, 191.97, 216.95, 218.59],
            "zoneAt": 8,
            "zoneColor": "red"
          },
          {
            "name": "PEBB3 T2 - Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.090534, 191.97, 193.29, 195.70],
            "zoneAt": 8,
            "zoneColor": "green"
          },
          {
            "name": "Lower Lim",
            "values": [
              80
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          },
          {
            "name": "Upper Lim",
            "values": [
              205
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          }
        ],
        [
          {
            "name": "PEBB1 T1",
            "values": [95.82, 105.82, 120.82, 135.82, 136.322534, 136.9087209, 137.5932645, 138.1573969],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB1 T2",
            "values": [113.82, 128.82, 148.82, 173.82, 174.463134, 175.2133255, 176.0893921, 176.8113586],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T1",
            "values": [88.82, 96.82, 110.82, 130.82, 131.304034, 131.8686413, 132.5279846, 133.0713493],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T2",
            "values": [101.82, 109.82, 123.82, 143.82, 144.352134, 144.9728482, 145.6977124, 146.295073],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T1",
            "values": [96.82, 104.82, 118.82, 138.82, 139.333634, 139.9327686, 140.6324325, 141.2090254],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - Actual",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.090534, 191.97, 199.87, 204.85],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - No Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.090534, 191.97, 199.87, 219.49],
            "zoneAt": 8,
            "zoneColor": "red"
          },
          {
            "name": "PEBB3 T2 - Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.090534, 191.97, 199.87, 202.36],
            "zoneAt": 8,
            "zoneColor": "green"
          },
          {
            "name": "Lower Lim",
            "values": [
              80
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          },
          {
            "name": "Upper Lim",
            "values": [
              205
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          }
        ],
        [
          {
            "name": "PEBB1 T1",
            "values": [95.82, 105.82, 120.82, 135.82, 136.322534, 136.9087209, 137.5932645, 138.1573969, 138.6133163],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB1 T2",
            "values": [113.82, 128.82, 148.82, 173.82, 174.463134, 175.2133255, 176.0893921, 176.8113586, 177.3948361],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T1",
            "values": [88.82, 96.82, 110.82, 130.82, 131.304034, 131.8686413, 132.5279846, 133.0713493, 133.5104847],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB2 T2",
            "values": [101.82, 109.82, 123.82, 143.82, 144.352134, 144.9728482, 145.6977124, 146.295073, 146.7778468],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T1",
            "values": [96.82, 104.82, 118.82, 138.82, 139.333634, 139.9327686, 140.6324325, 141.2090254, 141.6750152],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - Actual",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.09, 191.97, 199.87, 204.85],
            "zoneAt": null,
            "zoneColor": null
          },
          {
            "name": "PEBB3 T2 - No Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.09, 191.97, 199.87, 204.85],
            "zoneAt": 8,
            "zoneColor": "red"
          },
          {
            "name": "PEBB3 T2 - Intervention",
            "values": [103.82, 111.82, 125.82, 153.82, 163.82, 186.09, 191.97, 199.87, 204.85],
            "zoneAt": 8,
            "zoneColor": "green"
          },
          {
            "name": "Lower Lim",
            "values": [
              80
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          },
          {
            "name": "Upper Lim",
            "values": [
              205
            ],
            "zoneAt": null,
            "zoneColor": null,
            "showInLegend": false
          }
        ]
      ],
      currentIndex: 0,
      chart: null,
      seriesData: []
    };
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      required: false
    }
  },
  methods: {
    createChart(chartData) {
      console.log(this.seriesData, 'this.seriesData')
      const times = _.get(chartData, "categories");
      const upperRangeVal = _.get(chartData, "rangeMax");
      const lowerRangeVal = _.get(chartData, "rangeMin");
      const forecastZoneStart = getForecastZoneStart(this.seriesData);
      const maxYVal = getMaxYValue(this.seriesData);
      console.log(maxYVal, 'maxYVal')

      let ranges = [];
      if (forecastZoneStart > 0) {
        ranges = [[forecastZoneStart, maxYVal], [_.size(times) - 1, maxYVal]];
      }

      console.log(ranges, 'ranges')

      const lineSeriesData = getSeriesData(this.seriesData, times, 3);
      console.log(lineSeriesData, 'ioioioioioio')
      // Modify colors for the specific series here
      _.forEach(lineSeriesData, (series) => {
        if (series.name === "PEBB3 T2 - No Intervention") {
          series.marker = {
            symbol: 'square', 
            fillColor: 'red'  // Set legend color to orange
          };
        }
        if (series.name === "PEBB3 T2 - Intervention") {
          series.marker = {
            symbol: 'square', 
            fillColor: 'green'  // Set legend color to teal
          };
        }
      });

      const uppRangeSeriesData = getRangeSeries(upperRangeVal, times); // Upper Lim series
      const lowerRangeSeriesData = getRangeSeries(lowerRangeVal, times); // Lower Lim series

      const chartOptions = {
        chart: { height: this.chartOptions?.height },
        title: {
          text: _.get(chartData, "chartTitle", ""),
          align: 'left',
          x: 0,
          verticalAlign: "top",
          style: { color: '#7f2fdf', fontWeight: 700 }
        },
        subtitle: {
          text: _.get(chartData, "chartSubtitle", ""),
          align: 'left',
          x: 0,
          verticalAlign: "top",
          style: { fontWeight: 700 }
        },
        credits: { enabled: false },
        legend: {
          enabled: true, // Legend enabled, but we will manually control what shows
          align: 'right',
          verticalAlign: 'top',
          y: -60,
          x: 70,
          width: 425,
          itemHiddenStyle: { color: '#CCC' }
        },
        plotOptions: { area: { fillColor: "rgba(228,228,228,255)" }, series: { pointPlacement: 'on' } },
        xAxis: { categories: times, labels: { enabled: true }, startOnTick: true },
        yAxis: {
          gridLineColor: 'transparent',
          max: maxYVal + 20,
          alignTicks: true,
          endOnTick: false,
          tickInterval: (Math.ceil((maxYVal / 12) / 20)) * 20, // Nearest multiple of 20
          lineWidth: 1,
          title: { text: "Temperature (°F)" }
        },
        series: [
          ...lineSeriesData,
          {
            ...uppRangeSeriesData,
            showInLegend: false // Ensure Upper Lim does not appear in the legend
          },
          {
            ...lowerRangeSeriesData,
            showInLegend: false // Ensure Lower Lim does not appear in the legend
          },
          {
            showInLegend: false, // Forecasted series removed from legend
            name: 'Forecasted',
            data: ranges,
            type: 'area',
            lineWidth: 0,
            zIndex: -1,
            marker: { enabled: false },
            dataLabels: {
              enabled: true,
              x: 100,
              y: maxYVal + 60,
              formatter: function () {
                if (this.x === times[_.size(times) - 1]) return null;
                return this.series.name;
              },
              backgroundColor: "rgba(228,228,228,255)",
              style: { fontSize: "16px", fontColor: "#4c4c4c" }
            },
            enableMouseTracking: false
          }
        ]
      };

      // Initialize or update the chart
      if (this.chart) {
        this.chart.update(chartOptions);
      } else {
        this.chart = Highcharts.chart(this.$refs.chart1, chartOptions);
      }
    }



    ,
    updateChartData() {
      if (!this.chart) {
        console.error('Chart not initialized');
        return;
      }

      let start, end, zone;

      switch (this.currentIndex) {
        case 0:
          end = 3;
          start = 8;
          zone = 3;
          break;
        case 1:
          end = 4;
          start = 8;
          zone = 4;
          break;
        case 2:
          end = 5;
          start = 8;
          zone = 5;
          break;
        case 3:
          end = 6;
          start = 8;
          zone = 6;
          break;
        case 4:
          end = 7;
          start = 8;
          zone = 7;
          break;
        case 5:
          end = 8;
          start = 8;
          zone = 8;
          break;
        default:
          end = 8;
          start = 8;
          break;
      }

      const chartData = this.data;
      const times = _.get(chartData, "categories");
      const maxYVal = getMaxYValue(this.seriesData);
      const forecastZoneStart = getForecastZoneStart(this.seriesData);

      const lineSeriesData = getSeriesData(this.seriesData, times, zone);
      // Modify colors for the specific series here
      _.forEach(lineSeriesData, (series) => {
        if (series.name === "PEBB3 T2 - No Intervention") {
          series.marker = {
            symbol: 'square', 
            fillColor: 'red'  // Set legend color to orange
          };
        }
        if (series.name === "PEBB3 T2 - Intervention") {
          series.marker = {
            symbol: 'square', 
            fillColor: 'green'  // Set legend color to teal
          };
        }
      });

      const uppRangeSeriesData = getRangeSeries(_.get(chartData, "rangeMax"), times); // Upper Lim
      const lowerRangeSeriesData = getRangeSeries(_.get(chartData, "rangeMin"), times); // Lower Lim

      const forecastedSeries = {
        showInLegend: false, // Forecasted removed from legend
        name: 'Forecasted',
        data: [
          [end, maxYVal],
          [start, maxYVal],
        ],
        type: 'area',
        lineWidth: 0,
        zIndex: -1,
        marker: { enabled: false },
        dataLabels: {
          enabled: true,
          x: 60,
          y: maxYVal + 60,
          formatter: function () {
            if (this.x === times[_.size(times) - 1]) return null;
            return this.series.name;
          },
          backgroundColor: "rgba(228,228,228,255)",
          style: {
            fontSize: "16px",
            fontColor: "#4c4c4c"
          }
        },
        enableMouseTracking: false
      };


      const yAxis = {
        gridLineColor: 'transparent',
        max: maxYVal + 20,
        alignTicks: true,
        endOnTick: false,
        tickInterval: (Math.ceil((maxYVal / 12) / 20)) * 20, // Nearest multiple of 20
        lineWidth: 1,
        title: { text: "Temperature (°F)" }
      }

      // Update the entire series array
      this.chart.update({
        series: [
          ...lineSeriesData,
          uppRangeSeriesData,
          lowerRangeSeriesData,
          forecastedSeries
        ],
        yAxis
      });
    }
    ,
    updateChart() {
      if (this.currentIndex === 5) {
        this.currentIndex = 0
      } else {
        this.currentIndex = this.currentIndex + 1
      }
      console.log(this.currentIndex, '####$')
      this.seriesData = this.dataSets[this.currentIndex];
      this.updateChartData();
    }
  },
  async mounted() {
    this.updateChart();
    this.createChart(this.data);
    setInterval(() => {
      this.updateChart();
    }, 2000); // Update chart every 10 seconds
    
  }
}
</script>
