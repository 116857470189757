<template>
  <div class="w-full mb-4">
    <SolarInverterShortTermRisk :asset-name="assetName"/>
  </div>
</template>

<script>
import SolarInverterShortTermRisk from "@/components/PredictiveMaintenance/SolarInverter/PredictiveMaintenanceShortTermRisk/index.vue";

export default {
  name: "SolarInverterSTRiskView",
  components: {
    SolarInverterShortTermRisk
  },
  data(){
    return {
      assetName: "C7" // todo
    }
  }
};
</script>
<style scoped>

</style>
