<template>
  <div class="border bg-white text-center shadow-lg pb-16 h-72">
    <div class="chart-container w-full">
      <apexchart ref="chart" type="line" :options="chartOptions" :series="chartSeries" width="100%" height="250" />
      <div class="custom-legend">
        <div class="legend-item">
          <div class="legend-color" style="background-color: #FF4560;"></div>
          <div class="legend-text">A7 Gearbox</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'LineChart',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      dataSets: [
        [41.39, 41.48, 41.59, 41.74, 41.92, 42.16, 42.45, 42.82, 43.29, 43.87, 44.59, 45.48, 46.56, 47.87, 49.44, 51.27, 53.39, 55.77, 58.41, 61.24, 64.19, 67.19, 70.15, 72.98, 75.61, 78.00, 80.12, 81.95, 83.51, 84.82, 85.91, 86.80, 87.52, 88.10, 88.56, 88.94, 89.23, 89.46, 89.65, 89.79, 89.91, 90.00],
        [41.39, 41.55, 41.66, 41.81, 41.99, 42.23, 42.52, 42.89, 43.36, 43.94, 44.67, 45.56, 46.64, 47.95, 49.52, 51.36, 53.48, 55.86, 58.51, 61.34, 64.30, 67.30, 70.27, 73.10, 75.74, 78.13, 80.26, 82.09, 83.65, 84.96, 86.06, 86.95, 87.67, 88.25, 88.71, 89.09, 89.38, 89.61, 89.80, 89.94, 90.06, 90.15],
        [41.39, 41.65, 41.76, 41.91, 42.09, 42.33, 42.62, 43.00, 43.47, 44.05, 44.77, 45.67, 46.75, 48.07, 49.64, 51.48, 53.61, 56.00, 58.65, 61.49, 64.45, 67.47, 70.44, 73.28, 75.92, 78.32, 80.45, 82.29, 83.85, 85.17, 86.26, 87.16, 87.88, 88.46, 88.92, 89.31, 89.60, 89.83, 90.02, 90.16, 90.28, 90.37], 
        [41.39, 41.81, 41.92, 42.07, 42.26, 42.50, 42.79, 43.16, 43.64, 44.22, 44.95, 45.84, 46.93, 48.25, 49.84, 51.68, 53.82, 56.22, 58.88, 61.73, 64.70, 67.73, 70.71, 73.57, 76.22, 78.63, 80.76, 82.61, 84.18, 85.50, 86.60, 87.50, 88.22, 88.81, 89.27, 89.65, 89.95, 90.18, 90.37, 90.51, 90.63, 90.72],
        [41.39, 42.06, 42.18, 42.33, 42.51, 42.75, 43.05, 43.42, 43.90, 44.49, 45.22, 46.12, 47.22, 48.54, 50.14, 51.99, 54.14, 56.55, 59.23, 62.10, 65.09, 68.14, 71.14, 74.01, 76.67, 79.10, 81.25, 83.10, 84.68, 86.01, 87.12, 88.02, 88.75, 89.34, 89.81, 90.19, 90.49, 90.72, 90.91, 91.05, 91.17, 91.27],
        [41.39, 42.36, 42.47, 42.63, 42.81, 43.06, 43.35, 43.73, 44.21, 44.80, 45.54, 46.45, 47.55, 48.89, 50.49, 52.36, 54.53, 56.96, 59.65, 62.54, 65.56, 68.62, 71.64, 74.53, 77.22, 79.66, 81.82, 83.69, 85.29, 86.62, 87.74, 88.65, 89.38, 89.97, 90.44, 90.83, 91.13, 91.36, 91.56, 91.70, 91.82, 91.91],
        [41.39, 42.73, 42.85, 43.00, 43.19, 43.44, 43.73, 44.12, 44.60, 45.20, 45.94, 46.86, 47.97, 49.32, 50.94, 52.82, 55.01, 57.46, 60.18, 63.09, 66.13, 69.22, 72.27, 75.19, 77.90, 80.36, 82.54, 84.43, 86.04, 87.39, 88.51, 89.43, 90.17, 90.77, 91.24, 91.63, 91.93, 92.17, 92.36, 92.51, 92.63, 92.72]
      ],
      currentIndex: 0,
      chartSeries: [
        {
          name: 'Risk Factor',
          data: []  // Initialize with empty data
        }
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: this.generateDates(21),
          tickAmount: 14,
          labels: {
            show: true,
            rotate: -45,
            style: {
              colors: ['#000'],
              fontSize: '12px'
            }
          }
        },
        grid: {
          borderColor: 'transparent', // Remove grid border
          column: {
            colors: ['#FFFFFF', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0'], // White for the first column, grey for others
            opacity: 1
          },
        },
        yaxis: {
          title: {
            text: 'Risk Factor'
          },
          tickAmount: 4,
          min: 0,
          max: 100,
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        annotations: {
          yaxis: [
            {
              y: 80,
              borderColor: '#000',
              borderWidth: 2,
              strokeDashArray: 5,
              label: {
                text: 'Dynamic health threshold',
                style: {
                  color: '#000',
                  background: '#FFFFFF'
                }
              }
            },
            {
              y: 10,
              borderColor: 'transparent', // Make border color transparent
              borderWidth: 0, // Set border width to 0
              strokeDashArray: 0, // No dashes
              position: 'left',  // Set text alignment to the left
              label: {
                text: 'Forecasted',
                offsetX: -700,  // Move the text to the left

                style: {
                  color: '#000',
                  background: 'rgba(255, 255, 255, 0)', // Transparent background
                  border: 'none', // Optional: No border
                  fontWeight: 600,
                  fontSize: "16px",
                  fontColor: "#4c4c4c",
                  display: 'flex',
                  justifyContent: 'left',  // Align text center horizontally
                  alignItems: 'left'  // Align text center vertically
                }
              }
            }
          ]
        },
        title: {
          text: 'Health Index',
          align: 'left'
        },
        colors: ['#FF4560']
      }
    };
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.updateChart();
        setInterval(this.updateChart, 2000); // Update chart every 2 seconds
      }, 1000); // Ensure chart has enough time to render
    });
  },
  methods: {
    generateDates(days) {
      const dates = [];
      const today = new Date();

      // Generate unique dates
      for (let i = 0; i < days; i++) {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + i);

        const month = ('0' + (nextDate.getMonth() + 1)).slice(-2);
        const day = ('0' + nextDate.getDate()).slice(-2);
        const year = nextDate.getFullYear();
        const formattedDate = `${month}/${day}/${year}`;

        // Push each date twice to get a total of 42 dates
        dates.push(formattedDate, formattedDate);
      }
      return dates
    },
    updateChart() {
      this.currentIndex = (this.currentIndex + 1) % this.dataSets.length;
      this.chartSeries[0].data = this.dataSets[this.currentIndex];
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions(this.chartOptions); // Trigger a re-render
      } else {
        console.error('Chart reference is not available');
      }
    }
  }
};
</script>

<style scoped>
.chart-container {
  padding: 20px;
  position: relative;
}

.custom-legend {
  position: absolute;
  top: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  background: #FFFFFF;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-color {
  width: 12px;
  height: 12px;
  /* border-radius: 50%; */
  margin-right: 5px;
}

.legend-text {
  color: #333;
}
.apexcharts-xaxis-labels > text:first-child {
  fill: black; /* Ensure the first x-axis label is readable */
}
.apexcharts-canvas {
  background-color: #D3D3D3; /* Background color for the chart area */
}
/* Style for the first column (x-axis label) */
.apexcharts-xaxis-labels > text:nth-child(1) {
  background-color: white; /* Set the first label background to white */
  fill: black; /* Set label text color */
  border-radius: 4px; /* Optional: rounded corners */
  padding: 2px; /* Optional: some padding for better appearance */
}
</style>
