export const yieldOptimizerMock = [
    {
        "response": {
            "constraints": [
                {
                    "label": "KV70",
                    "predicted": 3.0060204037101266
                },
                {
                    "label": "KV150",
                    "predicted": 4.279038972298005
                },
                {
                    "label": "KV500",
                    "predicted": 8.87216640589537
                }
            ],
            "controllable": [
                {
                    "current": 23.354750413161074,
                    "label": "HLA.105PIC1401.PV",
                    "max": 25.68,
                    "min": 21.04,
                    "predicted": 21.88761767921365
                },
                {
                    "current": 345.0735098031853,
                    "label": "HLA.105TIC1322.PV",
                    "max": 370.0735098031853,
                    "min": 320.0735098031853,
                    "predicted": 336.77898177292724
                },
                {
                    "current": 838.1769999186198,
                    "label": "HLA.105FIC1406.PV",
                    "max": 1018.8093820073319,
                    "min": 682.7004050399653,
                    "predicted": 778.2530533669494
                },
                {
                    "current": 59.98982226053873,
                    "label": "HLA.105FIC1501.PV",
                    "max": 72.91800389407396,
                    "min": 48.86208516759792,
                    "predicted": 69.29256398266017
                },
                {
                    "current": 38647.32760416667,
                    "label": "HLA.105FIC1402.PV",
                    "max": 46976.068248662115,
                    "min": 31478.489879391276,
                    "predicted": 43332.48011449121
                },
                {
                    "current": 16016.91294642857,
                    "label": "HLA.105FIC1404.PV",
                    "max": 19468.65779208984,
                    "min": 13045.875700571985,
                    "predicted": 15182.111645987297
                },
                {
                    "current": 42572.155552455355,
                    "label": "HLA.105FIC1405.PV",
                    "max": 51746.72114998169,
                    "min": 34675.28677344709,
                    "predicted": 35001.067702429624
                },
                {
                    "current": 75.4795150756836,
                    "label": "HLA.105TI1912.PV",
                    "max": 100.4795150756836,
                    "min": 50.479515075683594,
                    "predicted": 85.62393481202355
                },
                {
                    "current": 10960.644986979167,
                    "label": "HLA.105FIC1314.PV",
                    "max": 13322.732485704348,
                    "min": 8927.5138459257,
                    "predicted": 12506.235277770185
                },
                {
                    "current": 11143.734928385416,
                    "label": "HLA.105FIC1315.PV",
                    "max": 13545.279453795778,
                    "min": 9076.641747513224,
                    "predicted": 9852.236660599972
                },
                {
                    "current": 19792.493782552083,
                    "label": "HLA.105FI1401.PV",
                    "max": 24057.8998957782,
                    "min": 16121.109888974812,
                    "predicted": 22061.803088740486
                },
                {
                    "current": 22649.027083333334,
                    "label": "HLA.105FIC1403.PV",
                    "max": 27530.03397621094,
                    "min": 18447.774115794273,
                    "predicted": 24655.201300229237
                }
            ],
            "nonControllable": [
                {
                    "current": 47159.697005208334,
                    "label": "HLA.105FI1102.PV"
                },
                {
                    "current": 5.42,
                    "label": "FPU-UCO-KV100"
                },
                {
                    "current": 846.8,
                    "label": "FPU-UCO-Density"
                }
            ],
            "outputs": [
                {
                    "current": 4815.36396986939,
                    "label": "70",
                    "predicted": 4156.649236166267
                },
                {
                    "current": 34483.60812670914,
                    "label": "150",
                    "predicted": 34861.445369658344
                },
                {
                    "current": 12869.924522962643,
                    "label": "500",
                    "predicted": 13500.073189674342
                },
                {
                    "current": 21.857575833460942,
                    "label": "revenue",
                    "predicted": 23.487107033515205
                },
                {
                    "current": 627.6956765437895,
                    "label": "go",
                    "predicted": 607.2529022797547
                }
            ]
        }
    },
    {
        "response": {
            "constraints": [
                {
                    "label": "KV70",
                    "predicted": 3.08458291634808
                },
                {
                    "label": "KV150",
                    "predicted": 4.364129752681029
                },
                {
                    "label": "KV500",
                    "predicted": 8.88276182244184
                }
            ],
            "controllable": [
                {
                    "current": 23.354750413161074,
                    "label": "HLA.105PIC1401.PV",
                    "max": 25.68,
                    "min": 21.04,
                    "predicted": 24.453504709161496
                },
                {
                    "current": 345.0735098031853,
                    "label": "HLA.105TIC1322.PV",
                    "max": 370.0735098031853,
                    "min": 320.0735098031853,
                    "predicted": 324.0023212731043
                },
                {
                    "current": 838.1769999186198,
                    "label": "HLA.105FIC1406.PV",
                    "max": 1018.8093820073319,
                    "min": 682.7004050399653,
                    "predicted": 694.9874448503602
                },
                {
                    "current": 59.98982226053873,
                    "label": "HLA.105FIC1501.PV",
                    "max": 72.91800389407396,
                    "min": 48.86208516759792,
                    "predicted": 64.20956313234659
                },
                {
                    "current": 38647.32760416667,
                    "label": "HLA.105FIC1402.PV",
                    "max": 46976.068248662115,
                    "min": 31478.489879391276,
                    "predicted": 41845.185781897686
                },
                {
                    "current": 16016.91294642857,
                    "label": "HLA.105FIC1404.PV",
                    "max": 19468.65779208984,
                    "min": 13045.875700571985,
                    "predicted": 16645.994082069825
                },
                {
                    "current": 42572.155552455355,
                    "label": "HLA.105FIC1405.PV",
                    "max": 51746.72114998169,
                    "min": 34675.28677344709,
                    "predicted": 35276.80179548162
                },
                {
                    "current": 75.4795150756836,
                    "label": "HLA.105TI1912.PV",
                    "max": 100.4795150756836,
                    "min": 50.479515075683594,
                    "predicted": 92.92996061271067
                },
                {
                    "current": 10960.644986979167,
                    "label": "HLA.105FIC1314.PV",
                    "max": 13322.732485704348,
                    "min": 8927.5138459257,
                    "predicted": 9287.394601221618
                },
                {
                    "current": 11143.734928385416,
                    "label": "HLA.105FIC1315.PV",
                    "max": 13545.279453795778,
                    "min": 9076.641747513224,
                    "predicted": 13035.454387943633
                },
                {
                    "current": 19792.493782552083,
                    "label": "HLA.105FI1401.PV",
                    "max": 24057.8998957782,
                    "min": 16121.109888974812,
                    "predicted": 17853.39393414405
                },
                {
                    "current": 22649.027083333334,
                    "label": "HLA.105FIC1403.PV",
                    "max": 27530.03397621094,
                    "min": 18447.774115794273,
                    "predicted": 24967.394632434367
                }
            ],
            "nonControllable": [
                {
                    "current": 47159.697005208334,
                    "label": "HLA.105FI1102.PV"
                },
                {
                    "current": 5.42,
                    "label": "FPU-UCO-KV100"
                },
                {
                    "current": 846.8,
                    "label": "FPU-UCO-Density"
                }
            ],
            "outputs": [
                {
                    "current": 4815.36396986939,
                    "label": "70",
                    "predicted": 3982.0353966581383
                },
                {
                    "current": 34483.60812670914,
                    "label": "150",
                    "predicted": 34861.445369658344
                },
                {
                    "current": 12869.924522962643,
                    "label": "500",
                    "predicted": 13645.428212097453
                },
                {
                    "current": 21.807842368570004,
                    "label": "revenue",
                    "predicted": 23.368919705121893
                },
                {
                    "current": 627.6956765437895,
                    "label": "go",
                    "predicted": 615.8582790605964
                }
            ]
        }
    },
    {
        "response": {
            "constraints": [
                {
                    "label": "KV70",
                    "predicted": 3.011954974932916
                },
                {
                    "label": "KV150",
                    "predicted": 4.301565489207244
                },
                {
                    "label": "KV500",
                    "predicted": 8.859097827330551
                }
            ],
            "controllable": [
                {
                    "current": 23.354750413161074,
                    "label": "HLA.105PIC1401.PV",
                    "max": 25.68,
                    "min": 21.04,
                    "predicted": 24.02717530353776
                },
                {
                    "current": 345.0735098031853,
                    "label": "HLA.105TIC1322.PV",
                    "max": 370.0735098031853,
                    "min": 320.0735098031853,
                    "predicted": 352.6301436868618
                },
                {
                    "current": 838.1769999186198,
                    "label": "HLA.105FIC1406.PV",
                    "max": 1018.8093820073319,
                    "min": 682.7004050399653,
                    "predicted": 746.8951681984585
                },
                {
                    "current": 59.98982226053873,
                    "label": "HLA.105FIC1501.PV",
                    "max": 72.91800389407396,
                    "min": 48.86208516759792,
                    "predicted": 61.63325832986437
                },
                {
                    "current": 38647.32760416667,
                    "label": "HLA.105FIC1402.PV",
                    "max": 46976.068248662115,
                    "min": 31478.489879391276,
                    "predicted": 46165.724977645645
                },
                {
                    "current": 16016.91294642857,
                    "label": "HLA.105FIC1404.PV",
                    "max": 19468.65779208984,
                    "min": 13045.875700571985,
                    "predicted": 13072.721169280467
                },
                {
                    "current": 42572.155552455355,
                    "label": "HLA.105FIC1405.PV",
                    "max": 51746.72114998169,
                    "min": 34675.28677344709,
                    "predicted": 42234.06837375473
                },
                {
                    "current": 75.4795150756836,
                    "label": "HLA.105TI1912.PV",
                    "max": 100.4795150756836,
                    "min": 50.479515075683594,
                    "predicted": 68.5597170926124
                },
                {
                    "current": 10960.644986979167,
                    "label": "HLA.105FIC1314.PV",
                    "max": 13322.732485704348,
                    "min": 8927.5138459257,
                    "predicted": 11583.866555612263
                },
                {
                    "current": 11143.734928385416,
                    "label": "HLA.105FIC1315.PV",
                    "max": 13545.279453795778,
                    "min": 9076.641747513224,
                    "predicted": 11829.30522902071
                },
                {
                    "current": 19792.493782552083,
                    "label": "HLA.105FI1401.PV",
                    "max": 24057.8998957782,
                    "min": 16121.109888974812,
                    "predicted": 22690.147710245124
                },
                {
                    "current": 22649.027083333334,
                    "label": "HLA.105FIC1403.PV",
                    "max": 27530.03397621094,
                    "min": 18447.774115794273,
                    "predicted": 24335.811883313803
                }
            ],
            "nonControllable": [
                {
                    "current": 47159.697005208334,
                    "label": "HLA.105FI1102.PV"
                },
                {
                    "current": 5.42,
                    "label": "FPU-UCO-KV100"
                },
                {
                    "current": 846.8,
                    "label": "FPU-UCO-Density"
                }
            ],
            "outputs": [
                {
                    "current": 4815.36396986939,
                    "label": "70",
                    "predicted": 4349.766486966055
                },
                {
                    "current": 34483.60812670914,
                    "label": "150",
                    "predicted": 35346.4387591397
                },
                {
                    "current": 12869.924522962643,
                    "label": "500",
                    "predicted": 12935.838363014984
                },
                {
                    "current": 21.922147439792628,
                    "label": "revenue",
                    "predicted": 23.627386395677934
                },
                {
                    "current": 627.6956765437895,
                    "label": "go",
                    "predicted": 583.4246391626683
                }
            ]
        }
    },
    {
        "response": {
            "constraints": [
                {
                    "label": "KV70",
                    "predicted": 3.0845826040149134
                },
                {
                    "label": "KV150",
                    "predicted": 4.344183443964689
                },
                {
                    "label": "KV500",
                    "predicted": 8.882746247138172
                }
            ],
            "controllable": [
                {
                    "current": 23.354750413161074,
                    "label": "HLA.105PIC1401.PV",
                    "max": 25.68,
                    "min": 21.04,
                    "predicted": 22.87169472593516
                },
                {
                    "current": 345.0735098031853,
                    "label": "HLA.105TIC1322.PV",
                    "max": 370.0735098031853,
                    "min": 320.0735098031853,
                    "predicted": 369.19103445726876
                },
                {
                    "current": 838.1769999186198,
                    "label": "HLA.105FIC1406.PV",
                    "max": 1018.8093820073319,
                    "min": 682.7004050399653,
                    "predicted": 954.1168763184761
                },
                {
                    "current": 59.98982226053873,
                    "label": "HLA.105FIC1501.PV",
                    "max": 72.91800389407396,
                    "min": 48.86208516759792,
                    "predicted": 61.38632059998527
                },
                {
                    "current": 38647.32760416667,
                    "label": "HLA.105FIC1402.PV",
                    "max": 46976.068248662115,
                    "min": 31478.489879391276,
                    "predicted": 46955.22246314783
                },
                {
                    "current": 16016.91294642857,
                    "label": "HLA.105FIC1404.PV",
                    "max": 19468.65779208984,
                    "min": 13045.875700571985,
                    "predicted": 16090.884696892786
                },
                {
                    "current": 42572.155552455355,
                    "label": "HLA.105FIC1405.PV",
                    "max": 51746.72114998169,
                    "min": 34675.28677344709,
                    "predicted": 34789.66315066841
                },
                {
                    "current": 75.4795150756836,
                    "label": "HLA.105TI1912.PV",
                    "max": 100.4795150756836,
                    "min": 50.479515075683594,
                    "predicted": 90.87428614069215
                },
                {
                    "current": 10960.644986979167,
                    "label": "HLA.105FIC1314.PV",
                    "max": 13322.732485704348,
                    "min": 8927.5138459257,
                    "predicted": 10796.06065235678
                },
                {
                    "current": 11143.734928385416,
                    "label": "HLA.105FIC1315.PV",
                    "max": 13545.279453795778,
                    "min": 9076.641747513224,
                    "predicted": 12911.998677591037
                },
                {
                    "current": 19792.493782552083,
                    "label": "HLA.105FI1401.PV",
                    "max": 24057.8998957782,
                    "min": 16121.109888974812,
                    "predicted": 16723.348938664272
                },
                {
                    "current": 22649.027083333334,
                    "label": "HLA.105FIC1403.PV",
                    "max": 27530.03397621094,
                    "min": 18447.774115794273,
                    "predicted": 20903.41891565497
                }
            ],
            "nonControllable": [
                {
                    "current": 47159.697005208334,
                    "label": "HLA.105FI1102.PV"
                },
                {
                    "current": 5.42,
                    "label": "FPU-UCO-KV100"
                },
                {
                    "current": 846.8,
                    "label": "FPU-UCO-Density"
                }
            ],
            "outputs": [
                {
                    "current": 4815.36396986939,
                    "label": "70",
                    "predicted": 3982.0353966581383
                },
                {
                    "current": 34483.60812670914,
                    "label": "150",
                    "predicted": 34861.445369658344
                },
                {
                    "current": 12869.924522962643,
                    "label": "500",
                    "predicted": 13687.992633804228
                },
                {
                    "current": 21.97582953417826,
                    "label": "revenue",
                    "predicted": 23.536906870730153
                },
                {
                    "current": 627.6956765437895,
                    "label": "go",
                    "predicted": 620.7722877481675
                }
            ]
        }
    },
    {
        "response": {
            "constraints": [
                {
                    "label": "KV70",
                    "predicted": 3.006018305919056
                },
                {
                    "label": "KV150",
                    "predicted": 4.272710434253109
                },
                {
                    "label": "KV500",
                    "predicted": 8.869498094091774
                }
            ],
            "controllable": [
                {
                    "current": 23.354750413161074,
                    "label": "HLA.105PIC1401.PV",
                    "max": 25.68,
                    "min": 21.04,
                    "predicted": 21.80823132874051
                },
                {
                    "current": 345.0735098031853,
                    "label": "HLA.105TIC1322.PV",
                    "max": 370.0735098031853,
                    "min": 320.0735098031853,
                    "predicted": 321.07084810130436
                },
                {
                    "current": 838.1769999186198,
                    "label": "HLA.105FIC1406.PV",
                    "max": 1018.8093820073319,
                    "min": 682.7004050399653,
                    "predicted": 950.0391544350393
                },
                {
                    "current": 59.98982226053873,
                    "label": "HLA.105FIC1501.PV",
                    "max": 72.91800389407396,
                    "min": 48.86208516759792,
                    "predicted": 64.35743800656456
                },
                {
                    "current": 38647.32760416667,
                    "label": "HLA.105FIC1402.PV",
                    "max": 46976.068248662115,
                    "min": 31478.489879391276,
                    "predicted": 46294.99517779617
                },
                {
                    "current": 16016.91294642857,
                    "label": "HLA.105FIC1404.PV",
                    "max": 19468.65779208984,
                    "min": 13045.875700571985,
                    "predicted": 14985.10508932268
                },
                {
                    "current": 42572.155552455355,
                    "label": "HLA.105FIC1405.PV",
                    "max": 51746.72114998169,
                    "min": 34675.28677344709,
                    "predicted": 35745.50050347702
                },
                {
                    "current": 75.4795150756836,
                    "label": "HLA.105TI1912.PV",
                    "max": 100.4795150756836,
                    "min": 50.479515075683594,
                    "predicted": 95.7846308196024
                },
                {
                    "current": 10960.644986979167,
                    "label": "HLA.105FIC1314.PV",
                    "max": 13322.732485704348,
                    "min": 8927.5138459257,
                    "predicted": 11578.534738275697
                },
                {
                    "current": 11143.734928385416,
                    "label": "HLA.105FIC1315.PV",
                    "max": 13545.279453795778,
                    "min": 9076.641747513224,
                    "predicted": 11850.424468300827
                },
                {
                    "current": 19792.493782552083,
                    "label": "HLA.105FI1401.PV",
                    "max": 24057.8998957782,
                    "min": 16121.109888974812,
                    "predicted": 22050.737847371325
                },
                {
                    "current": 22649.027083333334,
                    "label": "HLA.105FIC1403.PV",
                    "max": 27530.03397621094,
                    "min": 18447.774115794273,
                    "predicted": 21541.08359707072
                }
            ],
            "nonControllable": [
                {
                    "current": 47159.697005208334,
                    "label": "HLA.105FI1102.PV"
                },
                {
                    "current": 5.42,
                    "label": "FPU-UCO-KV100"
                },
                {
                    "current": 846.8,
                    "label": "FPU-UCO-Density"
                }
            ],
            "outputs": [
                {
                    "current": 4815.36396986939,
                    "label": "70",
                    "predicted": 3961.2411786999296
                },
                {
                    "current": 34483.60812670914,
                    "label": "150",
                    "predicted": 35441.323231592156
                },
                {
                    "current": 12869.924522962643,
                    "label": "500",
                    "predicted": 13223.373349902891
                },
                {
                    "current": 21.96256154151639,
                    "label": "revenue",
                    "predicted": 23.52016118539299
                },
                {
                    "current": 627.6956765437895,
                    "label": "go",
                    "predicted": 599.915198151364
                }
            ]
        }
    },
    {
        "response": {
            "constraints": [
                {
                    "label": "KV70",
                    "predicted": 3.0060201379958538
                },
                {
                    "label": "KV150",
                    "predicted": 4.270504613009114
                },
                {
                    "label": "KV500",
                    "predicted": 8.869499957808667
                }
            ],
            "controllable": [
                {
                    "current": 23.354750413161074,
                    "label": "HLA.105PIC1401.PV",
                    "max": 25.68,
                    "min": 21.04,
                    "predicted": 21.41237314751937
                },
                {
                    "current": 345.0735098031853,
                    "label": "HLA.105TIC1322.PV",
                    "max": 370.0735098031853,
                    "min": 320.0735098031853,
                    "predicted": 351.2870900038267
                },
                {
                    "current": 838.1769999186198,
                    "label": "HLA.105FIC1406.PV",
                    "max": 1018.8093820073319,
                    "min": 682.7004050399653,
                    "predicted": 928.7306818149272
                },
                {
                    "current": 59.98982226053873,
                    "label": "HLA.105FIC1501.PV",
                    "max": 72.91800389407396,
                    "min": 48.86208516759792,
                    "predicted": 68.81293933368181
                },
                {
                    "current": 38647.32760416667,
                    "label": "HLA.105FIC1402.PV",
                    "max": 46976.068248662115,
                    "min": 31478.489879391276,
                    "predicted": 46508.61233651192
                },
                {
                    "current": 16016.91294642857,
                    "label": "HLA.105FIC1404.PV",
                    "max": 19468.65779208984,
                    "min": 13045.875700571985,
                    "predicted": 14785.373494794034
                },
                {
                    "current": 42572.155552455355,
                    "label": "HLA.105FIC1405.PV",
                    "max": 51746.72114998169,
                    "min": 34675.28677344709,
                    "predicted": 35750.95599112506
                },
                {
                    "current": 75.4795150756836,
                    "label": "HLA.105TI1912.PV",
                    "max": 100.4795150756836,
                    "min": 50.479515075683594,
                    "predicted": 63.03468767517543
                },
                {
                    "current": 10960.644986979167,
                    "label": "HLA.105FIC1314.PV",
                    "max": 13322.732485704348,
                    "min": 8927.5138459257,
                    "predicted": 10109.267494178299
                },
                {
                    "current": 11143.734928385416,
                    "label": "HLA.105FIC1315.PV",
                    "max": 13545.279453795778,
                    "min": 9076.641747513224,
                    "predicted": 12777.424285593912
                },
                {
                    "current": 19792.493782552083,
                    "label": "HLA.105FI1401.PV",
                    "max": 24057.8998957782,
                    "min": 16121.109888974812,
                    "predicted": 17496.81414398124
                },
                {
                    "current": 22649.027083333334,
                    "label": "HLA.105FIC1403.PV",
                    "max": 27530.03397621094,
                    "min": 18447.774115794273,
                    "predicted": 19894.226603662806
                }
            ],
            "nonControllable": [
                {
                    "current": 47159.697005208334,
                    "label": "HLA.105FI1102.PV"
                },
                {
                    "current": 5.42,
                    "label": "FPU-UCO-KV100"
                },
                {
                    "current": 846.8,
                    "label": "FPU-UCO-Density"
                }
            ],
            "outputs": [
                {
                    "current": 4815.36396986939,
                    "label": "70",
                    "predicted": 3741.817024682261
                },
                {
                    "current": 34483.60812670914,
                    "label": "150",
                    "predicted": 35441.323231592156
                },
                {
                    "current": 12869.924522962643,
                    "label": "500",
                    "predicted": 13243.055715075321
                },
                {
                    "current": 21.851673750825753,
                    "label": "revenue",
                    "predicted": 23.322993623101063
                },
                {
                    "current": 627.6956765437895,
                    "label": "go",
                    "predicted": 592.5564714720506
                }
            ]
        }
    },
    {
        "response": {
            "constraints": [
                {
                    "label": "KV70",
                    "predicted": 3.009614624438941
                },
                {
                    "label": "KV150",
                    "predicted": 4.260346543356108
                },
                {
                    "label": "KV500",
                    "predicted": 8.86947497198997
                }
            ],
            "controllable": [
                {
                    "current": 23.354750413161074,
                    "label": "HLA.105PIC1401.PV",
                    "max": 25.68,
                    "min": 21.04,
                    "predicted": 22.15281135648158
                },
                {
                    "current": 345.0735098031853,
                    "label": "HLA.105TIC1322.PV",
                    "max": 370.0735098031853,
                    "min": 320.0735098031853,
                    "predicted": 338.4877913754973
                },
                {
                    "current": 838.1769999186198,
                    "label": "HLA.105FIC1406.PV",
                    "max": 1018.8093820073319,
                    "min": 682.7004050399653,
                    "predicted": 749.4749972052649
                },
                {
                    "current": 59.98982226053873,
                    "label": "HLA.105FIC1501.PV",
                    "max": 72.91800389407396,
                    "min": 48.86208516759792,
                    "predicted": 70.33008269513029
                },
                {
                    "current": 38647.32760416667,
                    "label": "HLA.105FIC1402.PV",
                    "max": 46976.068248662115,
                    "min": 31478.489879391276,
                    "predicted": 36740.52311046644
                },
                {
                    "current": 16016.91294642857,
                    "label": "HLA.105FIC1404.PV",
                    "max": 19468.65779208984,
                    "min": 13045.875700571985,
                    "predicted": 14382.96475709357
                },
                {
                    "current": 42572.155552455355,
                    "label": "HLA.105FIC1405.PV",
                    "max": 51746.72114998169,
                    "min": 34675.28677344709,
                    "predicted": 35745.66882388764
                },
                {
                    "current": 75.4795150756836,
                    "label": "HLA.105TI1912.PV",
                    "max": 100.4795150756836,
                    "min": 50.479515075683594,
                    "predicted": 72.865305751963
                },
                {
                    "current": 10960.644986979167,
                    "label": "HLA.105FIC1314.PV",
                    "max": 13322.732485704348,
                    "min": 8927.5138459257,
                    "predicted": 12933.015024394612
                },
                {
                    "current": 11143.734928385416,
                    "label": "HLA.105FIC1315.PV",
                    "max": 13545.279453795778,
                    "min": 9076.641747513224,
                    "predicted": 12718.79704791809
                },
                {
                    "current": 19792.493782552083,
                    "label": "HLA.105FI1401.PV",
                    "max": 24057.8998957782,
                    "min": 16121.109888974812,
                    "predicted": 19177.695470177478
                },
                {
                    "current": 22649.027083333334,
                    "label": "HLA.105FIC1403.PV",
                    "max": 27530.03397621094,
                    "min": 18447.774115794273,
                    "predicted": 21232.589545433064
                }
            ],
            "nonControllable": [
                {
                    "current": 47159.697005208334,
                    "label": "HLA.105FI1102.PV"
                },
                {
                    "current": 5.42,
                    "label": "FPU-UCO-KV100"
                },
                {
                    "current": 846.8,
                    "label": "FPU-UCO-Density"
                }
            ],
            "outputs": [
                {
                    "current": 4815.36396986939,
                    "label": "70",
                    "predicted": 3741.817024682261
                },
                {
                    "current": 34483.60812670914,
                    "label": "150",
                    "predicted": 35441.323231592156
                },
                {
                    "current": 12869.924522962643,
                    "label": "500",
                    "predicted": 13248.46003201753
                },
                {
                    "current": 22.124356816658374,
                    "label": "revenue",
                    "predicted": 23.59567668893369
                },
                {
                    "current": 627.6956765437895,
                    "label": "go",
                    "predicted": 592.5564714720506
                }
            ]
        }
    },
    {
        "response": {
            "constraints": [
                {
                    "label": "KV70",
                    "predicted": 2.9565126455769817
                },
                {
                    "label": "KV150",
                    "predicted": 4.315737906463806
                },
                {
                    "label": "KV500",
                    "predicted": 8.878699802982918
                }
            ],
            "controllable": [
                {
                    "current": 23.354750413161074,
                    "label": "HLA.105PIC1401.PV",
                    "max": 25.68,
                    "min": 21.04,
                    "predicted": 23.218825732593626
                },
                {
                    "current": 345.0735098031853,
                    "label": "HLA.105TIC1322.PV",
                    "max": 370.0735098031853,
                    "min": 320.0735098031853,
                    "predicted": 355.6186724701922
                },
                {
                    "current": 838.1769999186198,
                    "label": "HLA.105FIC1406.PV",
                    "max": 1018.8093820073319,
                    "min": 682.7004050399653,
                    "predicted": 1000.0474106428503
                },
                {
                    "current": 59.98982226053873,
                    "label": "HLA.105FIC1501.PV",
                    "max": 72.91800389407396,
                    "min": 48.86208516759792,
                    "predicted": 62.624405087403545
                },
                {
                    "current": 38647.32760416667,
                    "label": "HLA.105FIC1402.PV",
                    "max": 46976.068248662115,
                    "min": 31478.489879391276,
                    "predicted": 35961.834476179654
                },
                {
                    "current": 16016.91294642857,
                    "label": "HLA.105FIC1404.PV",
                    "max": 19468.65779208984,
                    "min": 13045.875700571985,
                    "predicted": 15824.031783248078
                },
                {
                    "current": 42572.155552455355,
                    "label": "HLA.105FIC1405.PV",
                    "max": 51746.72114998169,
                    "min": 34675.28677344709,
                    "predicted": 34961.01633459775
                },
                {
                    "current": 75.4795150756836,
                    "label": "HLA.105TI1912.PV",
                    "max": 100.4795150756836,
                    "min": 50.479515075683594,
                    "predicted": 60.23103855081778
                },
                {
                    "current": 10960.644986979167,
                    "label": "HLA.105FIC1314.PV",
                    "max": 13322.732485704348,
                    "min": 8927.5138459257,
                    "predicted": 10418.839658078597
                },
                {
                    "current": 11143.734928385416,
                    "label": "HLA.105FIC1315.PV",
                    "max": 13545.279453795778,
                    "min": 9076.641747513224,
                    "predicted": 12734.412670505608
                },
                {
                    "current": 19792.493782552083,
                    "label": "HLA.105FI1401.PV",
                    "max": 24057.8998957782,
                    "min": 16121.109888974812,
                    "predicted": 16390.960812408382
                },
                {
                    "current": 22649.027083333334,
                    "label": "HLA.105FIC1403.PV",
                    "max": 27530.03397621094,
                    "min": 18447.774115794273,
                    "predicted": 23217.096844137213
                }
            ],
            "nonControllable": [
                {
                    "current": 47159.697005208334,
                    "label": "HLA.105FI1102.PV"
                },
                {
                    "current": 5.42,
                    "label": "FPU-UCO-KV100"
                },
                {
                    "current": 846.8,
                    "label": "FPU-UCO-Density"
                }
            ],
            "outputs": [
                {
                    "current": 4815.36396986939,
                    "label": "70",
                    "predicted": 4156.649236166267
                },
                {
                    "current": 34483.60812670914,
                    "label": "150",
                    "predicted": 34861.445369658344
                },
                {
                    "current": 12869.924522962643,
                    "label": "500",
                    "predicted": 13517.25869834581
                },
                {
                    "current": 21.889311819724064,
                    "label": "revenue",
                    "predicted": 23.523747865877
                },
                {
                    "current": 627.6956765437895,
                    "label": "go",
                    "predicted": 620.4800772786597
                }
            ]
        }
    }

]