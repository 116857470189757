<template>
    <div class="border-2 rounded-md text-left text-sm sentiment-card">
        <div class="flex justify-between">
            <h6 class="w-1/3 entry-title ribbon"><span class="ribbon-content font-bold">{{ title }}</span></h6>
            <div class="flex items-center mr-5 ">
                <span class="trend-value py-1 px-2 rounded-md">-0.6 <img :src="require('@/assets/img/delta-down.svg')" alt="" class="inline h-2"></span>      
            </div>
        </div>
        <div class="px-5 py-2">
            <p class="border-b k-text-orange font-bold">Summary</p>
        <div class="my-2">
            <p v-if="!showPopup" title="Click to read the complete summary" class="text-xs mb-5 overflow-hidden line-clamp-3" @click="openPopup">
                {{ truncatedText }}
            </p>
            <p v-if="showPopup" class="text-xs mb-5 overflow-hidden" @click="openPopup">
                {{ this.sortedData && this.sortedData[6] }}
            </p>

            <div class="flex justify-between items-center mt-2" v-if="this.title === 'Daily Sentiment' && this.sortedData.length > 0">
                <PriceCard :title="'Closing Price'" :value="this.sortedData[3]" :commodity="this.sortedData[7]" :trend="this.sortedData[3] > 0 ? 'deltaUp' : 'deltaDown'" class="w-1/3" />
                <PriceCard :title="'High Price'" :value="this.sortedData[4]" :commodity="this.sortedData[7]" :trend="this.sortedData[4] > 0 ? 'deltaUp' : 'deltaDown'"  class="w-1/3 mx-5"/>
                <PriceCard :title="'Low Price'" :value="this.sortedData[5]" :commodity="this.sortedData[7]" :trend="this.sortedData[5] > 0 ? 'deltaUp' : 'deltaDown'"  class="w-1/3"/>
            </div>
            <div class="flex justify-between items-center mt-2" v-if="this.title === 'Weekly Sentiment' && this.sortedData.length > 0">
                <PriceCard :title="'Closing Price'" :value="this.sortedData[4]" :commodity="this.sortedData[8]" :trend="this.sortedData[4] > 0 ? 'deltaUp' : 'deltaDown'" class="w-1/3" />
                <PriceCard :title="'High Price'" :value="this.sortedData[5]" :commodity="this.sortedData[8]" :trend="this.sortedData[5] > 0 ? 'deltaUp' : 'deltaDown'"  class="w-1/3 mx-5"/>
                <PriceCard :title="'Low Price'" :value="this.sortedData[6]" :commodity="this.sortedData[8]" :trend="this.sortedData[6] > 0 ? 'deltaUp' : 'deltaDown'"  class="w-1/3"/>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import NewsArticlePopup from '@/components/NewsArticlePopup.vue'
import PriceCard from '@/components/PriceCard.vue'
export default {
    components: {
        PriceCard,
        NewsArticlePopup
    },
    data(){
        return {
            showPopup: false,
        }
    },
    props: {
        sortedData: {
            type: Array,
            required: true,
            default: []
        },
        title: {
            type: String,
            required: true
        },
    },
    computed: {
        truncatedText() {
            if (this.sortedData.length === 0) return '';
            return this.title === "Daily Sentiment" ? this.sortedData[6].slice(0, 450) + (this.sortedData[6].length > 450 ? '...' : '') : this.sortedData[7].slice(0, 450) + (this.sortedData[7].length > 450 ? '...' : '');
        },
    },
    methods: {
        openPopup() {
            this.showPopup = true;
        }
    }
}
</script>

<style scoped>
.trend-value{
    background-color: #243058;
    color: white;
}

.sentiment-card{
    background-color: #FBFBFB;
}

.ribbon {
 font-size: 16px !important;
width: auto;
  float:left; 
 position: relative;
 background: #FFBF99;
 color: black;
 text-align: center;
 padding: 0 1em; 
  line-height:40px;
  height:40px;
 margin: 1em 0 0 -16px; 
}
.ribbon:before, .ribbon:after {
 content: "";
 position: absolute;
 display: block;
 bottom: -1em;
 border: 0;
 z-index: -1;
}


.ribbon .ribbon-content:before, .ribbon .ribbon-content:after {
 content: "";
 position: absolute;
 display: block;
 border-style: solid;
 border-color: #FFBF99 transparent transparent transparent;
 bottom: -1em;
}


.ribbon .ribbon-content:before {
 left: 0;
 border-width: 1em 0 0 1em;
}
.ribbon .ribbon-content:after {
 right: -32px;
 border-width: 40px 2em 0 0;
  border-top-color:#FFBF99;
  bottom:0px
}

</style>