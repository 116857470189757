<template>
    <div class="shadow-lg p-2 h-full form-height flex flex-col justify-between">
        <div class="text-sm text-left mt-5">
            <p class="font-semibold mb-5">Input Values</p>
            <div class="bg-white mb-1">
                <label for="gcvA" class="block text-xs">Select GCV for Mill A</label>
                <select id="gcvA" v-model="gcvA"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="<3350">&lt;3350</option>
                    <option value="3350-3650">3350-3650</option>
                    <option value=">3650">>3650</option>
                </select>
            </div>
            <div class="bg-white mb-1">
                <label for="gcvB" class="block text-xs">Select GCV for Mill B</label>
                <select id="gcvB" v-model="gcvB"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="<3350">&lt;3350</option>
                    <option value="3350-3650">3350-3650</option>
                    <option value=">3650">>3650</option>
                </select>
            </div>
            <div class="bg-white mb-1">
                <label for="gcvC" class="block text-xs">Select GCV for Mill C</label>
                <select id="gcvC" v-model="gcvC"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="<3350">&lt;3350</option>
                    <option value="3350-3650">3350-3650</option>
                    <option value=">3650">>3650</option>
                </select>
            </div>
            <div class="bg-white mb-1">
                <label for="gcvD" class="block text-xs">Select GCV for Mill D</label>
                <select id="gcvD" v-model="gcvD"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="<3350">&lt;3350</option>
                    <option value="3350-3650">3350-3650</option>
                    <option value=">3650">>3650</option>
                </select>
            </div>
            <div class="bg-white mb-1">
                <label for="gcvE" class="block text-xs">Select GCV for Mill E</label>
                <select id="gcvE" v-model="gcvE"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="<3350">&lt;3350</option>
                    <option value="3350-3650">3350-3650</option>
                    <option value=">3650">>3650</option>
                </select>
            </div>
            <div class="bg-white mb-1">
                <label for="gcvF" class="block text-xs">Select GCV for Mill F</label>
                <select id="gcvF" v-model="gcvF"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="<3350">&lt;3350</option>
                    <option value="3350-3650">3350-3650</option>
                    <option value=">3650">>3650</option>
                </select>
            </div>
            <div class="bg-white mb-1">
                <label for="gcvG" class="block text-xs">Select GCV for Mill G</label>
                <select id="gcvG" v-model="gcvG"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="<3350">&lt;3350</option>
                    <option value="3350-3650">3350-3650</option>
                    <option value=">3650">>3650</option>
                </select>
            </div>
            <div class="bg-white mb-1">
                <label for="gcvH" class="block text-xs">Select GCV for Mill H</label>
                <select id="gcvH" v-model="gcvH"
                    class="bg-gray-100 text-xs w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
                    <option value="<3350">&lt;3350</option>
                    <option value="3350-3650">3350-3650</option>
                    <option value=">3650">>3650</option>
                </select>
            </div>

        </div>
        <button @click="sendFormValues"
            class="w-full border font-semibold rounded text-xs kearney-purple-button text-white shadow-lg py-2">
            Run Optimizer
        </button>
    </div>
</template>

<script>
export default {
    name: 'CombustionAndSADCForm',
    data() {
        return {
            gcvA: this.formInput.GCV_A_t || "",
            gcvB: this.formInput.GCV_B_t || "",
            gcvC: this.formInput.GCV_C_t || "",
            gcvD: this.formInput.GCV_D_t || "",
            gcvE: this.formInput.GCV_E_t || "",
            gcvF: this.formInput.GCV_F_t || "",
            gcvG: this.formInput.GCV_G_t || "",
            gcvH: this.formInput.GCV_H_t || "",
        };
    },
    props: {
        formInput: Object
    },
    methods: {
        sendFormValues() {
            const formValues = {
                GCV_A_t: this.gcvA,
                GCV_B_t: this.gcvB,
                GCV_C_t: this.gcvC,
                GCV_D_t: this.gcvD,
                GCV_E_t: this.gcvE,
                GCV_F_t: this.gcvF,
                GCV_G_t: this.gcvG,
                GCV_H_t: this.gcvH
            };
            this.$emit("form-values-updated", formValues);
        }
    }
}
</script>

<style scoped>
.form-height {
    height: calc(100vh - 4rem);
}
</style>