<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 overflow-y-auto">
      <div class="grid grid-rows-[auto,1fr,1fr] p-4 h-screen overflow-y-auto">
        <div class="h-16 mb-4">
          <h2 class="font-bold purple-text text-lg mt-2">
            C2 Medium-term Inverter Health​
          </h2>
          <span class="text-xs italic">
            Predict medium-term (1 – 3 weeks in advance) inverter health based on weather and equipment parameters​
          </span>
        </div>
        <HealthIndexLineChart class="mt-4 mb-8" />
        <div class="mt-4 mb-16 flex w-full">
          <div class="grid grid-cols-4 gap-2">
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-xs font-semibold text-gray-800">Peak short-term risk score</h2>
                <h6 class="text-xs italic">(UOM - Score)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                80
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-xs font-semibold text-gray-800">Current short-term risk score</h2>
                <h6 class="text-xs italic">(UOM - Score)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                60
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-xs font-semibold text-gray-800">Preventive Maintenace compliance</h2>
                <h6 class="text-xs italic">(UOM - %)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                95%
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-xs font-semibold text-gray-800">Cumulative MWh</h2>
                <h6 class="text-xs italic">(UOM - MWh)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                12,545
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-xs font-semibold text-gray-800">Average temperature (last week)</h2>
                <h6 class="text-xs italic">(UOM - &deg;F)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                86
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-xs font-semibold text-gray">IGBT Cycle Count (daily)</h2>
                <h6 class="text-xs italic">(UOM - Count)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                10
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-xs font-semibold text-gray-800">Power Factor</h2>
                <h6 class="text-xs italic">(UOM - Ratio)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                0.98
              </div>
            </div>
            <div class="border bg-white shadow-lg h-36 p-4">
              <div class="items-center border-b pb-2 mb-4">
                <h2 class="text-xs font-semibold text-gray-800">Average Irradiance (last week)</h2>
                <h6 class="text-xs italic">(UOM - kWh/m<sup>2</sup>/day)</h6>
              </div>
              <div class="text-3xl font-bold text-gray-900">
                6.97
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import HealthIndexLineChart from './HealthIndexLineChart.vue';
import TreeMenu from '../TreeMenu.vue';

export default {
  name: "SolarInverterMediumTermRisk",
  components: {
    apexchart: VueApexCharts,
    HealthIndexLineChart,
    TreeMenu
  },
  data() {
    return {
      healthIndexChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [1, 2, 3, 4]
        },
        title: {
          text: 'Total MW/Irradiance',
          align: 'left'
        },
        colors: ['#FF4560']
      },
      pastTemperatureChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [1, 2, 3, 4]
        },
        title: {
          text: 'Past Temperature (F)',
          align: 'left'
        },
        colors: ['#FF4560']
      },
      IGBTChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [1, 2, 3, 4]
        },
        title: {
          text: 'IGBT Thermal Cycle Count​',
          align: 'left'
        },
        colors: ['#FF4560']
      },
      PowerFactorChartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: [1, 2, 3, 4]
        },
        title: {
          text: 'Power Factor​',
          align: 'left'
        },
        colors: ['#FF4560']
      },
      chartSeries: [
        {
          name: 'Health Score',
          data: [30, 200, 250, 300]
        }
      ]
    };
  }
};
</script>
