<template>
  <div class="w-full mb-4">
    <ProcessParameterDashboard />
  </div>
</template>

<script>
import ProcessParameterDashboard from "@/components/ProcessParameterOptimizer/index.vue";

export default {
  name: "ProcessParameterDashboardView",
  components: {
    ProcessParameterDashboard
  }
};
</script>
