<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 mb-16 body-bg overflow-y-auto">
      <div class="grid grid-rows-[auto,auto,1fr] p-4 h-screen-auto bg-white shadow-md rounded-md p-4 mx-4">
        <div class="h-16 mb-4">
          <h1 class="font-bold text-[2rem] mt-2">Machine Flow</h1>
        </div>
        <div class="flex items-center justify-between mb-4 pl-1 text-sm ">
          <div class="bg-white shadow-md rounded-md overflow-hidden w-2/3 pb-2">
            <table class="w-full text-left">
              <thead>
                <tr class="bg-blue-custom">
                  <th colspan="2" class="py-3 px-4 border-b text-white" style="width: 60%;">Control Parameters</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="py-2 px-4">Separation Density HMC, kg/m3</td>
                  <td class="py-2 px-4">
                    <input type="number"
                    v-model="value1"
                      class="text-[1.5rem] text-center rounded-sm font-bold text-ppo-custom-blue w-full h-10 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      style="width: 60%;" />
                  </td>
                </tr>
                <tr>
                  <td class="py-2 px-4">Separation Density Hydrosizer, kg/m3 </td>
                  <td class="py-2 px-4">
                    <input type="number"
                    v-model="value2"
                      class="text-[1.5rem] text-center rounded-sm font-bold text-ppo-custom-blue w-full h-10 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      style="width: 60%;" />
                  </td>
                </tr>
              </tbody>
            </table>
            
          <div>
            <button
              v-if="!loading"
              @click="fetchData"
              class="ml-4 my-4 px-12 py-2 blue-custom text-white text-sm font-medium hover:blue-custom-400 transition-all"
            >
              Estimate
            </button>
            <div v-if="loading" class="flex justify-center items-center mt-4">
              <div class="spinner-border animate-spin border-4 border-blue-600 border-t-transparent rounded-full w-10 h-10"></div>
            </div>
          </div>
          </div>

          <div class="w-1/4 pr-1">
            <div class="bg-white rounded-md shadow-md p-3 max-w-xs h-28">
              <div class="text-sm text-gray-800 font-semibold mb-5">
                Concentrate (t/h)
              </div>
              <div class="flex items-center mb-1">
                <div class="text-[2.5rem] font-bold text-ppo-custom-blue">{{ machineData?.concentrate ? Math.round(machineData?.concentrate) : 0 }}</div>
                <div class="text-[1.3rem] font-bold text-ppo-custom-blue pt-4">&nbsp;t/h</div>
              </div>
            </div>
            <div class="bg-white rounded-md shadow-md p-3 max-w-xs mt-3 h-28">
              <div class="text-sm text-gray-500 font-semibold mb-5">
                Ash (%)
              </div>
              <div class="flex items-center mb-1">
                <div class="text-[2.5rem] font-bold text-ppo-custom-blue">{{ this.ash ||
                  0 }}</div>
                <div class="text-[1.3rem] font-bold text-ppo-custom-blue pt-4">&nbsp;%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between mb-4 pl-1 text-sm" v-if="machineData">
          <div class="ml-8 bg-white rounded-md overflow-hidden w-[90%] pb-2 relative image-container" 
            :style="{
              backgroundImage: 'url(' + icons.machineFlow + ')',
              backgroundSize: 'contain',      // Ensures image fits without being cut off
              backgroundPosition: 'center',   // Centers the image within the container
              backgroundRepeat: 'no-repeat',  // Prevents the image from repeating
              height: '609px', //+ 'px'       // Dynamically set the height based on the image aspect ratio
              position: 'relative'
            }">
            <div id="magnetic_separator" class="absolute bottom-[80%] sm:bottom-[5%] md:bottom-[82.5%] left-[44%] sm:left-[35%] md:left-[34%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.magnetic_separator.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.magnetic_separator.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.magnetic_separator.slurry_flow) }}
              </div>
            </div>
            <div id="classification_screen" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[72.5%] left-[40%] sm:left-[35%] md:left-[17.5%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.classification_screen.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.classification_screen.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.classification_screen.slurry_flow) }}
              </div>
            </div>
            <div id="heavy_medium_cyclone" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[49.5%] left-[40%] sm:left-[35%] md:left-[25.5%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.heavy_medium_cyclone.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.heavy_medium_cyclone.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.heavy_medium_cyclone.slurry_flow) }}
              </div>
            </div>
            <div id="refuse_screen1" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[26%] left-[40%] sm:left-[35%] md:left-[13%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.refuse_screen1.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.refuse_screen1.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.refuse_screen1.slurry_flow) }}
              </div>
            </div>
            <div id="clean_coal_screen1" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[29.5%] left-[40%] sm:left-[35%] md:left-[35.5%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.clean_coal_screen1.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.clean_coal_screen1.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.clean_coal_screen1.slurry_flow) }}
              </div>
            </div>
            <div id="centrifuge1" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[7%] left-[40%] sm:left-[35%] md:left-[28%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.centrifuge1.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.centrifuge1.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.centrifuge1.slurry_flow) }}
              </div>
            </div>
            <div id="centrifuge2" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[2%] left-[40%] sm:left-[35%] md:left-[53%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.centrifuge2.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.centrifuge2.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.centrifuge2.slurry_flow) }}
              </div>
            </div>
            <div id="refuse_screen2" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[24%] left-[40%] sm:left-[35%] md:left-[58.5%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.refuse_screen2.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.refuse_screen2.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.refuse_screen2.slurry_flow) }}
              </div>
            </div>
            <div id="clean_coal_screen2" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[24.5%] left-[40%] sm:left-[35%] md:left-[74%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.clean_coal_screen2.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.clean_coal_screen2.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.clean_coal_screen2.slurry_flow) }}
              </div>
            </div>
            <div id="hydrosizer" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[46.5%] left-[40%] sm:left-[35%] md:left-[59%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.hydrosizer.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.hydrosizer.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.hydrosizer.slurry_flow) }}
              </div>
            </div>
            <div id="hydroclone_1_stage" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[73.5%] left-[42%] sm:left-[35%] md:left-[61%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_1_stage.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_1_stage.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.hydroclone_1_stage.slurry_flow) }}
              </div>
            </div>
            <div id="decanter" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[23%] left-[42%] sm:left-[35%] md:left-[91.5%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.decanter.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.decanter.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.decanter.slurry_flow) }}
              </div>
            </div>
            <div id="filter_flotation" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[45.5%] left-[42%] sm:left-[35%] md:left-[96.5%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.filter_flotation.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.filter_flotation.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.filter_flotation.slurry_flow) }}
              </div>
            </div>
            <div id="main_flotation" class="absolute bottom-[10%] sm:bottom-[5%] md:bottom-[59%] left-[42%] sm:left-[35%] md:left-[80%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.main_flotation.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.main_flotation.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.main_flotation.slurry_flow) }}
              </div>
            </div>
            <div id="hydroclone_2_stage" class="absolute bottom-[79%] sm:bottom-[5%] md:bottom-[82%] left-[44%] sm:left-[35%] md:left-[86%] transform -translate-x-1/2 text-black text-[0.725rem] font-semibold">
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_2_stage.load) }}
              </div>
              <div class="mb-1">
                {{ Math.round(machineData.hydroclone_2_stage.ash) }}
              </div>
              <div class="mb-3">
                {{ Math.round(machineData.hydroclone_2_stage.slurry_flow) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMenu from './TreeMenu.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import machineFlowData from './machineFlowData.json';

export default {
  name: "machineFlow",
  components: {
    TreeMenu,
    Datepicker
  },
  data() {
    return {
      icons: {
        machineFlow: require('@/assets/img/machine_flow.png'),  // Path to your image
      },
      imageHeight: 0,   // This will store the calculated height of the image
      machineFlowData,
      value1: '',
      value2: '',
      machineData: null, // Store the fetched data
      ash: 0,
      concentrate: ''
    };
  },
  mounted() {
    this.calculateImageHeight();
    const button = document.getElementById('hamburger');

    if (button) {
      button.click();
    }
  },
  methods: {
    calculateImageHeight() {
      const image = new Image();
      image.src = this.icons.machineFlow;

      image.onload = () => {
        // Calculate the aspect ratio of the image
        const aspectRatio = image.height / image.width;
        // Set the height of the container based on the image width (adjust this as needed)
        this.imageHeight = this.$el.clientWidth * aspectRatio;
      };
    },
    fetchData() {
      // Format the key as value1_value2
      const key = `${this.value1}_${this.value2}`;

      if (machineFlowData[key]) {
        this.machineData = machineFlowData[key];
        this.errorMessage = ''; // Clear error if data is found
        
        this.ash = 7.7
      } else {
        this.ash = 0
        alert('Please enter correct values!')
        this.machineData = null;
        this.errorMessage = `Data for ${key} not found.`;
      }
    }
  },
};
</script>

<style>
.blue-custom {
  background: #232B60;
}

.body-bg {
  background: #F5F6FA;
  border-left: #D9D9D9 1px solid;
}

.tab-button {
  border: #232B60 1px solid;
  font-size: 1rem;
}

.dp__input_icon_pad {
  height: 33px;
}
</style>
