<template>
  <div class="flex max-h-screen overflow-y-auto">
    <div class="w-full m-10 border bg-white text-center shadow-lg max-h-screen overflow-y-auto">
      <div class="back-button font-bold mt-4 text-sm text-left mx-10 purple-text">
        <button @click="goBack"><u>&lt;&lt; Previous Page</u></button>
      </div>
      <p class="font-bold mt-4 text-2xl text-left mx-10 purple-text">Heat Exchanger Cleaning Scheduler</p>
      <p class="text-left mx-10 text-sm"><i>Optimized scheduler to detect heat exchanger fouling through AI models</i></p>
      <p class="text-left mx-10 mt-10 mb-5 font-bold">Scheduler Output</p>
      <div class="max-w-4xl mx-auto mb-6">
        <div class="max-h-screen overflow-y-auto">
          <table class="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
            <thead class="sticky top-0">
              <tr class="bg-gray-100">
                <th scope="col" class="px-6 py-3 text-centre text-xs font-medium text-gray-500 uppercase tracking-wider">S.No.</th>
                <th scope="col" class="px-6 py-3 text-centre text-xs font-medium text-gray-500 uppercase tracking-wider">HX</th>
                <th scope="col" class="px-6 py-3 text-centre text-xs font-medium text-gray-500 uppercase tracking-wider">Cleaning Schedule</th>
                <th scope="col" class="px-6 py-3 text-centre text-xs font-medium text-gray-500 uppercase tracking-wider">View Details</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="(item, index) in tableData" :key="index">
                <td class="px-6 py-4 whitespace-nowrap">{{ index + 1 }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ item.hx }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ item.duration }}</td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <button @click="openModal(item)" class="border font-semibold rounded font-sm kearney-purple-button shadow-lg px-10 py-1">
                    Details
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br><br>

        <!-- Modal for Line Chart -->
        <modal v-if="showModal" @close="showModal = false">
          <div class="p-6">
            <h2 class="text-xl font-bold mb-4">Cost trends for Heat Exchanger {{ modalItem.hx }}</h2>
            <div class="my-6">
              <apexchart 
                ref="apexChart"
                type="line" 
                :options="modalChartOptions" 
                :series="modalSeries">
              </apexchart>
            </div>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';

export default {
  name: 'HeatExchanerSchedulerOutput',
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
    formInput: Object
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      showModal: false,
      modalItem: null,
      modalChartOptions: {
        chart: {
          id: 'modal-line-chart',
        },
        annotations: {
          xaxis: [],
        },
        xaxis: {
          categories: Array.from({ length: 120 }, (_, index) => index + 1),
          tickAmount: 10,
          labels: {
            formatter: function (value) {
              return value;
            },
            skipTicks: 10
          },
          title: {
            text: "Time (in months)",
          }
        },
        yaxis: {
          title: {
            text: "Cost (in INR)",
          },
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            }
          }
        }
      },
      modalSeries: [],
      tableData: [],
    };
  },
  watch: {
    result: {
      immediate: true,
      handler(newResult) {
        if (newResult && typeof newResult === 'object') {
          this.tableData = this.parseResultToTableData(newResult);
        } else {
          this.tableData = []; // or handle non-object results appropriately
        }
      }
    }
  },
  methods: {
    parseResultToTableData(result) {
      // Implement parsing logic here based on the structure of `result`
      return Object.entries(result).map(([hx, duration]) => ({ hx, duration }));
    },
    async openModal(item) {
      this.modalItem = item;
      this.showModal = true;
      
      // Fetch data and update modalSeries
      await fetch(`https://powerpulseapicombustdev.kearney.com/heatexchanger-cost/${item.hx}`)
        .then(response => response.json())
        .then(csv => {
          this.modalSeries = csv?.cost

          this.modalSeries.forEach(item => {
            if (item.name === "Total") {
              item.name = "Total Cost";
            } else if (item.name === "Clean") {
              item.name = "Cost of cleaning";
            } else if (item.name === "No Clean") {
              item.name = "Cost of not cleaning";
            }
          });

          const itemDuration = this.extractIntegerFromString(item.duration);

          if (itemDuration && itemDuration > 0) {
            this.modalChartOptions.annotations.xaxis = [{
              x: Number(itemDuration),
              strokeDashArray: 0,
              borderColor: '#775DD0',
              label: {
                borderColor: '#775DD0',
                text: 'Optimal Cleaning Time',
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
              }
            }];
          } else {
            this.modalChartOptions.annotations.xaxis = [];
          }

          this.$nextTick(() => {
            this.$refs.apexChart.updateOptions(this.modalChartOptions, true);
          });
        })
        .catch(error => {
          console.error('Error fetching CSV:', error);
        });
    },
    extractIntegerFromString(str) {
      const match = str.match(/\d+/);

      if (match) {
        return parseInt(match[0], 10);
      } else {
        return null;
      }
    },
    goBack() {
      this.$emit('go-back');
    }
  }
};
</script>

<style scoped>
.table-fixed thead {
  position: sticky;
  top: 0;
  background-color: #f7fafc; /* Adjust background color as needed */
  z-index: 10;
}

.back-button {
  text-decoration: underline; /* Adding an underscore to the text */
}
</style>
