export const yieldOptimizerLabels = {
  "HLA.105PIC1401.PV": "105-C-01 Top product (to ejector) pressure (mmHg)",
  "HLA.105FIC1405.PV": "150N PA oil from e-02 to Bed 4 O'head (Kg/hr)",
  "HLA.105TIC1322.PV": "Column inlet temprature (°C)",
  "HLA.105FIC1406.PV": "LP Steam to C-01 (Kg/hr)",
  "HLA.105FIC1501.PV": "LP steam to C-02 (Kg/hr)",
  "HLA.105FIC1402.PV": "GO CR flow (Kg/hr)",
  "HLA.105FIC1404.PV": "150N reflux flow to bed 5 (Kg/hr)",
  "HLA.105TI1912.PV": "GO CR+PDT temp after cooling in 105-AC-01 (°C)",
  "HLA.105FIC1314.PV": "500N recycle Flow through Pass 1 of 105-F-01 (Kg/hr)",
  "HLA.105FIC1315.PV": "500N recycle Flow through Pass 2 of 105-F-01 (Kg/hr)",
  "HLA.105FI1401.PV": "GO reflux from 105-P-04 A/B to Bed 2 O'head (Kg/hr)",
  "HLA.105FIC1403.PV": "70N reflux from 105-P-05 A/B to Bed 3 O'head (Kg/hr)",
  "KV70": "KV Waxy 70",
  "KV150": "KV Waxy 150",
  "KV500": "KV Waxy 500",
  "HLA.105FI1102.PV": "105FI1102 (Kg/hr)",
  "FPU-UCO-KV100": "UCO-Feed-KV (SAP)",
  "FPU-UCO-Density": "UCO-Feed-Density (SAP)",
  "revenue": "Revenue (Lakhs/hr)",
  "go": "Gas Oil",
  "70": "Waxy 70N yld (Kg/hr)",
  "150": "Waxy 150N yld (Kg/hr)",
  "500": "Waxy 500N yld (Kg/hr)",
};