<template>
  <div class="rounded-lg p-4 border-2">
    <p class="font-bold text-xs h-10" v-if="info.label"><i>{{ label }}</i></p>
    <!-- <p class="text-xs mb-4 text-gray-500" v-if="subHeader">{{ subHeader }}</p> -->
    <p :class="{
      'purple-text': isInRange(info.current) || !info.min || !info.max,
      'red-text': isOutsideRange(info.current),
      'font-semibold text-sm border-b': true
    }" class="mt-5" v-if="info.current">{{ formatNumber(info.current) }}</p>
    <p :class="{
      'aqua-text': isInRange(info.predicted) || !info.min || !info.max,
      'red-text': isOutsideRange(info.predicted),
      'mt-2 font-semibold text-sm border-b': true
    }" v-if="info.predicted">{{ formatNumber(info.predicted) }}</p>
    <p class="text-xs mt-4 text-gray-500 text-sm" v-if="info.min !== undefined">
      Min:<span class="ml-1">{{ formatNumber(info.min) }}</span>
    </p>
    <p class="text-xs mt-1 text-gray-500 text-sm" v-if="info.max !== undefined">
      Max:<span class="ml-1">{{ formatNumber(info.max) }}</span>
    </p>
  </div>
</template>

<script>
import { yieldOptimizerLabels, yieldOptimizerSubheader } from "../utils/LabelMapping"
export default {
  name: "InfoCard",
  props: {
    info: {
      label: String,
      subHeader: String,
      current: Number,
      predicted: Number,
      min: Number,
      max: Number,
    },
  },
  methods: {
    isInRange(value) {
      return value >= this.info.min && value <= this.info.max;
    },
    isOutsideRange(value) {
      return value > this.info.max || value< this.info.min;
    },
    formatNumber(numberStr) {
      const number = parseFloat(numberStr);
      return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

  },
  computed: {
    label() {
      return yieldOptimizerLabels[this.info.label] || this.info.label;
    },
    subHeader() {
      return yieldOptimizerSubheader[this.info.label] || '';
    }
  }
};
</script>
