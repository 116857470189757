<template>
  <div class="flex">
    <div class="options-container m-8 w-4/5">
      <div class="options flex text-xs font-semibold">
        <button
          v-for="(option, index) in options"
          :key="index"
          :class="{ 'kearney-purple-button': selectedOption === index, 'bg-white text-black': selectedOption !== index }"
          @click="selectOption(index)"
          class="option w-1/4 px-4 py-2 kearney-purple-button rounded-t-lg mx-2 flex border flex-col justify-center items-center"
        >
          {{ option.label }}
        </button>
      </div>
      <div class="page-container border-2 rounded shadow-lg z-30">
        <div v-if="selectedOption !== null" class="page">
          <component
            v-if="componentProps[options[selectedOption].component]"
            :is="options[selectedOption].component"
            :result="componentProps[options[selectedOption].component]"
            class="overflow-y-auto"
          />
        </div>
      </div>
    </div>
    <YieldOptimizerForm class="w-1/5 h-screen" :formInput="formInput" @form-values-updated="updateFormValues" />
  </div>
</template>


<script>
import ControllableParameters from "./Categories/ControllableParameters.vue"
import YieldOptimizerForm from "./YieldOptimizerForm.vue"
import Outputs from "./Categories/Outputs.vue";
import NonControllableParameters from "./Categories/NonControllableParameters.vue";
import ModelConstraints from "./Categories/ModelConstraints.vue";

export default {
  name: 'YieldOptimizerOutput',
  props: {
    result: Object,
    formInput: Object
  },
  components: {
    ControllableParameters,
    YieldOptimizerForm,
    Outputs,
    NonControllableParameters,
    ModelConstraints
  },
  data() {
    return {
      options: [
        { label: 'Controllable Parameters', component: 'ControllableParameters' },
        { label: 'Yield', component: 'Outputs' },
        { label: 'Non-Controllable Parameters', component: 'NonControllableParameters' },
        { label: 'Model Constraints', component: 'ModelConstraints' },
      ],
      selectedOption: 0,
    };
  },
  methods: {
    selectOption(index) {
      this.selectedOption = index;
    },
    updateFormValues(formValues) {
      this.$emit("form-values-updated", formValues);
    },
  },
  computed: {
    componentProps() {
      return {
        ControllableParameters: { controllable: this.result?.controllable },
        Outputs: { outputs: this.result?.outputs },
        NonControllableParameters: { nonControllable: this.result?.nonControllable },
        ModelConstraints: { constraints: this.result?.constraints }
      };
    }
  }
};
</script>
  
<style scoped>
.option {
  transition: background-color 0.3s, color 0.3s;
}

.kearney-purple-button, .kearney-purple-button:hover {
  background-color: #7823dc;
  color: white;
  border-radius: 0.25rem 0.25rem 0 0;
}

.bg-white {
  background-color: white;
}

.text-black {
  color: black;
}
</style>
  