<template>
  <div class="w-full mb-4">
    <ProcessParameterPlot />
  </div>
</template>

<script>
import ProcessParameterPlot from "@/components/ProcessParameterOptimizer/plot.vue";

export default {
  name: "ProcessParameterPlotView",
  components: {
    ProcessParameterPlot
  }
};
</script>
