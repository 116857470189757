<template>
  <div class="m-10 border bg-white text-center shadow-lg">

    <p class="font-bold mt-4 text-2xl text-left mx-10 purple-text">Deep Thermal</p>
    <p class="text-left mx-10 text-sm"><i>Combustion and SADC Optimization Model</i></p>
    <!-- First Row -->
    <div class="flex mx-10 mt-10 justify-between text-sm text-left">
      <!-- Dropdown 1 -->
      <div class="bg-white rounded-md p-4 border-2 w-1/4 mr-2">
        <label for="gcvA" class="block mb-2 font-semibold">Select GCV for Mill A</label>
        <select v-model="gcvA" id="gcvA"
          class="bg-gray-100 w-full border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="<3350">&lt;3350</option>
          <option value="3350-3650">3350-3650</option>
          <option value=">3650">>3650</option>
        </select>
      </div>

      <!-- Dropdown 2 -->
      <div class="bg-white rounded-md p-4 border-2 w-1/4 mr-2">
        <label for="gcvB" class="block mb-2 font-semibold">Select GCV for Mill B</label>
        <select v-model="gcvB" id="gcvB"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="<3350">&lt;3350</option>
          <option value="3350-3650">3350-3650</option>
          <option value=">3650">>3650</option>
        </select>
      </div>

      <!-- Dropdown 3 -->
      <div class="bg-white rounded-md p-4 border-2 w-1/4 mr-2">
        <label for="gcvC" class="block mb-2 font-semibold">Select GCV for Mill C</label>
        <select v-model="gcvC" id="gcvC"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="<3350">&lt;3350</option>
          <option value="3350-3650">3350-3650</option>
          <option value=">3650">>3650</option>
        </select>
      </div>

      <!-- Dropdown 4 -->
      <div class="bg-white rounded-md p-4 border-2 w-1/4">
        <label for="gcvD" class="block mb-2 font-semibold">Select GCV for Mill D</label>
        <select v-model="gcvD" id="gcvD"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="<3350">&lt;3350</option>
          <option value="3350-3650">3350-3650</option>
          <option value=">3650">>3650</option>
        </select>
      </div>
    </div>

    <!-- Second Row -->
    <div class="flex mx-10 mt-10 justify-between text-sm text-left">
      <!-- Dropdown 5 -->
      <div class="bg-white rounded-md p-4 border-2 w-1/4 mr-2">
        <label for="gcvE" class="block mb-2 font-semibold">Select GCV for Mill E</label>
        <select v-model="gcvE" id="gcvE"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="<3350">&lt;3350</option>
          <option value="3350-3650">3350-3650</option>
          <option value=">3650">>3650</option>
        </select>
      </div>

      <!-- Select GCV for Mill 6 -->
      <div class="bg-white rounded-md p-4 border-2 w-1/4 mr-2">
        <label for="gcvF" class="block mb-2 font-semibold">Select GCV for Mill F</label>
        <select v-model="gcvF" id="gcvF"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="<3350">&lt;3350</option>
          <option value="3350-3650">3350-3650</option>
          <option value=">3650">>3650</option>
        </select>
      </div>

      <!-- Dropdown 7 -->
      <div class="bg-white rounded-md p-4 border-2 w-1/4 mr-2">
        <label for="gcvG" class="block mb-2 font-semibold">Select GCV for Mill G</label>
        <select v-model="gcvG" id="gcvG"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="<3350">&lt;3350</option>
          <option value="3350-3650">3350-3650</option>
          <option value=">3650">>3650</option>
        </select>
      </div>

      <!-- Dropdown 8 -->
      <div class="bg-white rounded-md p-4 border-2 w-1/4">
        <label for="gcvH" class="block mb-2 font-semibold">Select GCV for Mill H</label>
        <select v-model="gcvH" id="gcvH"
          class="bg-gray-100 w-full  border border-gray-300 rounded mt-2 px-2 py-1 focus:outline-none">
          <option value="<3350">&lt;3350</option>
          <option value="3350-3650">3350-3650</option>
          <option value=">3650">>3650</option>
        </select>
      </div>
    </div>

    <div>
      <button @click="sendFormValues"
      class="border font-semibold rounded font-sm kearney-purple-button shadow-lg px-20 py-2 mt-20">
      Run optimizer
    </button>
    <i class="block mt-5 text-left mx-10 text-xs mb-10">*SADC - Secondary Air damper Control</i>
    </div> 
  </div>
</template>
  
<script>
export default {
  name: "CombustionAndSADCInput",
  data() {
    return {
      gcvA: "",
      gcvB: "",
      gcvC: "",
      gcvD: "",
      gcvE: "",
      gcvF: "",
      gcvG: "",
      gcvH: "",
    };
  },
  methods: {
    sendFormValues() {
      if (this.gcvA && this.gcvB && this.gcvC && this.gcvD && this.gcvE && this.gcvF && this.gcvG && this.gcvH) {
        const formValues = {
          GCV_A_t: this.gcvA,
          GCV_B_t: this.gcvB,
          GCV_C_t: this.gcvC,
          GCV_D_t: this.gcvD,
          GCV_E_t: this.gcvE,
          GCV_F_t: this.gcvF,
          GCV_G_t: this.gcvG,
          GCV_H_t: this.gcvH
        };
        this.$emit("form-values-updated", formValues);
      }
      else {
        alert("Please input GCV for all the mills");
      }
    }
  }
};
</script>
  