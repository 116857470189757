<template>
  <div class="w-full mb-4">
    <WindTurbineMediumTermRisk />
  </div>
</template>

<script>
import WindTurbineMediumTermRisk from "@/components/PredictiveMaintenance/WindTurbine/PredictiveMaintenanceMediumTermRisk/index.vue";

export default {
  name: "WindTurbineMTRiskView",
  components: {
    WindTurbineMediumTermRisk
  }
};
</script>
