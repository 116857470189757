import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "e236f041-cf8d-4400-bcca-1bc459021a82",
    authority:
      "https://login.microsoftonline.com/7253831b-d767-4f8c-8fdc-296188c22fb7",
    redirectUri: "https://powerpulse.dev.kearney.com/",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const pca = new PublicClientApplication(msalConfig);
export { pca };
