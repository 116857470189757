<template>
  <div class="h-full w-full p-3">
    <div ref="chart" class=""></div>
  </div>
</template>
<script>
import _ from "lodash";
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import markerCluster from 'highcharts/modules/marker-clusters.src';
import colorAxis from 'highcharts/modules/coloraxis';
import mapInit from "highcharts/modules/map";
import accessibility from "highcharts/modules/accessibility";


markerCluster(Highcharts);
colorAxis(Highcharts);
mapInit(Highcharts);
accessibility(Highcharts);
export default {
  name: "PoliticalMap",
  components: {
    HighchartsVue
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  methods: {
    createChart(topology, lowRiskPoints, mediumRiskPoints, highRiskPoints) {
      const chartOptions = {
        chart: {
          map: topology,
          backgroundColor: this.chartOptions?.backgroundColor,
          height: 350,
          margin: 0,
        },
        title: {
          text: '',
          align: 'left'
        },
        mapNavigation: {
          enableButtons:false,
          enabled: true
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          mappoint: {
            cluster: {
              enabled: false,
              allowOverlap: true,
              animation: {
                duration: 450
              },
              layoutAlgorithm: {
                type: 'grid',
                gridSize: 40
              },
            }
          }
        },
      };
      const seriesItems = [{
        name: 'United States of America',
        accessibility: {
          exposeAsGroupOnly: true
        },
        borderColor: '#ffffff',
        nullColor: 'rgba(233,219,249,255)',
        showInLegend: false
      }];
      const mapPointSeriesBaseConfig = {
        type: 'mappoint',
        showInLegend: false,
        enableMouseTracking: false,
        color: "#000000",
        marker: {
          lineWidth: 1,
          lineColor: '#fff',
          symbol: 'url(/predictive-maintenance/solar_panel_red.svg)',
          radius: 8,
          width: 20,
          height: 20,
        },
        dataLabels: {
          verticalAlign: 'top'
        }
      }

      const lowRiskSeriesMapPoint = {
        ...mapPointSeriesBaseConfig
      };
      lowRiskSeriesMapPoint.name = "Low Risk";
      lowRiskSeriesMapPoint.data = lowRiskPoints;
      lowRiskSeriesMapPoint.marker = {
        ...mapPointSeriesBaseConfig.marker,
        symbol: 'url(/predictive-maintenance/solar_panel_green.svg)'
      };

      const mediumRiskSeriesMapPoint = {
        ...mapPointSeriesBaseConfig
      };
      mediumRiskSeriesMapPoint.name = "Medium Risk";
      mediumRiskSeriesMapPoint.data = mediumRiskPoints;
      mediumRiskSeriesMapPoint.marker = {
        ...mapPointSeriesBaseConfig.marker,
        symbol: 'url(/predictive-maintenance/solar_panel_orange.svg)'
      };

      const highRiskSeriesMapPoint = {
        ...mapPointSeriesBaseConfig
      };
      highRiskSeriesMapPoint.name = "High Risk";
      highRiskSeriesMapPoint.data = highRiskPoints;
      highRiskSeriesMapPoint.marker = {
        ...mapPointSeriesBaseConfig.marker,
        symbol: 'url(/predictive-maintenance/predictive_maintenance_fail_marker.svg)'
      };

      seriesItems.push(lowRiskSeriesMapPoint);
      seriesItems.push(mediumRiskSeriesMapPoint);
      seriesItems.push(highRiskSeriesMapPoint);
      chartOptions.series = seriesItems;
      if (this.$refs.chart) {
        Highcharts.mapChart(this.$refs.chart, chartOptions);
      }
    }
  },

  async created() {
    const topology = await fetch(
        'https://code.highcharts.com/mapdata/countries/us/us-all.topo.json'
    ).then(response => response.json());

    const mapData = this.data;

    console.log(mapData, 'mapData')

    const lowRisk = [], mediumRisk = [], highRisk = [];
    _.forEach(mapData, v => {
      if (v.medium_term === 'High') {
        highRisk.push(v);
      } else if (v.medium_term === 'Medium') {
        mediumRisk.push(v);
      } else {
        lowRisk.push(v);
      }
    })
    this.createChart(topology, lowRisk, mediumRisk, highRisk)
  }
}
</script>

<style scoped></style>