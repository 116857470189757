<template>
  <div class="flex h-screen">
    <TreeMenu></TreeMenu>
    <div class="flex-1 p-4 pb-16 overflow-y-auto w-full">
      <div class="flex items-center justify-between">

        <h1 class="font-bold text-lg mt-2 ml-2">
          <span class="purple-text">Asset {{ assetId }} DC Fuse Health Map</span>(based on daily and monthly cumulative MWh)
        </h1>

        <div class="flex mt-2 mr-2">
          <div class="bg-green-100 text-black font-bold text-xs text-center py-1 px-1">
            Normal Operations
          </div>
          <div class="bg-yellow-100 text-black font-bold text-xs text-center py-1 px-2">
            Medium-term risk
          </div>
        </div>
      </div>

      <div class="grid grid-cols-21 grid-rows-20 p-2 mr-4">
        <div v-for="row in 20" :key="row" class="flex">
          <div
            v-for="col in 21"
            :key="col"
            :class="{
              'bg-gray-300 border-black-400': col === 21,
              'bg-yellow-100 border-gray-300': col !== 21 && isYellow((row - 1) * 20 + col),
              'bg-green-100 border-gray-300': col !== 21 && isGreen((row - 1) * 20 + col),
              'w-11': col < 21,
              'w-20': col === 21
            }"
            class="flex items-center justify-center h-11 text-xs font-semibold border"
          >
            <span v-if="col < 21">
              F{{ (row - 1) * 20 + col }}
            </span>
            <span v-else>
              Combiner {{ row }}
            </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import TreeMenu from '@/components/PredictiveMaintenance/SolarInverter/TreeMenu.vue';

export default {
  name: "DCHealth",
  components: {
    TreeMenu
  },
  data() {
    return {
      assetName: "C7" // todo
    }
  },
  computed: {
    assetId() {
      return this.$route.params.asset;
    },
  },
  methods: {
    isYellow(number) {
      return (number >= 23 && number <= 28) ||
             (number >= 91 && number <= 95) ||
             (number >= 124 && number <= 129) ||
             (number >= 303 && number <= 310) || 
             (number >= 323 && number <= 330) || 
             (number >= 228 && number <= 233);
    },
    isGreen(number) {
      return !this.isYellow(number);
    }
  }
};
</script>
<style scoped>
</style>
