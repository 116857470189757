<template>
  <div class="border bg-white shadow-lg h-96">
    <div class="flex items-center justify-between">
      <h2 class="font-bold purple-text text-lg mt-2 ml-4">
        Asset {{ assetId }} Inverter Health Map
      </h2>
      <div class="flex mt-2 mr-4">
        <div class="bg-green-custom text-white font-bold text-xs text-center py-1 px-2">
          Normal Operations
        </div>
        <div class="bg-yellow-custom text-white font-bold text-xs text-center py-1 px-2">
          Medium-term IF risk
        </div>
        <div class="bg-red-custom text-white font-bold text-xs text-center py-1 px-2">
          Imminent IF risk
        </div>
      </div>
    </div>
    <div class="grid-container h-64 overflow-y-auto">
      <div class="grid grid-cols-6 gap-2 m-6">
        <div v-for="item in gridData" :key="item" class="grid-item h-16 relative p-4">
          <img :src="SolarGreenIcon" v-if="item[1] === 'Normal Operations'" class="w-full h-full object-cover" />
          <img :src="SolarYellowIcon" v-if="item[1] === 'Medium-term IF risk'" class="w-full h-full object-cover" />
          <img :src="SolarRedIcon" v-if="item[1] === 'Short-term IF risk'" class="w-full h-full object-cover" />
          <div class="absolute inset-0 flex items-center justify-center text-white text-sm sm:text-base font-bold px-2 py-1">
            C{{ item[0] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SolarRedIcon from "@/assets/img/Inverter_red.svg";
import SolarYellowIcon from "@/assets/img/Inverter_orange.svg";
import SolarGreenIcon from "@/assets/img/Inverter_green.svg";

export default {
  name: "HealthView",
  data() {
    return {
      SolarRedIcon,
      SolarYellowIcon,
      SolarGreenIcon
    }
  },
  props: {
    gridData: Array,
    assetId: Number
  }
};
</script>

<style scoped>
.grid-container {
  height: 50vh;
}
.left-custom {
  left: 50%;
}
.bottom-custom {
  bottom: 50%;
}
.bg-green-custom {
  background-color: #329832;
}
.bg-red-custom {
  background-color: #ff0000;
}
.bg-yellow-custom {
  background-color: #febf00;
}
</style>