<template>
    <div class="flex items-center justify-between">
      <div class="flex items-center filter-box">
        <span class="mr-5">Filter By:</span>
        <div class="flex space-x-4">
          <div>
            <select v-model="selectedOption1" @change="handleCategoryFilter" class="border py-2 rounded-md">
                <option value="Category" disabled>Category</option>
                <option v-for="(option, index) in options1" :key="index" :value="option">
                {{ option }}
                </option>
            </select>
          </div>
  
          <div>
            <select v-model="selectedOption2" @change="handleSubCategoryFilter" class="border py-2 rounded-md">
              <option value="Sub-category">Sub-category</option>
              <option v-for="(option, index) in options2" :key="index" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
  
          <div>
            <select v-model="selectedOption3" class="border py-2 rounded-md">
              <option value="Sort by">Sort by</option>
              <option v-for="(option, index) in options3" :key="index" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>
  
      <div>
        <input v-model="searchQuery" @input="handleSearch" type="text" placeholder="Search for a keyword"
               class="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:border-blue-500"/>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        options1: ['Demand', 'Supply'],
        options2: ['Geopolitical', 'Regulatory', 'Industry-wide', 'Company-wide'],
        options3: ['Time stamp', 'Sentiment high to low', 'Sentiment low to high', 'Relevance'],
        selectedOption1: 'Category',
        selectedOption2: 'Sub-category',
        selectedOption3: 'Sort by',
        searchQuery: ''
      };
    },
    methods: {
      handleSearch() {
        this.$emit('search', this.searchQuery); // Emit search event with the search query
      },
      handleCategoryFilter() {
                this.$emit('category-filter', this.selectedOption1); // Emit category-filter event with the selected category
      },
      handleSubCategoryFilter() {
        this.$emit('sub-category-filter', this.selectedOption2); // Emit sub-category-filter event with the selected sub-category
      },
    }
  };
  </script>
  <style scoped>
    .filter-box {
        font-size: 14px;
    }
  </style>
  