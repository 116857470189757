<template>
  <div class="flex space-x-4 mx-10">
    <div class="flex-1 p-4">
      <h3 class="box-title">Aspect Score Snapshot</h3>
      <div class="grid grid-cols-4 gap-1 w-full max-w-screen-lg mt-2">
        <div class="py-1 px-4 border border-gray-300 rounded-md" v-for="i in 8" :key="i">
          <span class="flex flex-col items-start">
            <div class="text-xl font-bold">
              <span class="text-1xl">8.3</span><small class="text-sm">/10</small>
            </div>
            <small class="quality-score">Quality Score</small>
          </span>
        </div>
        <!-- Ninth tile beside the two rows -->
        <!-- <div class="w-full max-w-xs mt-4 p-4">
                Tile 9
            </div> -->
      </div>
    </div>
    <div class="flex-1 p-4">
      <h3 class="box-title">Sentiment Snapshot</h3>
      <div class="grid grid-cols-4 gap-1 w-full max-w-screen-lg mt-2 sentiment-snapshot">
        <div class="py-1 px-4 border border-gray-300 rounded-md" v-for="(snapshot, index) in sentimentSnapshots"
          :key="index" :style="{ backgroundColor: '#' + snapshot.color }">
          <span class="flex flex-col items-start">
            <div class="text-xl font-bold">
              <span class="text-1xl">{{ snapshot.neurominator }}</span><small class="text-sm"
                v-if="snapshot.denominator > 0">/{{ snapshot.denominator }}</small>
            </div>
            <small class="snapshot-title">{{ snapshot.title }}</small>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="flex spec-x-4 mx-10">
    <div class="flex-1">
      <h3 class="box-title pb-2 ml-4">Aspect by Sentiment</h3>
      <div class="px-2 pb-4 w-full max-w-screen-lg">
        <highcharts :options="chartOptions" />
      </div>
    </div>
    <div class="flex-1">
      <h3 class="box-title pb-2 ml-6">Aspect Co-occurance</h3>
      <div class="pb-4 w-full max-w-screen-lg">
        <div ref="chart" class="chart-container"></div>

      </div>
    </div>
  </div>
  <div class="flex spec-x-4 mx-10">
    <div class="flex-1 px-4">
      <div class="p-2 key-pleasure-point">
        <h3>Key Pleasure Points</h3>
      </div>
      <div class="px-2 pb-4 w-full max-w-screen-lg key-points key-pleasure-point-description">
        {{ dataList?.positive_summary }}
      </div>
    </div>
    <div class="flex-1 px-4">
      <div class="p-2 key-pain-point">
        <h3>Key Pain Points</h3>
      </div>
      <div class="px-2 pb-4 w-full max-w-screen-lg key-points key-pain-point-description">
        {{ dataList?.negative_summary }}
      </div>
    </div>
  </div>
  <div class="flex spec-x-4 mx-10 mb-20">
    <div class="flex-1 px-4">
      <div class="py-1 rating-heading">
        <h3>Highest Rating Review</h3>
      </div>

      <div class="grid grid-cols-10 gap-2 w-full max-w-screen-lg rating-box">
        <div class="col-span-6 h-64 overflow-auto px-2 pb-4 key-points rating-description custom-scrollbar">
          Writing this review after observing for 20 days. I brought a 200AH zelio 1100 inverter. The 200 ah
          battery holds 2400 watts of electricity , where the standard industry efficiency rating is 0.6 to
          0.8, I consider it at an average of 0.7 so the output will be 2400*0.7=1680 watts output. For 3
          ceiling fans - 50W each , 3 led bulbs 10W each, 1 50 inch led tv- 75W + 25W , 2 laptops continous
          charge , one WiFi router , modem ,extra monitor ,of total around 100W = 380W load gave about 4 hrs
          back up. This inverter does not have buzzer or beep funtion for power outage and in .which is
          annoying, I like it without it, In Bangalore(Banaswadi) there are not much of long hour power cuts
          but we have frequent short span power cuts for about 2 mins to 10 , rearly for 2 hrs , 900VA
          inverter charges battery pretty quick and also it indicates the hours left to discharge and charge.
          My pc and router does not restart even in eco mode(efficient) , most inverters need to switch to ups
          mode(inefficient). The above was done for testing . We usually..
        </div>
        <div class="col-span-4 px-2 pb-2 aspect-rating m-2">
          <div class="font-bold">Aspect-wise Rating</div>
          <div class="grid grid-cols-1 gap-1">
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Quality</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Service</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Durability</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Price</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Shipping</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Staff</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Performance</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Size</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
          </div>

          <div class="text-left my-2 font-bold">Keywords:</div>
          <div class="">
            <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded mr-2">Silent</span>
            <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">Efficient</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 px-4">
      <div class="py-1 rating-heading">
        <h3>Lowest Rating Review</h3>
      </div>

      <div class="grid grid-cols-10 gap-2 w-full max-w-screen-lg rating-box">
        <div class="col-span-6 h-64 overflow-auto px-2 key-points rating-description custom-scrollbar">
          The product arrives in very bad condition, the acid spill and leakage is terryfying and I thought it
          is just the water, but then realized that the skin started a rash and the floors went white, very
          bad delivery service, please don't purchase.
        </div>
        <div class="col-span-4 px-2 pb-2 aspect-rating m-2">
          <div class="font-bold">Aspect-wise Rating</div>
          <div class="grid grid-cols-1 gap-1">
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Quality</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Service</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Durability</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Price</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Shipping</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Staff</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Performance</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
            <div class="flex justify-between items-center">
              <span class="w-15 text-center">Size</span>
              <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">8</span>
            </div>
          </div>
          <div class="text-left my-2 font-bold">Keywords:</div>
          <div class="">
            <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded mr-2">Silent</span>
            <span class="custom-badge text-xs font-medium px-2.5 py-0.5 rounded">Efficient</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import heatmap from 'highcharts/modules/heatmap';
import axios from 'axios';

// Initialize the heatmap module
heatmap(Highcharts);

export default {
  name: 'TwoBoxes',
  mounted() {
    this.renderChart();
  },
  async created() {
    try {
      const api_key = localStorage.getItem('api_key');

      const response = await axios.get('https://powerpulseapicombustdev.kearney.com/results', {
        params: {
          api_key: api_key
        }
      });
      this.dataList = response.data; // Assign the fetched data to the dataList
    } catch (error) {
      console.error('There was an error fetching the data:', error);
    }
  },
  data() {
    return {
      dataList: [],
      sentimentSnapshots: [
        {
          "color": "7F2FDF",
          "title": "Total Reviews",
          "denominator": 0,
          "neurominator": 120
        },
        {
          "color": "7F2FDF",
          "title": "Highest Score",
          "denominator": 10,
          "neurominator": 9.5
        },
        {
          "color": "924EE1",
          "title": "Avg Score",
          "denominator": 10,
          "neurominator": 6.4
        },
        {
          "color": "AF7DEB",
          "title": "Lowest Score",
          "denominator": 10,
          "neurominator": 4.2
        },
        {
          "color": "77DD77",
          "title": "Relevant",
          "denominator": 120,
          "neurominator": 105
        },
        {
          "color": "FFB246",
          "title": "Positive",
          "denominator": 105,
          "neurominator": 51
        },
        {
          "color": "FF6962",
          "title": "Neutral",
          "denominator": 105,
          "neurominator": 34
        },
        {
          "color": "FF6962",
          "title": "Negative",
          "denominator": 105,
          "neurominator": 20
        }
      ],
      chartOptions: {
        series: [{
          name: 'Positive Sentiment',
          data: [100, 75, 50, 25, 0, 25, 50, 75] // Example data values corresponding to categories on y-axis
        },
        {
          name: 'Negative Sentiment',
          data: [-100, -75, -50, -25, 0, -25, -50, -75] // Example data values corresponding to categories on y-axis
        }],
        chart: {
          type: 'bar',
          height: 440,
          stacked: true
        },
        colors: ['#77DD77', '#FF6962'],
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: true, // This ensures the categories will be on the y-axis
            barHeight: '80%',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            formatter: function (val) {
              return Math.abs(val) + "%"; // Display percentage values on x-axis
            }
          },
          title: {
            text: 'Percent'
          }
        },
        yaxis: {
          categories: ['Quality', 'Service', 'Durability', 'Price', 'Shipping', 'Staff', 'Performance', 'Size'],
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val;
            }
          },
          y: {
            formatter: function (val) {
              return Math.abs(val) + "%"; // Format tooltip values as percentages
            }
          }
        },
        title: {
          text: ''
        }
      }


    }
  },
  methods: {
    renderChart() {
      // Define categories for x-axis and y-axis
      const xCategories = ['Size', 'Performance', 'Staff', 'Shipping', 'Price', 'Durability', 'Service', 'Quality'];
      const yCategories = ['Quality', 'Service', 'Durability', 'Price', 'Shipping', 'Staff', 'Performance', 'Size'];

      // Generate 8x8 grid data with random values between 0 and 100
      const data = [];
      for (let x = 0; x < 8; x++) {
        for (let y = 0; y < 8; y++) {
          data.push([x, y, (Math.random() * 100).toFixed(2)]); // Format to two decimal places
        }
      }

      Highcharts.chart(this.$refs.chart, {
        chart: {
          type: 'heatmap',
          plotBorderWidth: 1,
          height: '600px', // Adjust height as needed
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: xCategories,
          title: {
            text: 'X Axis'
          },
        },
        yAxis: {
          categories: yCategories,
          title: {
            text: 'Y Axis'
          },
        },
        colorAxis: {
          min: 0,
          minColor: '#F4EDFB',
          maxColor: '#54179C',
        },
        series: [{
          name: 'Heatmap Data',
          borderWidth: 1,
          data: data,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.point.value, 2); // Format to two decimal places
            }
          },
        }],
      });
    },
  },

};
</script>

<style scoped>
.box-title {
  color: #390B67;
  font-weight: 600;
}

.key-points {
  font-size: small;
  text-align: justify;
}

.key-pleasure-point {
  background-color: #54179C;
  color: #fff;
  font-size: 1.25rem;
}

.key-pain-point {
  background-color: #525252;
  color: #fff;
  font-size: 1.25rem;
}

.key-pain-point-description {
  background-color: #EDEDED;
  color: #000;
  font-size: 0.75rem;
}

.key-pleasure-point-description {
  background-color: #F4EDFB;
  color: #54179C;
  font-size: 0.75rem;
}

.rating-heading {
  font-size: 1.25rem;
  border-top: 1px solid #525252;
  border-right: 1px solid #525252;
  border-left: 1px solid #525252;
  text-align: center;
}

.rating-box {
  border: 1px solid #525252;
}

.rating-description {
  font-size: 0.75rem;
}

.aspect-rating {
  /* border: 1px solid #525252; */
  color: #390B67;
  font-size: 0.75rem;
  text-align: center;
  border: 1px solid #390B67;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  /* Thin scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  /* Scrollbar thumb color */
  border-radius: 4px;
  /* Rounded scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  /* Scrollbar track color */
}

.quality-score {
  font-size: .70rem;
  color: #7B7B7B;
}

.sentiment-snapshot {
  color: #fff;
}

.snapshot-title {
  font-size: .70rem;
  color: #fff;
}

.custom-badge {
  background-color: #E6D2FA !important;
  color: #390B67 !important;
}
</style>
