<template>
  <div class="grid grid-cols-2 gap-8 map-class">
    <div class="border bg-white text-center shadow-lg overflow-y-auto pt-4 h-96">
      <Bar :data="monthlyDowntime.monthlyDowntimeChartData" :options="monthlyDowntime.monthlyDowntimeChartOptions" />
    </div>
    <div class="border bg-white text-center shadow-lg overflow-y-auto pt-4 h-96">
      <Bar :data="monthlyGeneration.monthlyGenerationChartData"
        :options="monthlyGeneration.monthlyGenerationChartOptions" />
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "HealthMap",
  components: {
    Bar
  },
  props: {
    mapData: Object
  },
  data() {
    return {
      chartData: {
        labels: ['Planned', 'Actual MTD', 'Predicted - NC', 'Predicted - REC'], // Categories
        datasets: [
          {
            label: 'Hours',
            data: [10, 20, 30, 40], // Values for each category
            backgroundColor: 'grey', // Color of the bars
            borderRadius: 5, // Rounded corners for bars
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false, // Allows the chart to resize based on its container
        plugins: {
          legend: {
            display: false // Hides the legend
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                return `${context.label}: ${context.raw}`; // Custom tooltip label
              }
            }
          },
          title: {
            display: true,
            text: 'Monthly Spares Spend ($M)', // Title text
            font: {
              size: 16 // Adjust the font size as needed
            },
            padding: {
              top: 10,
              bottom: 10
            },
            color: '#7824DB'
          }
        },
        scales: {
          y: {
            display: false // Hides the y-axis
          },
          x: {
            display: true // Shows the x-axis
          }
        }
      }
    };
  },
  computed: {
    monthlyDowntime() {
      const monthlyDowntimeChartData = JSON.parse(JSON.stringify(this.chartData));
      monthlyDowntimeChartData.datasets[0].data = [this.mapData[0][5], this.mapData[0][6], this.mapData[0][7], this.mapData[0][8]];

      const monthlyDowntimeChartOptions = JSON.parse(JSON.stringify(this.chartOptions));
      monthlyDowntimeChartData.labels = [
        this.mapData[0][1],
        this.mapData[0][2],
        this.mapData[0][3],
        this.mapData[0][4]
      ]
      console.log(this.mapData[0][0], 'this.mapData[0]')
      monthlyDowntimeChartOptions.plugins.title.text = this.mapData[0][0]

      return { monthlyDowntimeChartData, monthlyDowntimeChartOptions }
    },
    monthlyGeneration() {
      const monthlyGenerationChartData = JSON.parse(JSON.stringify(this.chartData));
      monthlyGenerationChartData.datasets[0].data = [this.mapData[1][5], this.mapData[1][6], this.mapData[1][7], this.mapData[1][8]];
      monthlyGenerationChartData.datasets[0].label = 'MUs'
      const monthlyGenerationChartOptions = JSON.parse(JSON.stringify(this.chartOptions));

      monthlyGenerationChartData.labels = [
        this.mapData[1][1],
        this.mapData[1][2],
        this.mapData[1][3],
        this.mapData[1][4]
      ];
      monthlyGenerationChartOptions.plugins.title.text = this.mapData[1][0];

      return { monthlyGenerationChartData, monthlyGenerationChartOptions };
    }
  }
};
</script>
<style scoped>
.map-class {
  margin-top: 8rem;
}
</style>