<template>
  <div class="w-full mb-4">
    <SolarInverterMediumTermRisk />
  </div>
</template>

<script>
import SolarInverterMediumTermRisk from "@/components/PredictiveMaintenance/SolarInverter/PredictiveMaintenanceMediumTermRisk/index.vue";

export default {
  name: "SolarInverterMTRiskView",
  components: {
    SolarInverterMediumTermRisk
  }
};
</script>
