<template>
    <div class="flex">
      <div class="options-container m-8 w-4/5">
        <div class="options flex text-xs font-semibold">
          <button
            v-for="(option, index) in options"
            :key="index"
            :class="{ 'kearney-purple-button': selectedOption === index, 'bg-white text-black': selectedOption !== index }"
            @click="selectOption(index)"
            class="option w-1/4 px-4 py-2 kearney-purple-button rounded-t-lg mx-2 flex border flex-col justify-center items-center"
          >
            {{ option.label }}
          </button>
        </div>
        <div class="page-container border-2 rounded shadow-lg z-30">
          <div v-if="selectedOption !== null" class="page">
            <component
              v-if="componentProps[options[selectedOption].component]"
              :is="options[selectedOption].component"
              :result="componentProps[options[selectedOption].component]"
              class="overflow-y-auto"
            />
          </div>
        </div>
      </div>
      <CombustionAndSADCForm class="w-1/5 h-screen" :formInput="formInput" @form-values-updated="updateFormValues" />
    </div>
  </template>
  
  
  <script>
  import GeneralParameters from "./GeneralParameters.vue"
  import OptimalMillParameters from "./OptimalMillParameters.vue"
  import OptimalFurnaceParameters from "./OptimalFurnaceParameters.vue";
  import OptimalTurbineParameters from "./OptimalTurbineParameters.vue";
  import OptimalSADCParameters from "./OptimalSADCParameters.vue";
  import CombustionAndSADCForm from "./CombustionAndSADCForm.vue";
  
  export default {
    name: 'CombustionAndSADCOutput',
    props: {
      result: Object,
      formInput: Object
    },
    components: {
        GeneralParameters,
        OptimalMillParameters,
        OptimalFurnaceParameters,
        OptimalTurbineParameters,
        OptimalSADCParameters,
        CombustionAndSADCForm
    },
    data() {
      return {
        options: [
          { label: 'General Parameters', component: 'GeneralParameters' },
          // { label: 'Optimal Mill Parameters', component: 'OptimalMillParameters' },
          { label: 'Optimal Furnace Parameters', component: 'OptimalFurnaceParameters' },
          { label: 'Optimal Turbine Parameters', component: 'OptimalTurbineParameters' },
          { label: 'Optimal SADC Parameters', component: 'OptimalSADCParameters' }
        ],
        selectedOption: 0,
      };
    },
    methods: {
      selectOption(index) {
        this.selectedOption = index;
      },
      updateFormValues(formValues) {
        this.$emit("form-values-updated", formValues);
      },
    },
    computed: {
      componentProps() {
        return {
          GeneralParameters: { generalParameters: JSON.parse(this.result?.generalParameters) },
          OptimalFurnaceParameters: { optimalFurnaceParameters: JSON.parse(this.result?.optimalFurnaceParameters) },
          OptimalTurbineParameters: { optimalTurbineParameters: JSON.parse(this.result?.optimalTurbineParameters) },
          OptimalSADCParameters: { optimalSADCParameters: JSON.parse(this.result?.optimalSADCParameters) },
          // OptimalMillParameters: { optimalMillParameters: JSON.parse(this.result?.optimalMillParameters)}
        };
      }
    }
  };
  </script>
    
  <style scoped>
  .option {
    transition: background-color 0.3s, color 0.3s;
  }
  
  .kearney-purple-button, .kearney-purple-button:hover {
    background-color: #7823dc;
    color: white;
    border-radius: 0.25rem 0.25rem 0 0;
  }
  
  .bg-white {
    background-color: white;
  }
  
  .text-black {
    color: black;
  }
  </style>
    