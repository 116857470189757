<template>
  <div class="w-full mb-4">
    <ProcessParameterPredictor/>
  </div>
</template>

<script>
import ProcessParameterPredictor from "@/components/ProcessParameterOptimizer/predictor.vue";

export default {
  name: "ProcessParameterPredictorView",
  components: {
    ProcessParameterPredictor
  }
};
</script>
