<template>
  <div>
    <SolarInverterAssetOverview />
  </div>
</template>

<script>
import SolarInverterAssetOverview from "@/components/PredictiveMaintenance/SolarInverter/PredictiveMaintenanceAssetOverview/index.vue";

export default {
  name: "SolarInverterAssetView",
  components: {
    SolarInverterAssetOverview
  }
};
</script>
