// src/store/index.js
import { createStore } from 'vuex';
import sheets from './sheets';

const store = createStore({
  modules: {
    sheets,
  },
});

export default store;
