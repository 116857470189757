<template>
  <div class="">
    <YieldOptimizerInput v-if="!showOutput" @form-values-updated="showOutputPage" />
    <YieldOptimizerOutput v-if="showOutput && result.response" :result="result.response" :formInput="formInput"
      @form-values-updated="showOutputPage" />
    <Overlay :visible="showOverlay" />
  </div>
</template>
  
<script>
import axios from 'axios'
import { yieldOptimizerMock } from "../mocks/yield-optimizer-mock"
import Overlay from "../components/Overlay.vue"
import YieldOptimizerInput from "../components/YieldOptimizer/Input/YieldOptimizerInput.vue";
import YieldOptimizerOutput from "../components/YieldOptimizer/Output/YieldOptimizerOutput.vue";
export default {
  name: "YieldOptimizerView",
  data() {
    return {
      showOutput: false,
      showOverlay: false,
      result: Object,
      formInput: Object
    }
  },
  components: {
    Overlay,
    YieldOptimizerInput,
    YieldOptimizerOutput
  },
  methods: {
    showOutputPage(values) {
      this.showOutput = true;
      this.showOverlay = true;
      this.formInput = values;
      if (this.showOutput) {
        const apiUrl = "https://powerpulseapidev.kearney.com/FPU_PREDICT";
        axios
          .post(apiUrl, values)
          .then(response => {
            console.log("POST success:", response.data);
            this.result = response.data;
            this.showOverlay = false;
          })
          .catch(error => {
            console.error("POST error:", error);
          });
      }
    },
    // showOutputPage(values) {
    //   this.showOutput = true;
    //   this.showOverlay = true;
    //   this.formInput = values;
    //   setTimeout(() => {
    //     if (this.showOutput) {
    //       if (values.pr_70 === "39203" && values.pr_150 === "42721" && values.pr_500 === "51588") {
    //         this.result = yieldOptimizerMock[0];
    //       } else if (values.pr_70 === "39203" && values.pr_150 === "42721" && values.pr_500 === "50674") {
    //         this.result = yieldOptimizerMock[1];
    //       } else if (values.pr_70 === "39203" && values.pr_150 === "43141" && values.pr_500 === "51588") {
    //         this.result = yieldOptimizerMock[2];
    //       } else if (values.pr_70 === "39203" && values.pr_150 === "43141" && values.pr_500 === "50674") {
    //         this.result = yieldOptimizerMock[3];
    //       } else if (values.pr_70 === "39321" && values.pr_150 === "42721" && values.pr_500 === "51588") {
    //         this.result = yieldOptimizerMock[4];
    //       } else if (values.pr_70 === "39321" && values.pr_150 === "42721" && values.pr_500 === "50674") {
    //         this.result = yieldOptimizerMock[5];
    //       } else if (values.pr_70 === "39321" && values.pr_150 === "43141" && values.pr_500 === "51588") {
    //         this.result = yieldOptimizerMock[6];
    //       } else if (values.pr_70 === "39321" && values.pr_150 === "43141" && values.pr_500 === "50674") {
    //         this.result = yieldOptimizerMock[7];
    //       }
    //       this.showOverlay = false; // Hide overlay after 5 seconds
    //     }
    //   }, 5000); // Delay for 5 seconds (5000 milliseconds)
    // }
  }
};
</script>
  