<template>
  <div class="w-full mb-4">
    <MachineFlow />
  </div>
</template>

<script>
import MachineFlow from "@/components/MachineFlow/machineFlow.vue";

export default {
  name: "ProcessParameterMachineFlowView",
  components: {
    MachineFlow
  }
};
</script>
