<template>
    <div class="p-8">
    <Legend :text="'Non-Controllable Parameters'" :showAquaLegend="false" :showRedLegend="false" />
      <div class="cards-list mt-5 overflow-y-auto">
        <div class="card-row flex justify-start" v-for="(row, rowIndex) in cardRows" :key="rowIndex">
          <InfoCard v-for="(cardData, columnIndex) in row" :key="columnIndex" :info="cardData" class="w-1/4 m-2"/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import InfoCard from '../../../InfoCard.vue';
  import Legend from '../Legend.vue';
  
  export default {
    name: 'NonControllableParameters.vue',
    components: {
      InfoCard,
      Legend
    },
    props: {
    result: Object
  },
    computed: {
      // Calculate rows for displaying cards in groups of four
      cardRows() {
        const cardsPerRow = 4;
        const rows = [];
        const cards = this.result.nonControllable;
        for (let i = 0; i < cards?.length; i += cardsPerRow) {
          rows.push(cards?.slice(i, i + cardsPerRow));
        }
        return rows;
      },
    },
  };
  </script>
  
  <style scope>
  .cards-list {
    max-height: calc(100vh - 300px);
  }
  </style>
  