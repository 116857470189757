<template>
  <div class="text-center text-xs">
    <div class="table-container" style="max-height: 450px; overflow-y: auto;">
      <table class="w-full table-auto">
        <thead class="bg-gray-100">
          <tr class="purple-text">
            <th class="px-5 py-3">Parameter</th>
            <th class="px-5 py-3">Present Value (%)</th>
            <th class="px-5 py-3">Optimal Value (%)</th>
            <th class="px-5 py-3">Final Optimal Value (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in result.optimalSADCParameters" :key="key">
            <td class="border-b px-5 py-2">{{ key }}</td>
            <td class="border-b px-5 py-2">{{ formatNumber(value['Present Value']) }}</td>
          <td class="border-b px-5 py-2">{{ formatNumber(value['Optimal Value']) }}</td>
          <td class="border-b px-5 py-2">{{ formatNumber(value['Final Optimal Value']) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    result: Object,
  },
  methods: {
    formatNumber(numberStr) {
      if (isNaN(numberStr)) {
    return "No change";
  }
      const number = parseFloat(numberStr);
      return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
  }
};
</script>

<style scoped>
.table-container {
  max-height: 450px; /* Adjust the max height as needed */
  overflow-y: auto;
}

/* Add CSS to make the table header fixed */
thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

th {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
}
</style>
