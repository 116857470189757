<template>
  <div class="border bg-white text-center shadow-lg h-72">
    <div class="chart-container w-full">
      <apexchart ref="chart" type="line" :options="chartOptions" :series="chartSeries" width="100%" height="250" />
      <div class="custom-legend">
        <div class="legend-item">
          <div class="legend-color" style="background-color: #FF4560;"></div>
          <div class="legend-text">C2 Inverter</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'LineChart',
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      dataSets: [
        [43.20, 43.29, 43.41, 43.57, 43.75, 44.00, 44.31, 44.69, 45.18, 45.79, 46.54, 47.47, 48.60, 49.96, 51.60, 53.51, 55.72, 58.21, 60.96, 63.92, 67.00, 70.13, 73.22, 76.17, 78.92, 81.41, 83.62, 85.53, 87.16, 88.53, 89.67, 90.60, 91.35, 91.95, 92.43, 92.83, 93.13, 93.37, 93.57, 93.72, 93.84, 93.94],
        [43.20, 43.60, 43.71, 43.87, 44.06, 44.31, 44.62, 45.01, 45.50, 46.11, 46.87, 47.80, 48.94, 50.31, 51.96, 53.89, 56.11, 58.62, 61.39, 64.37, 67.47, 70.62, 73.73, 76.70, 79.47, 81.98, 84.21, 86.13, 87.77, 89.15, 90.29, 91.23, 91.99, 92.60, 93.08, 93.48, 93.78, 94.03, 94.23, 94.37, 94.50, 94.59],
        [43.20, 43.98, 44.09, 44.25, 44.44, 44.70, 45.00, 45.40, 45.90, 46.51, 47.27, 48.22, 49.36, 50.75, 52.42, 54.36, 56.60, 59.13, 61.93, 64.93, 68.05, 71.23, 74.37, 77.37, 80.16, 82.69, 84.94, 86.88, 88.54, 89.92, 91.08, 92.02, 92.79, 93.40, 93.89, 94.29, 94.60, 94.84, 95.05, 95.19, 95.32, 95.42],
        [43.20, 44.26, 44.38, 44.54, 44.73, 44.98, 45.29, 45.69, 46.19, 46.81, 47.58, 48.53, 49.68, 51.08, 52.75, 54.70, 56.97, 59.50, 62.32, 65.34, 68.49, 71.69, 74.85, 77.87, 80.67, 83.22, 85.49, 87.44, 89.10, 90.50, 91.66, 92.61, 93.38, 94.00, 94.49, 94.90, 95.21, 95.45, 95.65, 95.80, 95.93, 96.03],
        [43.20, 44.71, 44.83, 44.99, 45.18, 45.44, 45.75, 46.15, 46.66, 47.29, 48.06, 49.02, 50.18, 51.60, 53.29, 55.26, 57.55, 60.11, 62.96, 66.01, 69.19, 72.42, 75.61, 78.66, 81.50, 84.07, 86.36, 88.33, 90.01, 91.42, 92.60, 93.56, 94.33, 94.96, 95.45, 95.86, 96.18, 96.42, 96.63, 96.78, 96.91, 97.01],
        [43.20, 45.04, 45.16, 45.33, 45.52, 45.78, 46.10, 46.50, 47.01, 47.64, 48.42, 49.39, 50.56, 51.98, 53.69, 55.68, 57.98, 60.56, 63.43, 66.50, 69.71, 72.96, 76.18, 79.25, 82.11, 84.70, 87.00, 88.99, 90.69, 92.11, 93.29, 94.26, 95.04, 95.67, 96.17, 96.58, 96.90, 97.15, 97.35, 97.51, 97.64, 97.73],
        [43.20, 45.44, 45.56, 45.73, 45.92, 46.19, 46.50, 46.91, 47.42, 48.06, 48.85, 49.82, 51.01, 52.44, 54.16, 56.17, 58.49, 61.10, 63.99, 67.09, 70.32, 73.61, 76.85, 79.95, 82.83, 85.45, 87.77, 89.78, 91.48, 92.92, 94.11, 95.09, 95.88, 96.51, 97.02, 97.43, 97.75, 98.00, 98.21, 98.36, 98.50, 98.59]
      ],
      currentIndex: 0,
      chartSeries: [
        {
          name: 'Risk Factor',
          data: []  // Initialize with empty data
        }
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: this.generateDates(21),
          tickAmount: 14,
          labels: {
            show: true,
            rotate: -45,
            style: {
              colors: ['#000'],
              fontSize: '12px'
            }
          }
        },
        grid: {
          borderColor: 'transparent', // Remove grid border
          column: {
            colors: ['#FFFFFF', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0', '#F0F0F0'], // White for the first column, grey for others
            opacity: 1
          },
        },
        yaxis: {
          title: {
            text: 'Risk Factor'
          },
          tickAmount: 4,
          min: 0,
          max: 100,
          labels: {
            formatter: function (value) {
              return value;
            }
          }
        },
        annotations: {
          yaxis: [
            {
              y: 80,
              borderColor: '#000',
              borderWidth: 2,
              strokeDashArray: 5,
              label: {
                text: 'Dynamic health threshold',
                style: {
                  color: '#000',
                  background: '#FFFFFF'
                }
              }
            },
            {
              y: 10,
              borderColor: 'transparent', // Make border color transparent
              borderWidth: 0, // Set border width to 0
              strokeDashArray: 0, // No dashes
              position: 'left',
              label: {
                text: 'Forecasted',
                offsetX: -700,  // Move the text to the left
                style: {
                  color: '#000',
                  background: 'rgba(255, 255, 255, 0)', // Transparent background
                  border: 'none', // Optional: No border
                  fontWeight: 600,
                  fontSize: "16px",
                  fontColor: "#4c4c4c",
                  display: 'flex',
                  justifyContent: 'left',  // Align text center horizontally
                  alignItems: 'left'  // Align text center vertically
                }
              }
            }
          ]
        },
        title: {
          text: 'Health Index',
          align: 'left'
        },
        colors: ['#FF4560']
      }
    };
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.updateChart();
        setInterval(this.updateChart, 2000); // Update chart every 2 seconds
      }, 1000); // Ensure chart has enough time to render
    });
  },
  methods: {
    generateDates(days) {
      const dates = [];
      const today = new Date();

      // Generate unique dates
      for (let i = 0; i < days; i++) {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + i);

        const month = ('0' + (nextDate.getMonth() + 1)).slice(-2);
        const day = ('0' + nextDate.getDate()).slice(-2);
        const year = nextDate.getFullYear();
        const formattedDate = `${month}/${day}/${year}`;

        // Push each date twice to get a total of 42 dates
        dates.push(formattedDate, formattedDate);
      }
      return dates
    },
    updateChart() {
      this.currentIndex = (this.currentIndex + 1) % this.dataSets.length;
      this.chartSeries[0].data = this.dataSets[this.currentIndex];
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions(this.chartOptions); // Trigger a re-render
      } else {
        console.error('Chart reference is not available');
      }
    }
  }
};
</script>

<style scoped>
.chart-container {
  padding: 20px;
  position: relative;
}

.custom-legend {
  position: absolute;
  top: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  background: #FFFFFF;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-color {
  width: 12px;
  height: 12px;
  /* border-radius: 50%; */
  margin-right: 5px;
}

.legend-text {
  color: #333;
}
.apexcharts-xaxis-labels > text:first-child {
  fill: black; /* Ensure the first x-axis label is readable */
}

.apexcharts-canvas {
  background-color: #D3D3D3; /* Background color for the chart area */
}

/* Style for the first column (x-axis label) */
.apexcharts-xaxis-labels > text:nth-child(1) {
  background-color: white; /* Set the first label background to white */
  fill: black; /* Set label text color */
  border-radius: 4px; /* Optional: rounded corners */
  padding: 2px; /* Optional: some padding for better appearance */
}
</style>
