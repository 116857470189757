<template>
  <div class="p-8">
    <Legend :text="'Controllable Parameters'" />
    <div class="cards-list mt-5 overflow-y-auto">
      <div class="card-row flex justify-between" v-for="(row, rowIndex) in cardRows" :key="rowIndex">
        <InfoCard v-for="(cardData, columnIndex) in row" :key="columnIndex" :info="cardData" class="w-1/4 m-2"/>
      </div>
    </div>
  </div>
</template>
<script>
  import InfoCard from '../../../InfoCard.vue';
  import Legend from '../Legend.vue';

export default {
  name: 'ControllableParameters',
  components: {
    InfoCard,
    Legend
  },
  props: {
    result: Object
  },
  computed: {
    cardRows() {
      const cardsPerRow = 4;
      const rows = [];
      const cards = this.result.controllable;
      for (let i = 0; i < cards?.length; i += cardsPerRow) {
        rows.push(cards?.slice(i, i + cardsPerRow));
      }
      return rows.reverse();
    },
  },
};
</script>

<style scope>
.orange-legend {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: #EA7B03;
}

.aqua-legend {
  width: 1rem;
  height: 1rem;
  display: inline-block;
background-color: #55A8B5;
}

.cards-list {
  /* You can adjust the maximum height as needed */
  max-height: calc(100vh - 300px); /* 250px for headers and margins */
}
</style>
